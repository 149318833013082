/*
 *   File : confirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Delete Modal
 *   Integrations : 28-11-2023
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { PrimaryBtn, SecondaryBtn } from '../Cta/index';
import { Toast } from '../Note/index';

/*import styles*/
import styles from '../../Styles/Components/confirmation.module.scss';

/*import services*/
import service from '../../Services/service';

/* import assets */
// import Delete from '../../assets/images/modal/delete.svg'

export default function ConfirmationforBlock(props) {

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        }
    }, [success]);

    useEffect(() => {
        var myModalEl = document.getElementById("Confirm")
        myModalEl.addEventListener('hidden.bs.modal', (event) => {

            $(".fade").removeClass("modal-backdrop");
            $("body").removeAttr("style");
        })
        //eslint-disable-next-line
    }, [])

    /* --- close delete modal on click ---*/
    const closeModal = () => {
        $(".btn-close").trigger("click");
        $("#Confirm").trigger('click');
        setLoader(false)
    }

    /*----- Delete target maturity fund -----------*/
    const _blockUnblockUser = () => {
        setLoader(true)
        const url = "/user/block-unblock-user";
        const data = {
            "user_id": props.id
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                props.updateStatus();
                closeModal();
            } else {
                setLoader(false);
            }
        });
    }
    return (
        <div className={`${styles.e_confirmation} modal fade`} id={"Confirm"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-0">
                    <div className="modal-header py-4 px-4">
                        <h6>{`${props?.title}`}</h6>
                        <button type="button"
                            className="btn-close"
                            id="close-btn"
                            data-bs-dismiss="modal"
                            aria-label="Close" >
                        </button>
                    </div>
                    <div className="modal-body p-0 px-4 pb-4">
                        <div className="row">
                            {/* <div className='col-lg-12 text-center'>
                                <img src={Delete} alt="" />
                            </div> */}
                            <div className="col-lg-12 text-center">
                                <p className={props.className}
                                    dangerouslySetInnerHTML={{
                                        __html: props.content
                                    }}></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-end">

                                    <PrimaryBtn name={props.blockStatus === true ? "Unblock" : "Block"}
                                        className={
                                            loader ?
                                                `${styles.e_submit} e-modal-submit e-btn-loader me-3`
                                                :
                                                `${styles.e_submit} e-modal-submit me-3`
                                        }
                                        handleClick={() => {
                                            _blockUnblockUser()
                                        }} />
                                    <SecondaryBtn name="Cancel" className={styles.e_cancel} cancel="modal" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* error toast */}
            {
                error !== null ?
                    <Toast data={error}
                        type="error"
                        id="1"
                    />
                    :
                    null
            }
            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2"
                    />
                    :
                    null
            }
        </div>
    );
}
