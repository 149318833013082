/*
 *   File : editFundclass.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : edit fund Modal
 *   Integrations :28-11-2023
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input } from '../Input/index';
import { SecondaryBtn, PrimaryBtn } from '../Cta/index';
import { Toast } from '../Note';

/*import styles*/
import styles from '../../Styles/Components/fund-class.module.scss';

/*import services */
import service from '../../Services/service';

export default function EditFundClass(props) {

	const [name, setName] = useState("");
	const [ids, setIds] = useState(null);
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [success, setSuccess] = useState(null);

	useEffect(() => {
		var myModalEl = document.getElementById(props.debt ? "editDebtFundClass" : "editEquityFundClass")
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			props.close()
		})
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success])

	useEffect(() => {
		if (props.editedData && Object.values(props.editedData).length > 0) {
			const SCHEME_IDS = []
			props.editedData.map((item) => {
				return (
					SCHEME_IDS.push(item.id)
				)
			})
			setIds(SCHEME_IDS)
			setName(props.editedData[0].asset_class)
		}
	}, [props?.editedData])

	/*-----update fund class name api---- */
	const _updateFundClassName = () => {
		setLoader(true);
		const url = '/fund/edit-asset-class'
		const data = {
			"category_type": props.name,
			"allocation_ids": ids,
			"assetclass": name
		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				$('.btn-close').trigger('click');
				setTimeout(() => {
					$(props.debt ? "#editDebtFundClass" : "#editEquityFundClass").trigger('click');
					props.updatedClass(true);
				}, 1000)
				setLoader(false);
				setSuccess("Asset class updated successfully")
			}
			else {
				setError(response.data.message);
				setLoader(false);
			}
		});
	}

	return (
		<div className={`${styles.e_add_fund} modal fade`} id={props.debt ? "editDebtFundClass" : "editEquityFundClass"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header px-2 px-sm-3">
						<h6>Rename fund class</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body container-fluid px-2 px-sm-3 pt-0">
						<div className="row">
							<div className="col-lg-12">
								<label>Asset class*</label>
								<Input placeholder="Title" value={name} valueChange={props?.editedData ? (value) => { setName(value) } : (value) => { setName(value) }} type="text" />
							</div>
						</div>
						<div className="row pt-4 mt-2">
							<div className="d-flex justify-content-end col-lg-12">
								<div className="d-flex">
									<SecondaryBtn name="Cancel" className={`${styles.e_cancel} e-cancel`} cancel="modal" />
									{
										name ?
											<PrimaryBtn
												name="Save class"
												className={`${loader ? "e-btn-loader" : ""}`}
												handleClick={() => _updateFundClassName()} />
											:
											<PrimaryBtn
												name="Save class"
												className="e-disabled pe-4" />
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast data={error} type="error" id="1" />
					:
					null
			}

			{/* success toast */}
			{
				success !== null ?
					<Toast data={success} type="success" id="2" />
					:
					null
			}
		</div>
	);
}
