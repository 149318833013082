/*
 *   File : change-password.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Change password page
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

import React, { useEffect, useState } from 'react';

//custom components
import { Input } from '../../Components/Input';
import { PrimaryBtn } from '../../Components/Cta';
import { Toast } from '../../Components/Note';
import service from '../../Services/service';

//custom styles
import styles from '../../Styles/Components/add-faq.module.scss';


const ChangePassword = () => {

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 4000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 4000);
        }
    }, [success]);

    //handle validate
    const _handleValidate = () => {
        var pattern = new RegExp(/^(?=.*\d)(?=.*?[a-zA-Z])(?=.*?[\W_]).{8,100}$/);
        if (newPassword !== confirmPassword) {
            setError("Your password and confirm password does not match.");
        }
        else if (!pattern.test(newPassword)) {
            setError("Password contain one special character and one digit with minimum 8 characters");
        } else {
            _changePassword();
        }
    }

    //API- change password
    const _changePassword = () => {
        setLoader(true);
        const url = "/auth/change-password";
        const data = {
            current_password: currentPassword,
            new_password: newPassword
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setSuccess("Password changes successfully")
                setTimeout(() => {
                    window.location.href = '/'
                    localStorage.removeItem('access_token');
                }, 500)

            } else {
                setLoader(false);
                setError(response.data.message);
            }
        })
    }

    return (
        <div className="row">
            <div className='offset-2 col-8'>
                <div className="p-4 card e-dashboard-card border-0">
                    <h6 className="e-section-title">Change password</h6>
                    <div className='pt-4'>
                        <div >
                            <label>Current password*</label>
                            <Input
                                placeholder="Current password"
                                value={currentPassword}
                                valueChange={(value) => {
                                    setCurrentPassword(value)
                                }}
                                type="password" />
                        </div>

                        <div className="pt-3">
                            <label>New password*</label>
                            <Input
                                placeholder="New password"
                                value={newPassword}
                                valueChange={(value) => {
                                    setNewPassword(value)
                                }}
                                type="password" />
                        </div>
                        <div className="pt-3">
                            <label>Confirm password*</label>
                            <Input
                                placeholder="Confirm password"
                                value={confirmPassword}
                                valueChange={(value) => {
                                    setConfirmPassword(value)
                                }}
                                type="password" />
                        </div>

                        <div className="pt-4 text-end">

                            {currentPassword && newPassword && confirmPassword ?
                                <PrimaryBtn
                                    name="Save password"
                                    className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                    handleClick={() => {
                                        _handleValidate();
                                    }} />
                                :
                                <PrimaryBtn
                                    name="Save password"
                                    className={`${styles.e_disabled} e-modal-submit `} />
                            }

                        </div>
                    </div>

                    {/* error toast */}
                    {error !== null ?
                        <Toast
                            data={error}
                            type="error"
                            id="1" />
                        :
                        null
                    }
                    {/* success toast */}
                    {success !== null ?
                        <Toast
                            data={success}
                            type="success"
                            id="2" />
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;