/*
 *   File : primaryLinkText.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Link Text button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*Style Import*/
import styles from '../../Styles/Components/primary-link-text.module.scss';

const PrimaryLink = (props) => {
	return (
		<button
			id={props.id}
			className={`${styles.e_primary_link} ${props.className}`}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
		>
			{
				props.left ?
					<img src={props.image} alt="" />
					:
					null
			}
			{props.name}
			{
				props.right ?
					<img src={props.image} alt="" />
					:
					null
			}

		</button>
	);
}


export default PrimaryLink;
