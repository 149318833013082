/*
 *   File : add-family-member.js
 *   Author URI : https://evoqins.com
 *   Description : Family member adding modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-05-2024
 */

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import IcoMoon from "react-icomoon";

//custom component
import { PrimaryBtn, SecondaryBtn } from '../Cta';
import { CustomTextInput, OTPInput } from '../Form-Elements';
import iconSet from "../../Assets/Images/Icons/selection.json";
import service from '../../Services/service';

// Colors
import Colors from "../../Styles/config.scss";

const AddFamilyMember = (props) => {

    const inputRef = useRef(null);
    const [familyID, setFamilyID] = useState(0);

    const [clientID, setClientID] = useState("");
    const [clientIDError, setClientIDError] = useState("");
    const [pan, setPan] = useState("");
    const [panError, setPanError] = useState("");
    const [success, setSuccess] = useState(false);
    const [verifyToggle, setVerifyToggle] = useState(false);
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");

    const [focusedInput, setFocusedInput] = useState(0);
    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);

    // From API response
    const [customerID, setCustomerID] = useState(0);


    useEffect(() => {
        setFamilyID(props.familyID);
    }, [props.familyID]);

    //for timer
    useEffect(() => {
        if (verifyToggle === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [verifyToggle, timer]);

    //handle client id change
    const _handleClientIDChange = (value) => {
        setClientID(value.toUpperCase());
        setClientIDError("");
    };

    //handle PAN change
    const _handlePanChange = (value) => {
        setPan(value.toUpperCase());
        setPanError("");
    };

    //handle verify client id and pan
    const _handleVerify = () => {

        let is_valid = true;
        let valid_customer_id = /^(IFSH)\d+$/;
        let validate_pan = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/; // validation of PAN

        if (clientID.length === 0) {
            setClientIDError("Please enter the client id");
            is_valid = false;
        } else if (valid_customer_id.test(clientID) === false) {
            setClientIDError("Entered client id is invalid");
            is_valid = false;
        }

        if (validate_pan.test(pan) === false) {
            if (pan.length === 0) {
                setPanError("Please enter the PAN number");
            } else {
                setPanError("Invalid PAN number ");
            }
            is_valid = false;
        };

        // If Phone and pan valid open phone verify modal
        if (is_valid) {

            // API -send OTP to customer
            setLoading(true);
            _sendOTP();
        }

    };

    //handle confirm pin
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    // handle OTP resend
    const _handleOTPResend = () => {
        // API - resend OTP to customer
        _resendOTP();
    };

    //handle verify otp
    const _handleVerifyOTP = () => {
        if (otpValue.length === 0) {
            setOtpError("Please enter a valid OTP.");
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
        }
        else {
            setLoading(true);
            _verifyOTPAndAddMember();
        }
    };


    //======= API CALLS =======//

    // API- send OTP to customer 
    const _sendOTP = () => {
        let is_post = true;
        let url = props.type == 2 ? '/family/create' : '/family/add';
        let data = {
            "client_id": clientID,  // Ex : ifsh_id => IFSH1246
            "pan": pan,
        };

        if (props.type === 2) { // Adding member from User detail page
            data = {
                ...data,
                "customer_id": props.customerId,
            };

        } else {
            data = {
                ...data,
                "family_id": familyID, // when we use => /family/add API
            };
        }

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCustomerID(response.data.customer_id);
                setFocusedInput(2);
                setTimer(30);
                setOtpValue("");
                setOtpError("");
                setVerifyToggle(true);
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }
            setLoading(false);
        });
    };

    // API- resend OTP to customer 
    const _resendOTP = () => {
        let is_post = true;
        let url = '/family/resend-otp';
        let data = {
            "customer_id": customerID
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("OTP re-sent successfully");
                setFocusedInput(2);
                setTimer(30);
                setOtpValue("");
                setOtpError("");

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

        });
    };

    //API- verify OTP
    const _verifyOTPAndAddMember = () => {

        let is_post = true;
        let url = '/family/verify-otp';
        let data = {
            "customer_id": customerID,
            "otp": otpValue
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess(true);
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        });

    };

    return (
        <div className={`modal fade`}
            id="add-family-member-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="Add Family ModalLabel"
            aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered`}>
                {
                    success === false ?
                        <div className="modal-content e-border-radius-8 p-0">
                            <div className="modal-header justify-content-between pt-3  px-4 pb-0 border-0">
                                <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                                    {
                                        verifyToggle ? "OTP verification" : "Add family member"
                                    }
                                </h6>
                                <button type="button"
                                    id='mf-invest-modal-close'
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={verifyToggle ? props.onSuccess : props.onCloseModal}></button>
                            </div>
                            <div className="modal-body pb-4 px-4 ">
                                {
                                    verifyToggle === false ?
                                        <>
                                            <CustomTextInput label="Client ID"
                                                require
                                                type="text"
                                                placeholder="IFSH0101"
                                                maxLength={12}
                                                value={clientID}
                                                errorMsg={clientIDError}
                                                handleChange={_handleClientIDChange} />
                                            <div className='pt-1'>
                                                <CustomTextInput label="PAN number"
                                                    type="text"
                                                    require={true}
                                                    maxLength={10}
                                                    placeholder="AFDSHJK890"
                                                    value={pan}
                                                    errorMsg={panError}
                                                    handleChange={_handlePanChange} />
                                            </div>

                                            {/* Info message */}
                                            <div className='d-flex gap-2 e-bg-cornsilk e-border-radius-16 p-3 mt-4'>
                                                <span>
                                                    <IcoMoon iconSet={iconSet}
                                                        icon={"info"}
                                                        size={"1.125rem"}
                                                        color={Colors.ochre} />
                                                </span>
                                                <span className='e-text-ochre e-font-manrope e-font-14'>
                                                    The system will send an OTP to the family member's email and phone.
                                                </span>
                                            </div>
                                            <div className='row margin-top-32px justify-content-end'>
                                                <div className='col-4'>
                                                    <SecondaryBtn name="Cancel"
                                                        className="w-100 h-100 py-2"
                                                        cancel="modal"
                                                        handleClick={() => {
                                                            props.onCloseModal();
                                                        }} />
                                                </div>
                                                <div className='col-4'>
                                                    <PrimaryBtn name="Verify"
                                                        disabled={loading}
                                                        loading={loading}
                                                        className="w-100 h-40 d-flex justify-content-center"
                                                        handleClick={_handleVerify} />
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <>
                                            <div className='pb-4'>
                                                <label className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px">
                                                    Enter OTP*
                                                </label>
                                                <div className='h-64'>
                                                    <OTPInput ref={inputRef}
                                                        id="otp"
                                                        autoFocus={true}
                                                        value={otpValue}
                                                        spellCheck="false"
                                                        focused_input={focusedInput}
                                                        error={otpError}
                                                        onFocus={() => setFocusedInput(2)}
                                                        onBlur={() => setFocusedInput(0)}
                                                        onChange={_handleChangeOTP} />
                                                </div>
                                                {/* timer */}
                                                <div className='d-flex pt-1'>
                                                    <button className={`${timer !== 0 && " e-disable"} ps-0 border-0 bg-transparent e-text-egyptian-blue e-font-12 e-font-manrope e-font-weight-500 line-height-26px`}
                                                        onClick={_handleOTPResend} >
                                                        Re-send OTP
                                                    </button>
                                                    {timer !== 0 &&
                                                        <p className='mb-0 e-text-chinese-black e-font-12 e-font-manrope e-font-weight-600 line-height-26px'>
                                                            in {timer} {timer == 1 ? "second" : "seconds"}
                                                        </p>
                                                    }
                                                </div>

                                                <p className='mb-0 pt-3 e-text-light-grey e-font-12 e-font-manrope e-font-weight-400 line-height-18px'>
                                                    *In accordance with regulatory, 2FA verification is required in order to create an order.
                                                </p>
                                            </div>
                                            {/* custom buttons */}
                                            <div className='row pt-2 justify-content-end'>
                                                <div className='col-4'>
                                                    <SecondaryBtn name="Back"
                                                        className="w-100 h-100 py-2"
                                                        handleClick={() => {
                                                            setLoading(false);
                                                            setVerifyToggle(false);
                                                        }} />
                                                </div>
                                                <div className='col-4'>
                                                    <PrimaryBtn name="Confirm"
                                                        disabled={loading}
                                                        loading={loading}
                                                        className="w-100 h-40 d-flex justify-content-center"
                                                        handleClick={_handleVerifyOTP} />
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                        :
                        <div className="modal-content e-border-radius-8 p-0">
                            <div className="modal-header justify-content-between pt-3 px-4 pb-0 border-0">
                                <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-700 line-height-32px'>
                                    Family member added
                                </h6>

                                <button type="button"
                                    id='mf-invest-modal-close'
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onSuccess}></button>
                            </div>
                            <div className="modal-body pb-4 px-4 ">
                                <p className='e-font-manrope mb-0 e-font-weight-500 e-font-14 e-text-chinese-black line-height-20px'>
                                    The new family member has been successfully added
                                    to the system. They will now appear in the list of
                                    family members under the respective Family head.
                                </p>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default AddFamilyMember;