/*
 *   File : dashboard.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Dashboard file for dashboard container;
 *   Integrations :28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
 */

/*import packages*/
import { useState, useEffect } from 'react';


/*import components*/
import { Summary } from '../../Components/Cards/index';
import { LineChart } from '../../Components/Charts/index';
import SelectBox from '../../Components/Dropdown/select-box';
import { EmptyNote, Toast } from '../../Components/Note';
import { Loader } from '../../Components/Loader';

/* import services */
import service from '../../Services/service';
// import service true,from '../../services/postApi';


/*import assets*/
import Aum from '../../Assets/Images/Card/aum.svg';
import Users from '../../Assets/Images/Card/users.svg';
import Transaction from '../../Assets/Images/Card/transactions.svg';
import TxnPending from '../../Assets/Images/Card/txn-pending.svg';


// import DateRange from '../../components/input/dateRangepicker';

/* select box options ---*/

const CUMULATIVE_OPTIONS = [
    {
        value: 1, label: 'Daily',
    },
    {
        value: 2, label: 'Weekly'
    },
    {
        value: 3, label: 'Monthly'
    },
]

const Dashboard = (props) => {
    const [error, setError] = useState(null);
    const [selected, setSelected] = useState({ value: 2, label: 'Weekly', });
    const [dataLoader, setDataLoader] = useState(false);
    const [dashboardData, setDashboardData] = useState({});
    const [cumulativeAumData, setCumulativeAumData] = useState([]);
    const [cumulativeUsersData, setCumulativeUsersData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [cumulativeAumLabel, setCumulativeAumLabel] = useState([]);
    // const [investmentLoader, setInvestmentLoader] = useState(false);
    // const [investmentAumData, setInvestmentAumData] = useState([]);
    // const [investmentTotalData, setInvestmentTotalData] = useState([]);
    // const [investmentAumLabel, setInvestmentAumLabel] = useState([]);
    // const [investmentTotalLabel, setInvestmentTotalLabel] = useState([]);

    useEffect(() => {
        _getDashboardData();
        // _getStatistics();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);


    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 300);
    }, [error]);

    useEffect(() => {
        _getCumulativePerformace();
        //eslint-disable-next-line
    }, [selected])



    /*----- dashboard data api ---*/
    const _getDashboardData = () => {
        setDataLoader(true)
        const url = '/dashboard/get'
        service(false, url).then((response) => {
            if (response.status_code === 200) {
                setDataLoader(false);
                setDashboardData(response.data);
            }
            else {
                setDataLoader(false);
                setError(response.data.message);
            }
        });
    }

    /*----- statistics api ---*/
    // const _getStatistics = () => {
    //     setInvestmentLoader(true);
    //     setInvestmentAumData([]);
    //     setInvestmentTotalData([]);
    //     setInvestmentAumLabel([]);
    //     setInvestmentTotalLabel([]);
    //     const url = '/dashboard/goal-statistics'
    //     const data = {
    //         "filter_id": 1,
    //         "from_date": null,
    //         "till_date": null
    //     }

    //     service(true, url, data).then((response) => {
    //         if (response.status_code === 200) {
    //             setInvestmentLoader(false);
    //             setInvestmentAumData(response.data.goal_aum.data);
    //             setInvestmentTotalData(response.data.goal_investment.data);
    //             setInvestmentAumLabel(response.data.goal_aum.label);
    //             setInvestmentTotalLabel(response.data.goal_investment.data);
    //         }
    //         else {
    //             setInvestmentLoader(false);
    //             setInvestmentAumData([]);
    //             setInvestmentTotalData([]);
    //             setInvestmentAumLabel([]);
    //             setInvestmentTotalLabel([]);
    //         }
    //     });
    // }

    /*----- statistics api ---*/
    const _getCumulativePerformace = () => {
        setCumulativeAumData([]);
        setCumulativeUsersData([]);
        setCumulativeAumLabel([])
        setLoader(true)
        const url = '/dashboard/cumulative-performance'

        const data = {
            filter_id: selected.value,
            from_date: null,
            till_date: null
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                setCumulativeAumData(Object.values(response.data.aum))
                setCumulativeAumLabel(response.data.aum.label)
                setCumulativeUsersData(Object.values(response.data.users))
            }
            else {
                setLoader(false)
                setCumulativeAumData([]);
                setCumulativeUsersData([]);
                setCumulativeAumLabel([]);
            }
        });
    }

    return (
        <>
            {
                !dataLoader ?
                    <>
                        {/*-------------- Dashboard cards---------------- */}

                        <div className="row e-dashboard position-relative">
                            <h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
                                Overview
                            </h3>
                            <div className="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary

                                    data={{
                                        tooltipContent: "₹ " + Number(dashboardData.aum_text).toLocaleString("en-In"),
                                        title: 'AUM',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.aum && dashboardData.aum ?
                                            dashboardData.aum.slice(1,).split(" ")[0]
                                            :
                                            "-"
                                        ,
                                        currency: "₹",
                                        icon: Aum,
                                        text: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.aum ?
                                            dashboardData.aum.split(" ")[1]
                                            :
                                            null,
                                    }}
                                />
                            </div>
                            <div className="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'Total Users',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.total_users && dashboardData.total_users ?
                                            dashboardData.total_users
                                            :
                                            "-",
                                        icon: Users,
                                        text: null,
                                    }}
                                />
                            </div>
                            <div className="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: `Transactions`,
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.no_of_transactions && dashboardData.no_of_transactions ?
                                            dashboardData.no_of_transactions
                                            :
                                            "-",
                                        icon: Transaction,
                                    }}
                                />
                            </div>
                            <div className="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'Txn. pending',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.pending_transactions && dashboardData.pending_transactions ?
                                            dashboardData.pending_transactions
                                            :
                                            "-",
                                        icon: TxnPending,
                                        text: null
                                    }}
                                />
                            </div>
                            <div className="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'Invested users',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.active_investors &&
                                            dashboardData.active_investors
                                        ,

                                        icon: Users
                                    }}
                                />
                            </div>
                            <div className="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'Non-invested users',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.inactive_investors &&
                                            dashboardData.inactive_investors
                                        ,
                                        icon: Users
                                    }}
                                />
                            </div>
                            <div className="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                                <div>
                                    <Summary
                                        data={{
                                            tooltipContent: "₹ " + Number(dashboardData.average_aum_text).toLocaleString("en-In"),
                                            title: 'Avg AUM',
                                            count: dashboardData["average_aum"] ?
                                                dashboardData.average_aum.slice(1, -1).trim() : "0",

                                            currency: "₹",
                                            text: dashboardData["average_aum_text"] ?
                                                dashboardData.average_aum.slice(-1)
                                                :
                                                null,
                                            icon: Aum
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        {/*------------------ Dashboard line graph------------------ */}

                        <div className="row mt-5 e-sort">
                            <div className='col-lg-6'>
                                <h6 className="e-card-setcion-title">Cumulative performance</h6>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-end'>
                                {/* <DateRange

                                    onDatesChange={(start, end) => {
                                        setStartDate(start);
                                        setEndDate(end);
                                    }}
                                /> */}
                                <SelectBox
                                    value={selected}
                                    // isDisabled={startDate && endDate ? true : false}
                                    options={CUMULATIVE_OPTIONS}
                                    className="e-dashboard-card-sort ms-3"
                                    selectChange={(temp) => { setSelected(temp); }}
                                    isSearchable={false}
                                />
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6 mb-lg-0 mb-md-4 mb-4">
                                <div className="card e-dashboard-card e-dashboard-row-2 e-round-chart-container border-0 p-4">
                                    <h6 className="e-card-title">AUM</h6>
                                    {
                                        !loader ?
                                            cumulativeAumData && cumulativeAumData.length > 0 && cumulativeAumLabel && cumulativeAumLabel.length > 0 ?
                                                <div className="col-lg-12 ms-3 e-w-800 ">
                                                    <LineChart
                                                        colors={['#106EE8', '#FFFFFF']}
                                                        data={cumulativeAumData[0]}
                                                        label={cumulativeAumLabel}
                                                    />
                                                </div>
                                                :
                                                <EmptyNote note="No data" />
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 pe-0">
                                <div className="card e-dashboard-card e-dashboard-row-2 e-round-chart-container border-0 p-4">
                                    <h6 className="e-card-title">Users</h6>
                                    {!loader ?
                                        cumulativeUsersData && cumulativeUsersData.length > 0 ?
                                            <div className="col-lg-12 ms-3 e-w-800 ">
                                                <LineChart
                                                    colors={['#106EE8', '#FFFFFF']}
                                                    data={cumulativeUsersData[0]}
                                                    label={cumulativeUsersData[1]}
                                                    users={true}
                                                />
                                            </div>
                                            :
                                            <EmptyNote note="No data" />
                                        :
                                        null
                                    }
                                </div>
                            </div>

                        </div>


                    </>
                    :
                    <Loader />
            }



            {/* --------------- error message toast---------- */}
            {
                error !== null ?
                    <Toast
                        data={error}
                        id="1"
                        type="error"
                    />
                    :
                    null
            }
        </>
    );
}
export default Dashboard;