/*
 *   File : otp-input.js
 *   Author : https://evoqins.com
 *   Description : OTP input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

//custom style
import { useEffect, forwardRef, memo, useState, useRef } from "react";

//styles
import styles from "../../Styles/Components/input.module.scss";

const OTPInput = forwardRef((props, ref) => {

    const [pin, setPin] = useState(props.value);
    const otpLabelInputRef = useRef(null);
    const [inputWidth, setInputWidth] = useState(0);

    useEffect(() => {
        setPin(props.value);
    }, [props.value]);

    useEffect(() => {
        setInputWidth(otpLabelInputRef.current.clientWidth);
    }, [otpLabelInputRef.current]);

    useEffect(() => {

        if (ref && props.focused_input === 2 && props.value.length <= 4) {
            ref.current.focus();
        };

    }, [props.focused_input]);

    const _handleOtpChange = (event) => {

        const value = event.target.value;
        const allowedValues = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""];
        if (allowedValues.includes(value.slice(-1)) == false) return;
        setPin(value);
        props.onChange(value);

    };

    const _preventDefaults = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed


        if (key_code == 9 || // Tab key
            (is_ctrl_pressed && (key_code == 65 || key_code == 67 || key_code == 88 || key_code == 86))
        ) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V    
            e.preventDefault();
        }

        if (
            (key_code >= 48 && key_code <= 57) || // 0-9
            (key_code >= 96 && key_code <= 105) || // Numpad 0-9
            key_code === 8 || // Backspace
            (key_code >= 37 && key_code <= 40) // Arrow keys
        ) {
            // Allow the keypress
        } else {
            e.preventDefault();
        }
    };

    return (
        <div className={`${styles.e_otp_wrapper} d-flex flex-column align-items-start position-relative  mt-2 user-select-none`}>
            <input
                style={{ width: inputWidth + "px" }}
                type="text"
                ref={ref}
                maxLength={4}
                id={props.id}
                value={pin}
                spellCheck={props.spellCheck}
                className={`mb-0 e-caret-color-transparent`}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onChange={_handleOtpChange}
                onKeyDown={_preventDefaults}
                autoComplete="off"
                autoFocus={props.autoFocus}
                tabIndex={props.tabIndex} />


            <label ref={otpLabelInputRef} className='d-flex gap-12px' onClick={props.onFocus}>
                <div className={`d-flex align-items-center justify-content-center w-48px h-52 e-font-weight-500 e-font-16 line-height-20px  e-font-manrope 
                    ${props.focused_input === 2 ? 'e-border-otp-active' : 'e-border-otp'}`}>
                    {
                        pin.length !== 0 ?
                            <span className="pt-1 e-text-egyptian-blue">*</span> :
                            <span className="pt-2"></span>
                    }
                </div>
                <div className={`d-flex align-items-center justify-content-center w-48px h-52 e-font-weight-500 e-font-manrope e-font-16 line-height-20px 
                    ${props.focused_input === 2 && pin.length >= 2 ? 'e-border-otp-active' : 'e-border-otp'}`}>
                    {
                        pin.length >= 2 ?
                            <span className="pt-1 e-text-egyptian-blue">*</span> :
                            <span className={`pt-2`}></span>
                    }
                </div>
                <div className={`d-flex align-items-center justify-content-center w-48px h-52 e-font-weight-500 e-font-manrope e-font-16 line-height-20px 
                    ${props.focused_input === 2 && pin.length >= 3 ? 'e-border-otp-active' : 'e-border-otp'}`}>
                    {
                        pin.length >= 3 ?
                            <span className="pt-1 e-text-egyptian-blue">*</span> :
                            <span className={`pt-2`}></span>
                    }
                </div>
                <div className={`d-flex align-items-center justify-content-center w-48px h-52 e-font-weight-500 e-font-manrope e-font-16 line-height-20px 
                    ${props.focused_input === 2 && pin.length >= 4 ? 'e-border-otp-active' : 'e-border-otp'}`}>
                    {
                        pin.length >= 4 ?
                            <span className="pt-1 e-text-egyptian-blue">*</span> :
                            <span className={`pt-2`}></span>
                    }
                </div>
            </label>

            <p className={`${props.error ? "opacity-100" : "opacity-0"} h-10 mb-0 e-text-red e-font-12  e-font-weight-400 e-font-manrope`} >
                {props.error}
            </p>
        </div>
    )
});

OTPInput.defaultProps = {

}

export default memo(OTPInput);