/*
 *   File : badge.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : badge
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

import React from 'react';

/*import styles*/
import styles from '../../Styles/Components/badge.module.scss';

export default function Badge(props) {
	return (
		<span
			style={props.style}
			className={
				props.type === "Success" || props.type === "SUCCESS" ?
					`${styles.e_success} ${props.className}`
					:
					props.type === "Failed" || props.type === "FAILED" ?
						`${styles.e_fail} ${props.className}`
						:
						props.type === "PARTIALLY_COMPLETED" || props.type === "Partially Success" ?
							`${styles.e_partially_completed} ${props.className}`
							:

							`${styles.e_processing} ${props.className}`
			}
			onClick={props.handleClick}>{props.text}
		</span>
	);
}