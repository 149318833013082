import { takeEvery, put, delay } from "redux-saga/effects";
import { GET_INVESTMENT_TYPES, GET_ORDER_STATUS, GET_PAYMENT_STATUS } from "../Action/types";

function* _getOrderData(data) {
    yield delay(1000); // delay for 1 second
    try {

        yield put({
            type: GET_ORDER_STATUS,
            payload: data.payload
        });
    } catch (err) {
        yield put({
            type: GET_ORDER_STATUS,
            payload: []
        });
    }

}

function* _getPaymentData(data) {
    yield delay(1000); // delay for 1 second
    try {

        yield put({
            type: GET_PAYMENT_STATUS,
            payload: data.payload
        });
    } catch (err) {
        yield put({
            type: GET_PAYMENT_STATUS,
            payload: []
        });
    }

}

function* _getInvestmentStyles(data) {
    try {

        yield put({
            type: GET_INVESTMENT_TYPES,
            payload: data.payload
        });
    } catch (err) {
        yield put({
            type: GET_INVESTMENT_TYPES,
            payload: []
        });
    }

}

export function* _fetchAllData() {
    yield takeEvery(GET_PAYMENT_STATUS, _getPaymentData);
    yield takeEvery(GET_ORDER_STATUS, _getOrderData);
    yield takeEvery(GET_INVESTMENT_TYPES, _getInvestmentStyles);
}


