/*
 *   File : manage-content.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : ManageContent file for manage-content container;
 *   Integrations :28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
*/

/*import packages*/
import { useEffect, useState } from 'react';

/*import components*/
import { FundsTab } from '../../Components/DataFormating/index';
import { PrimaryBtn } from '../../Components/Cta/index';
import { AddFaq } from '../../Components/Modal/index';
import FaqAccordion from '../../Components/DataFormating/faq-accordion';
import Privacy from './privacy';
import { Loader } from '../../Components/Loader';
import { EmptyNote } from '../../Components/Note';


/* import assets */
import Add from '../../Assets/Images/ManageContent/add.svg'
import Terms from './terms';

/* import services */
import service from '../../Services/service';

const ManageContent = (props) => {
	const [index, setIndex] = useState(1);
	const [loader, setLoader] = useState(false);
	const [faqData, setFaqData] = useState([]);

	useEffect(() => {
		window.scrollTo({
			top: 0, behavior: 'smooth' //scroll top
		});

		_getFaq();
	}, [])

	/*--- Faq listing api ---*/
	const _getFaq = () => {
		setLoader(true);
		const url = "/content/list-faq"
		service(false, url).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				setFaqData(response.data);
			} else {
				setLoader(false);
				setFaqData([]);
			}
		});
	}

	return (
		<>
			<div className='e-tab-position'>
				<FundsTab
					data={['FAQ']}
					index={index}
					setTab={(temp) => setIndex(temp)} />
			</div>
			<div className="row position-relative">
				<h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
					FAQ
				</h3>
				<div className="col-lg-12">
					<div className="card e-dashboard-card e-faq-card border-0 p-4">
						{
							index === 1 ?
								<>
									<div className="row mb-4">
										<div className="col-lg-9 col-8">
											<h6 className="e-card-title pt-3">Question & answer</h6>
										</div>
										<div className="col-lg-3 col-4 d-flex justify-content-end">
											<PrimaryBtn name="Add question"
												prefix={Add}
												target="#addFaq"
												toggle="modal" />
										</div>
									</div>
									{
										loader ?
											<Loader />
											:
											faqData.length > 0 ?
												<div className="col-lg-12">
													<FaqAccordion data={faqData} update={() => { _getFaq() }} />
												</div>
												:
												<EmptyNote note="No data found" />
									}
								</>
								:
								index === 2 ?
									<>
										<Privacy />
									</>
									:
									<>
										<Terms />
									</>
						}
					</div>
				</div>
			</div>
			{/* add faq modal */}
			<AddFaq
				edit={false}
				update={() => { _getFaq() }}
				close={() => {

				}} />
		</>
	)
}

export default ManageContent;