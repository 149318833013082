/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import package*/
import React, { useState } from 'react';

/*import components*/


/*import style*/
import styles from '../../Styles/Components/accordion-user-investment.module.scss';

/*import assets*/
// import Icon from '../../assets/images/dataFormating/Icon.svg';
// import { IconStyleToolTip } from '../badge';

export default function AccordionUserInvestment(props) {
    const [id, setId] = useState(null);
    // const wrapperRef = useRef(null);
    //eslint-disable-next-line
    // const [tooltip, setTooltip] = useState(false);


    // /* ----- closing tooltip on mouse click in any other position of screen ----*/
    // useEffect(() => {
    //     window.scrollTo({ top: 0, behavior: 'smooth' });
    //     document.addEventListener('mouseup', closeMenu);
    //     return () => {
    //         document.removeEventListener('mouseup', closeMenu);
    //     };
    // }, []);

    // const closeMenu = (event) => {
    //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //         setTooltip(false)
    //     }
    // }

    return (
        <div className={`${styles.e_user_accordion} e-allcation-accordion`}>
            <div className="accordion" id="accordionDescription">
                <div className="row mx-0">
                    {
                        props.summaryData.map((item, key) => {
                            return (
                                <div className={`${styles.e_accordion_sec} col-lg-12`}>
                                    <div className={`${styles.e_accordion_item} accordion-item`}>
                                        <h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription" + key} >
                                            <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`} >
                                                <div className="row" onClick={() => { setId(key + 1); }}>
                                                    {
                                                        (key + 1) !== id ?
                                                            <div className="row" >
                                                                <div className="col-lg-10" >
                                                                    <span className={`${styles.e_accordion_title}`}>{item.name}</span>

                                                                </div>
                                                                <div className="col-lg-2" >
                                                                    <span className={`${styles.e_accordion_icon}`} ></span>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="row" onClick={() => { setId(null); }}>
                                                    {
                                                        (key + 1) === id ?
                                                            <div className="row" >
                                                                <div className="col-lg-10" >
                                                                    <span className={`${styles.e_accordion_title}`}>{item.name}</span>

                                                                </div>
                                                                <div className="col-lg-2" >
                                                                    <span className={`${styles.e_accordion_icon_minus}`} ></span>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </button>
                                        </h2>
                                        {
                                            (key + 1) === id ?
                                                <div  >
                                                    <div className={`${styles.e_accordion_body} accordion-body `}>
                                                        <div className={`${styles.e_accordion_wrapper} `}>

                                                            <div className="row">
                                                                <div className='col-lg-3'>
                                                                    <div className={`${styles.e_accordion_body_wrapper}`}>
                                                                        <h6>Target amount</h6>
                                                                        {
                                                                            item.target ?
                                                                                <p className='mb-0 pt-2'>₹ {Number((item.target).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                :
                                                                                0
                                                                        }                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3 e-investment-tooltip position-relative '>
                                                                    <div className={`${styles.e_accordion_body_wrapper}`}>
                                                                        <h6>Amount invested
                                                                            {/* <img
                                                                                className="ps-2"
                                                                                src={Icon} ref={wrapperRef} alt=""
                                                                                onMouseEnter={() => setTooltip(true)}
                                                                                onMouseLeave={() => setTooltip(false)}
                                                                                style={{ cursor: "pointer" }}
                                                                            /> */}
                                                                        </h6>
                                                                        {/* {
                                                                            tooltip ?
                                                                                <IconStyleToolTip
                                                                                    className="e-icon-tooltip"
                                                                                    content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />

                                                                                :
                                                                                null
                                                                        } */}
                                                                        {
                                                                            item.invested_amount ?
                                                                                <p className='mb-0 pt-2'>{item.invested_amount_text}</p>
                                                                                :
                                                                                0
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3'>
                                                                    <div className={`${styles.e_accordion_body_wrapper} ${styles.e_accordion_body_wrapper_value}`}>
                                                                        <h6>Current value</h6>
                                                                        {
                                                                            item.current_amount_text ?
                                                                                <p className='mb-0 pt-2'>{item.current_amount_text}</p>
                                                                                :
                                                                                0
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3'>
                                                                    <div className={`${styles.e_accordion_body_wrapper}`}>
                                                                        <h6>Total return%</h6>
                                                                        {
                                                                            item.returns_percentage ?
                                                                                <p className='mb-0 pt-2'>{item.returns_percentage_text}</p>
                                                                                :
                                                                                0
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row pt-4'>
                                                                <div className="col-lg-12" >
                                                                    <span className={`${styles.e_accordion_sub_title}`}>Funds and investments</span>
                                                                </div>
                                                            </div>
                                                            <div className='row pt-2'>
                                                                <div className='col-lg-12'>
                                                                    <div className={`${styles.e_investment_wrapper} `}>
                                                                        <div className='row'>
                                                                            <div className='col-lg-6'>
                                                                                <h6>Invested Funds</h6>
                                                                            </div>
                                                                            <div className='col-lg-2'>
                                                                                <h6>Weightage</h6>
                                                                            </div>
                                                                            <div className='col-lg-4'>
                                                                                <h6>Amount</h6>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            item.funds.map((fundData) => {
                                                                                return (
                                                                                    <div className='row'>
                                                                                        <div className='col-lg-6'>
                                                                                            <p className='pt-3'>{fundData.scheme_name}</p>
                                                                                        </div>
                                                                                        <div className='col-lg-2'>
                                                                                            <p className='pt-3'>{fundData.allocation !== null ? fundData.allocation.toFixed(2) : "-"}%</p>
                                                                                        </div>
                                                                                        <div className='col-lg-4'>
                                                                                            <p className='pt-3'>{fundData.invested_amount ? `₹ ${((parseFloat(fundData.invested_amount))).toLocaleString('en-IN')}` : "--"}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}


