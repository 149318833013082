/*
 *   File : textbtn.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Link Text button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*Style Import*/
import styles from '../../Styles/Components/text-btn.module.scss';

// Components
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";

const TextBtn = (props) => {
	return (
		<button
			id={props.id}
			className={`${styles.e_text_btn} ${props.className}`}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
		>
			{
				props.left ?
					<img src={props.image} alt="" width={props.width} />
					:
					null
			}
			{
				props.icon &&
				<IcoMoon iconSet={iconSet}
					icon={props.icon}
					color={props.icon_color}
					size={props.icon_size} />
			}
			{props.name}
			{
				props.right ?
					<img src={props.image} alt="" width={props.width} />
					:
					null
			}

		</button>
	);
}


export default TextBtn;
