/*
 *   File : empty.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Empty Note
 *   Integrations :
 *   Version : v1.0
 *   Created :28-11-2023
 */

/*Import Packages*/

// import style
import style from '../../Styles/Components/empty-note.module.scss';

// import assets
import Illustration from '../../Assets/Images/Note/Error-bro.png';

const Nodata = (props) => {
    return (
        <div className={`${style.e_nodata_wrapper} col-lg-12 text-center`}>
            <img src={Illustration}
                className={props.className}
                alt=""
                draggable={false} />
            <h5>{props.title ? props.title : 'Please try later'}</h5>
            <p>{props.note ? props.note : 'There is nothing found'}</p>
        </div>
    )
}

export default Nodata;
