/*
 *   File : questionnaire.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Questionnaire file for Risk and Funds container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-11-2023
*/

/*import packages*/
import { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';

/*import components*/
import { FundsTab } from '../../Components/DataFormating';
import { Toast } from '../../Components/Note';
import { Loader } from '../../Components/Loader';
import { AccordionQuiz } from '../../Components/DataFormating';
import { AddQuestion, AddTopic } from '../../Components/Modal';


/*import services */
import service from '../../Services/service';

const Questionnaire = (props) => {
    const [tabIndex, setTabindex] = useState(1);
    const [error, setError] = useState(null);
    const [details, setTopicDetails] = useState({});
    const [getTopics, setTopicData] = useState([]);
    const [allQuestion, setQuestionData] = useState([]);
    const [questionOptions, setQuestionOptions] = useState([]);

    useEffect(() => {
        if (getTopics.length > 0) {
            setTopicDetails(getTopics[0])
            setTabindex(getTopics[0].category_id);
        }
    }, [getTopics]);

    useEffect(() => {
        _getQuestionTypes();
        if (getTopics.filter((data) => data.category_id === tabIndex)) {
            _getQuestions();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    });

    /*------ add topic modal----*/
    const openModal = () => {
        var myModal = new Modal(document.getElementById("addTopic"), {});
        myModal.show();
    }

    /* ----- add Question Modal ---*/
    const addQuestion = () => {
        var myModal = new Modal(document.getElementById("addFundClass"), {});
        myModal.show();
    }

    /*----- api for listing questions for each topic in tab----*/
    const _getQuestions = () => {
        let url = '/quiz/list-questions';
        service(false, url).then((response) => {
            if (response?.status_code === 200) {
                if (response.status === true) {
                    setQuestionData(response.data)
                }
            }
            else {
                setError(response?.data.message)
            }
        })
    }

    const _getQuestionTypes = () => {
        let url = '/quiz/get-questiontype';
        service(false, url).then((response) => {
            if (response?.status_code === 200) {
                if (response.status === true) {
                    let questions = []
                    response.data.map((item) => (
                        questions.push({
                            label: item.name,
                            value: item.id
                        })
                    ))
                    setQuestionOptions(questions);
                }
            }
            else {
                setError(response?.data.message)
            }
        })
    }
    return (
        <>
            <div className="row pb-5 position-relative mt-2 pt-1">
                <div className="col-lg-12 mt-5">
                    <div className='e-tab-position'>
                        <h3 >Risk questionnaire</h3>
                    </div>
                    <div className="card e-dashboard-card e-faq-card e-quiz-card border-0 p-4">
                        <div className="row">
                            <div className="col-lg-8 mt-2 mb-3 pe-0 e-quiz-scroll">

                                {/*----------- Topic tabs ------------------*/}
                                <div className="e-tab-container">
                                    <FundsTab
                                        risk={true}
                                        data={getTopics}
                                        setTab={(temp, data) => {
                                            setTabindex(temp);
                                            setTopicDetails(data);
                                        }}
                                        className="e-quiz-tab"
                                        toggle="tool-tip"
                                        index={tabIndex}
                                        title={(item) => item.topic}
                                        tooltip={true}
                                        currentIndex={tabIndex}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 mt-2 pt-2 mb-3 text-end ps-2">
                                <span className="e-add-quiz" onClick={() => openModal()}>Manage Topic</span>
                            </div>
                        </div>

                        {/*----------- Questions list ------------------*/}
                        {
                            allQuestion && allQuestion.length > 0 ?
                                <>
                                    <div className="col-lg-12 d-flex pt-3 justify-content-between">
                                        <h6 className="e-card-title pt-4 mt-2">Questions in {details?.category}</h6>
                                        <div className='card e-dashboard-card-alloc p-3'>
                                            <h6 className="e-card-title">Weightage:</h6>
                                            {
                                                allQuestion.map((data, key) => (
                                                    data?.category_id === details?.category_id ?
                                                        <span>
                                                            {
                                                                data.questions.length > 0 ?
                                                                    data.questions?.reduce((a, b) => a = a + b.weight, 0) :
                                                                    0
                                                            }/100
                                                        </span>
                                                        :
                                                        null
                                                ))
                                            }

                                        </div>
                                    </div>
                                    {
                                        allQuestion?.map((data, key) => (
                                            data?.category_id === details?.category_id ?
                                                <>
                                                    <div className="col-lg-12 pt-3">
                                                        <AccordionQuiz
                                                            data={data.questions}
                                                            question_options={questionOptions}
                                                            topicDetails={data}
                                                            details={details} edit={() => _getQuestions()}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                null
                                        ))
                                    }
                                </>
                                :
                                <Loader />
                        }

                        {/*----------- Add Question on click ------------------*/}
                        <div className="row mx-0 e-add-sec">
                            <div className="col-lg-10 ps-0" >
                                <h6>
                                    <span onClick={() => addQuestion()}>
                                        <span className="pe-2" >
                                            <img src={require('../../Assets/Images/DataFormating/add-new.svg').default} alt="add" />
                                        </span>
                                        <span>Add New</span>
                                    </span>
                                </h6>
                            </div>
                            <div className="col-lg-2 position-relative" >
                                <span className="e-quiz-icon" onClick={() => addQuestion()}></span>
                            </div>
                        </div>
                    </div>

                    {/* ----------- Modal to add topic ------------------ */}
                    <AddTopic topics={(data) => setTopicData(data)} />

                    {/*----------- Modal to add questions ------------------*/}
                    <AddQuestion details={details}
                        question_options={questionOptions}
                        addedQuestion={() => _getQuestions()} />
                </div>

                {/*----------- error message toast ------------------*/}
                {
                    error !== null ?
                        <Toast
                            data={error}
                            format={1}
                            type="fail"
                        />
                        :
                        null
                }
            </div>
        </>
    );
}

export default Questionnaire;