/*
 *   File : forgot-password.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import packages*/
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

/*import components*/
import { OuterHeader, Footer } from '../../Components/Navigation/index';
import { Input } from '../../Components/Input/index';
import { PrimaryBtn } from '../../Components/Cta/index';
import service from '../../Services/service';

/*import container*/

const ForgotPassword = () => {

	const navigate = useNavigate()
	const tempAccessToken = useRef("");

	const [email, setEmail] = useState("");
	const [toggleOTP, setToggleOTP] = useState(false);
	const [OTP, setOTP] = useState("");
	const [password, setPassword] = useState("");
	const [cpassword, setCpassword] = useState("");
	const [currentStep, setCurrentStep] = useState(1);
	const [loading, setLoading] = useState(false);

	const _handleSendOTP = () => {

		// email validation 
		let validate_email = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

		if (validate_email.test(email)) {
			setLoading(true);
			_sendEmailOTP();
		};

	};


	const _handleValidateOTP = () => {
		if (OTP.length === 4) {
			setLoading(true);
			_validateEmailOTP();
		}
	};

	const _handleBack = () => {
		setCurrentStep(currentStep - 1);
		setPassword("");
		setCpassword("");
		setLoading(false);
	};

	//  Password and confirm password validation 
	const _handleValidatePassword = () => {
		var pattern = new RegExp(/^(?=.*\d)(?=.*?[a-zA-Z])(?=.*?[\W_]).{8,100}$/);
		if (password !== cpassword) {
			toast.dismiss();
			toast.error("Your password and confirm password does not match");
		}
		else if (!pattern.test(password)) {
			toast.dismiss();
			toast.error("Password contain one special character and one digit with minimum 8 characters");
		} else {
			setLoading(true);
			_resetPassword();
		};
	};

	const _handleOTPToggle = () => {
		setToggleOTP(false); 
		setLoading(false);
	};

	// API - Send email otp
	const _sendEmailOTP = () => {

		let is_post = true;
		let url = '/auth/forgot-password';
		let data = {
			"email": email
		};

		service(is_post, url, data).then((response) => {
			if (response.status === true) {
				setToggleOTP(true);
				setOTP("");
			} else {
				toast.dismiss();
				toast.error(response.data.message);
			}
			setLoading(false);
		});
	};

	// API - Validate email otp
	const _validateEmailOTP = () => {
		let is_post = true;
		let url = '/auth/verify-otp';
		let data = {
			"email": email,
			"otp": OTP,
			"type": "RESET_PASSWORD"
		};

		service(is_post, url, data).then((response) => {
			if (response.status === true) {
				tempAccessToken.current = response.access_token.temp_token;
				setCurrentStep(2);
				setToggleOTP(false);
				setEmail("");
			} else {
				toast.dismiss();
				toast.error(response.data.message);
			}

			setLoading(false);
		});
	};

	// API - Used to reset password
	const _resetPassword = () => {

		let is_post = true;
		let url = '/auth/reset-password';
		let data = {
			"password": cpassword,
			"temp_token": tempAccessToken.current
		};

		service(is_post, url, data).then((response) => {
			if (response.status === true) {
				navigate("/");
				toast.dismiss();
				toast.success("Password reset successful. Please log in to continue.");
			} else {
				toast.dismiss();
				toast.error(response.data.message);
			};

			setLoading(false);
		});
	};

	return (
		<>
			<div className="container-fluid e-auth-wrapper vh-100 d-flex flex-column">
				<OuterHeader type={2} />
				<div className="container">
					<div className="row">
						{
							currentStep == 1 &&
							<div className="col-lg-4  offset-lg-4">
								<div className="e-form-wrap">
									{
										toggleOTP === false ?
											<>
												<h4>Enter email address</h4>
												<div className="pt-3">
													<label>Email*</label>
													<Input
														placeholder="Email"
														value={email}
														valueChange={(value) => { setEmail(value) }}
														type="email"
													/>
												</div>
												<div className="row mt-4  mb-1">
													<div className="col-lg-12">
														<PrimaryBtn
															name="Send OTP"
															className={`${loading && "e-btn-loader"} ${email ? "" : "e-disabled"} w-40per`}
															handleClick={_handleSendOTP}
														/>
													</div>
												</div>
											</>
											:
											<>
												<h4 className='d-flex align-items-center'>
													<img src={require("../../Assets/Images/right-black-icon.svg").default}
														draggable={false}
														role='button'
														className='e-back-btn '
														alt='back-icon'
															onClick={_handleOTPToggle} />
													OTP Verification
												</h4>
												<p className="pt-3">Please enter the One Time Password send to the email:<span className="ps-1">{email}</span> </p>
												<div className="">
													<label>Enter OTP*</label>
													<Input
														placeholder="OTP"
														value={OTP}
														valueChange={(value) => { setOTP(value) }}
														type="number"
													/>
												</div>
												<div className="row mt-3 mb-1">
													<div className="col-lg-12">
														<PrimaryBtn
															name="Verify OTP"
															className={`${loading && "e-btn-loader"} ${OTP ? "" : "e-disabled"} w-50per`}
															handleClick={_handleValidateOTP}
														/>
													</div>
												</div>
											</>
									}
								</div>
							</div>
						}
						{
							currentStep == 2 &&
							<div className="col-lg-4 offset-lg-4">
								<div className="e-form-wrap">
									<h4 className='d-flex align-items-center'>
										<img src={require("../../Assets/Images/right-black-icon.svg").default}
											draggable={false}
											role='button'
											className='e-back-btn '
											alt='back-icon'
											onClick={_handleBack} />
										Create password
									</h4>
									<div className="pt-3">
										<label>Password*</label>
										<Input
											placeholder="Password"
											value={password}
											valueChange={(value) => { setPassword(value) }}
											type="password"
										/>
									</div>
									<div className="pt-1">
										<label>Confirm password*</label>
										<Input
											placeholder="Confirm Password"
											value={cpassword}
											valueChange={(value) => { setCpassword(value) }}
											type="password"
										/>
									</div>
									<div className="row mt-4">
										<div className="col-lg-12">
											<PrimaryBtn
												name="Create password"
												className={`${loading && "e-btn-loader"} ${password && cpassword ? "" : "e-disabled"} w-60per`}
												handleClick={_handleValidatePassword}
											/>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
				<div className="e-auth-footer mt-auto">
					<Footer type={1} />
				</div>
			</div >
		</>
	);
}

export default ForgotPassword;

