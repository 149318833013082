/*
 *   File : download.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Dowload Button
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-11-2023
 */

/*Import Packages*/
import React from 'react';

/*import style*/
import styles from '../../Styles/Components/download.module.scss';

/* import assets */
import LoaderImg from '../../Assets/Images/Loader/btn-loader-blue.gif';

export default function DownloadBtn(props) {
	return (
		<>
			<button
				disabled={props.loading}
				onClick={props.onClick}
				className={!props.optionalDisable ? props?.check?.length > 0 ? `${styles.e_download_btn_active}` : `${styles.e_download_btn}` : `${styles.e_download_btn_active}`}
			>
				<span>
					{
						props.loading ? <img src={LoaderImg} alt="loader" /> : "Download report"
					}
				</span>

			</button>
		</>

	);
}
