/*
 *   File : otherMenu.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Other Menu
 *   Integrations : 28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";

/*import components*/
import { Toast } from '../Note';

/*import styles*/
import styles from '../../Styles/Components/notification-menu.module.scss';

/*import assets*/
import Bell from '../../Assets/Images/Navigation/bell.svg';
import LoaderImg from '../../Assets/Images/Loader/mintd-loader.gif';

/*import services*/
import service from '../../Services/service';

export default function NotificationMenu(props) {

	const navigate = useNavigate();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [notificationData, setNotificationData] = useState([]);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	useEffect(() => {
		setLoading(true);
		setNotificationData([]);
		_getNotification();
	}, []);

	/*---- read notification api----*/
	// const _readNotification = (id) => {
	// 	const url = '/notifications/read';

	// 	const data = {
	// 		notification_id: id,
	// 	}
	// 	service(true, url, data).then((response) => {
	// 		if (response.status_code === 200) {
	// 			if (id === null) {
	// 				props.update();
	// 			} else {
	// 				navigate('/all-notification');
	// 				props.close();
	// 			}
	// 		} else {
	// 			setError(response.data.message);
	// 		}
	// 	});
	// };

	// API - List notifications  
	const _getNotification = () => {
		const url = '/notification/get-received-notification'
		const data = {
			page_num: 1
		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setNotificationData(response.data);
			}
			else {
				setError(response.data.message);
			}

			setLoading(false);
		});
	};

	return (
		<>
			<div className={`${styles.e_notification_menu} notification `} >
				<div className={`${styles.e_notification_div} h-400px d-flex justify-content-start flex-column`}>
					<div className="d-flex justify-content-between">
						<span className={`${styles.e_title} `}>Notifications</span>
						{/* <span><PrimaryLink name="Mark all as read" handleClick={() => _readNotification(null)} /></span> */}
					</div>
					<span className={`${styles.e_span_border} my-3`}></span>
					{
						loading ? (
							<div className=' mt-5 text-center pt-5'>
								<img src={LoaderImg} alt="" width={24} height={24} />
							</div>
						) : (
							notificationData.length > 0 ?
								<>

									<div className={`${styles.e_notification_sub_data} flex-fill`}>
										{
											notificationData.filter((item) => item.is_read === false).map((data) => (
												<div style={{ cursor: "pointer" }}
													className='d-flex pt-3 align-items-start'
													onClick={() => {
														navigate(`/user-detail?id=${data.data_id}&tab=${2}`);
														props.close();
													}}>
													<span>

														<IcoMoon iconSet={iconSet}
															size={"1.5rem"}
															icon={"bell"} />
													</span>
													{/* <img src={Bell} alt="" /> */}
													<div className='ps-2'>
														<h6 className={`${styles.e_notification_title}`}>{data.title}</h6>
														<p className={`${styles.e_notification_content} mb-1`}>{data.description}</p>
													</div>
												</div>
											))
										}
									</div>
									<div className={`${styles.e_view} pt-3 pb-3 mt-auto`}>
										<span className='cursor-pointer border-1px-bottom-hover'
											onClick={() => {
												navigate("/all-notification");
												props.close();
											}}>
											View all
										</span>
									</div>
								</>
								:
								null
						)
					}
				</div>
			</div>

			{
				error !== null ?
					<Toast data={error} id="1" type="error" />
					:
					null
			}
		</>
	)
}

