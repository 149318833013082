/*
 *   File : select-suggestion.js
 *   Author : https://evoqins.com
 *   Description : Select Suggestion modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useState } from 'react';
import { CustomTextInput } from '../Form-Elements';

// Assets
import Search from "../../Assets/Images/Cta/search.svg";
import Close from "../../Assets/Images/Cta/close-black.png";
import LoaderImg from '../../Assets/Images/Loader/btn-loader-blue.gif';

const SelectionSuggestModal = (props) => {

    const [selected, setSelected] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const [list, setList] = useState(props.data);

    useEffect(() => {
        if (props.selectedOption) {
            setSelected(props.selectedOption);
        };

    }, [props.selectedOption]);

    useEffect(() => {
        setList(props.data);
    }, [props.data]);

    //handle select 
    const _handleSelect = (selected) => {
        props.onSelectOption(selected);
        //handle modal close
        var button = document.getElementById(`suggestion-modal-close${props.type}`);
        button.click();
    };

    //handle search
    const _handleSearch = (value) => {
        if (value.trim().length == 0) {
            setSearchValue("");
            setList(props.data);
            return;
        };

        setSearchValue(value);

        if (value.length > 2) {
            let filtered_data = props.data.filter(item => (
                item.name.toLocaleLowerCase().startsWith(value.toLocaleLowerCase())
            ));

            setList(filtered_data);
        }
    };

    return (
        <div className="modal fade e-manage-modal"
            data-bs-backdrop={props.backdrop ? props.backdrop : "static"}
            id={props.id}
            tabIndex="-1"
            aria-labelledby="selectSuggestModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content e-border-radius-8 e-modal-box-shadow overflow-hidden p-0 h-90vh">
                    <div className="modal-header justify-content-between py-3  px-3 border-0">
                        <h6 className='mb-0 e-text-charleston-green e-font-18  e-font-manrope e-font-weight-600'>
                            {props.title}
                        </h6>
                        <button type="button"
                            id={`suggestion-modal-close${props.type}`}
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onModalClose}></button>
                    </div>
                    {
                        props.search &&
                        <div className='px-4 pb-0'>
                            <div className="position-relative">
                                <CustomTextInput id="search"
                                    className="e-font-14 w-100 no-outline py-2 px-4 ms-3"
                                    placeholder={props.searchPlaceholder}
                                    autoComplete="off"
                                    value={searchValue}
                                    height={"h-auto"}
                                    handleChange={_handleSearch} />
                                <img src={Search}
                                    draggable={false}
                                    width={16}
                                    className="position-absolute top-12px  start-0 ms-3"
                                    alt="search" />

                                {
                                    searchValue &&
                                    <img src={Close}
                                        draggable={false}
                                        width={10}
                                        className="opacity-75 object-fit-contain position-absolute top-12px mt-1 end-0 me-3 cursor-pointer"
                                        alt="clear"
                                        onClick={() => {
                                            setSearchValue("");
                                            setList(props.data);
                                        }} />
                                }
                            </div>
                        </div>
                    }
                    {
                        props.loading == false ?
                            <div className="modal-body pb-4 px-3 pt-0">
                                {
                                    list && list.map((data, key) => (
                                        <div key={key}>
                                            <div className='d-flex gap-3 cursor-pointer'
                                                onClick={() => _handleSelect(data)}>
                                                {selected.id === data.id &&
                                                    <img src={require('../../Assets/Images/Kyc/tick.svg').default}
                                                        draggable={false}
                                                        alt='mark' />
                                                }
                                                <p className={`${selected.id === data.id ? `e-text-egyptian-blue` : `e-text-light-grey ps-4 ms-3`}  mb-0 py-3  e-font-14  e-font-manrope e-font-weight-500 line-height-24px`}>
                                                    {data.display_name ? data.display_name : data.name}
                                                </p>
                                            </div>
                                            {/* border */}
                                            {key !== props.data.length - 1 && (
                                                <div className="margin-40px-left e-border-bottom-1px-light-gray"></div>
                                            )}
                                        </div>
                                    ))
                                }

                                {/* No data message */}
                                {
                                    list.length == 0 &&
                                    <p className='text-center mt-4 e-text-charleston-green e-font-16  e-font-manrope e-font-weight-600'>
                                        No data found!
                                    </p>
                                }
                            </div>
                            :
                            <div className='h-100 d-flex align-items-center justify-content-center'>
                                <img src={LoaderImg} alt="loading spinner" width={24} />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SelectionSuggestModal;

SelectionSuggestModal.defaultProps = {
    loading: false
}