/*
 *   File : editRevenue.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations : 
 *   Version : v1.0
 */

/*import packages*/
import React, { useState } from 'react';

/*import componets*/
import { Input, Radio } from '../Input/index';
import { SecondaryBtn, PrimaryBtn } from '../Cta/index';


/*import styles*/
import styles from '../../Styles/Components/fund-class.module.scss';


export default function EditRevenue(props) {

	const [name, setName] = useState("");
	const [value, setValue] = useState("1");

	return (
		<div className={`${styles.e_add_fund} modal fade`} id="editRevenue" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h6>Edit fee</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className="col-lg-3">
								<Radio id={1} value="1" label="Percentage" valueClick={(e) => setValue(e)} name="check" checked={value === "1" ? true : false} />
							</div>

							<div className="col-lg-3">
								<Radio id={2} value="2" label="Fixed fee" valueClick={(e) => setValue(e)} name="check" checked={value === "2" ? true : false} />
							</div>

						</div>
						{
							value === "1" ?
								<div className="row mt-3">
									<div className="col-lg-12 position-relative">
										<label>Fee percentage</label>
										<Input placeholder="Percentage" suffix="%" className="position-relative" value={props?.editData ? name : name} valueChange={props?.editData ? (value) => { setName(value) } : (value) => { setName(value) }} type="number" />
									</div>
								</div>
								: value === "2" ?
									<div className="row mt-3">
										<div className="col-lg-12 position-relative">
											<label>Fixed fee</label>
											<Input placeholder="Fee" suffix="₹" value={props?.editData ? name : name} className="position-relative" valueChange={props?.editData ? (value) => { setName(value) } : (value) => { setName(value) }} type="number" />
										</div>
									</div>
									:
									null

						}
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn name="Cancel" className={`${styles.e_cancel} e-cancel`} cancel="modal" />
									{
										name ?
											<PrimaryBtn name="Save fee" className="e-modal-submit" />
											:
											<PrimaryBtn name="Save fee" className="e-disabled" />
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
