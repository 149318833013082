/*
 *   File : profile.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : profile
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
*/

/*import packages*/
import { Modal } from 'bootstrap'

/*import components*/
import { ProfileKyc } from '../../Components/Cards/index';
import { useEffect, useState } from 'react';
import { EditBankDetailsModal, EditBasicDetailsModal } from '../../Components/Modal';

const Profile = (props) => {

	const [showBasicDataEditModal, setShowBasicDataEditModal] = useState();
	const [showBankEditModal, setShowBankEditModal] = useState();

	// handle open basic data edit modal
	useEffect(() => {
		if (showBasicDataEditModal) {
			let edit_modal = new Modal(document.getElementById("edit-basic-detail-modal"));
			edit_modal.show();
		}
	}, [showBasicDataEditModal]);

	// handle open basic data edit modal
	useEffect(() => {
		if (showBankEditModal) {
			let edit_modal = new Modal(document.getElementById("edit-bank-detail-modal"));
			edit_modal.show();
		}
	}, [showBankEditModal]);

	// handle open edit modal
	const _handleEdit = (type) => {
		if (type === 1) {
			setShowBasicDataEditModal(true);
		} else if (type === 3) {
			setShowBankEditModal(true);
		}
	};

	// handle edit success
	const _handleEditSuccess = () => {
		setShowBasicDataEditModal(false);
		setShowBankEditModal(false);
		props.handleEditSuccess();
	};


	return (
		<>
			<div className="col-lg-12 mb-4" >
				<ProfileKyc
					data={props.data}
					userId={props.userId}
					title="Profile overview"
					type={1}
					edit={true}
					onEdit={(() => _handleEdit(1))}
				/>
			</div>
			<div className="col-lg-12 mb-4" >
				<ProfileKyc
					data={props.data}
					title="KYC details"
					type={2}
					onEdit={() => { }}
					onAddressEdit={props.handleEditSuccess}
					verified={props.data.kyc.aadhaar_card_number}
				/>
			</div>
			<div className="col-lg-6 mb-4" >
				<ProfileKyc
					data={props.data}
					title="Bank details"
					type={3}
					edit={true}
					verified={false}
					onEdit={() => _handleEdit(3)}
				/>
			</div>
			<div className="w-100"></div>
			<div className="col-lg-6 mb-4" >
				<ProfileKyc
					data={props.data}
					title="Nominee details"
					type={5}
					verified={false}
					onEdit={() => { }}
				/>
			</div>
			{/* <div className="col-lg-6 mb-3" >
				<ProfileKyc
					data={props.data}
					title="Autopay"
					type={4}
					Clicked={(temp) => _setData(temp)}
					verified={false}
				/>
			</div> */}
			{/* <EditUser id="editDetails" data={editableData} userId={props.userId} editUserDetails={(data) => props.editUserDetails(data)} /> */}

			{
				showBasicDataEditModal &&
				<EditBasicDetailsModal
					data={props.data}
					userId={props.userId}
					onCloseModal={() => setShowBasicDataEditModal(false)}
					handleEditSuccess={_handleEditSuccess} />
			}

			{
				showBankEditModal &&
				<EditBankDetailsModal
					data={props.data}
					userId={props.userId}
					onCloseModal={() => setShowBankEditModal(false)}
					handleEditSuccess={_handleEditSuccess} />
			}
		</>
	);
}

export default Profile;