/*
 *   File : redemption.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Redemption file for Redemption container;
 *   Integrations : 29-11-2023
 *   Version : v1.0
 *   Created : 29-11-2023
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

/*import components*/
import { DataTable } from '../../Components/DataFormating/index';
import { Loader } from '../../Components/Loader';
import { EmptyNote, Toast } from '../../Components/Note';
import { ConfirmationforRedemption } from '../../Components/Modal';
import { PrimaryBtn, SecondaryBtn } from '../../Components/Cta';

/*import styles*/


/* import services */
import service from '../../Services/service';
import { Modal } from 'bootstrap';



const Redemption = () => {

    const navigate = useNavigate();
    const [userData, setRedemptionData] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(true);
    const [success, setSuccess] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [type, setType] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        if (orderId !== null) {
            var modal = new Modal(document.getElementById("confirm-redemption"));
            modal.show();
        }
    }, [orderId]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        }
    }, [success]);


    useEffect(() => {
        _getRedemptionList();
        //eslint-disable-next-line
    }, []);

    // const _customDateSort = (a, b, selector, given) => {
    //     if (selector === "joined") {
    //         let format = given ? given : "DD MMM YYYY"
    //         if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
    //         else if (a[selector]) return -1;
    //         else if (b[selector]) return 1;
    //         else return 0;
    //     }
    // }


    /*--------- active users list -----------*/
    const _getRedemptionList = () => {
        const url = '/redemption/requests';
        const data = {
            customer_id: null
        }

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setRedemptionData(response.data);
            }
            else {
                setRedemptionData([]);
            }
            setLoader(false);
        });
    }


    const _openConfirmation = (id) => {
        setOrderId(id)
    }


    /* ---- columns for redemption table ---*/
    const REDEMPTION_COLUMNS = [
        {
            name: 'Investment type',
            left: true,
            minWidth: "400px",
            cell: row => (
                <div className="d-flex gap-2 py-2">
                    <img src={row.icon}
                        alt="fund_image"
                        draggable="false"
                        className='me-2 rounded-4 bg-white'
                        width="24"
                        height="24" />
                    {row.name}
                </div>
            )

        },
        {
            name: '',
            selector: 'actions',
            sortable: false,
            left: true,
            minWidth: "210px",
            cell: (row, key) => (
                <div className="d-flex align-items-end">
                    <SecondaryBtn name="Reject" className="py-2 e-delete"
                        handleClick={() => {
                            _openConfirmation(row.order_id);
                            setType(2);
                        }} />
                    <PrimaryBtn name="Approve" className="w-auto px-3 ms-2"
                        handleClick={() => {
                            _openConfirmation(row.order_id);
                            setType(1);
                        }} />
                </div>
            )
        },
        {
            name: 'Customer name',
            selector: (row) => row["customer_name"].toLowerCase(),
            sortable: true,
            left: true,
            minWidth: '180px',
            cell: row => <>
                {
                    row.name ?
                        <div className='e-link link-offset-1' onClick={() => {
                            navigate(`/user-detail?id=${row.customer_id}`)
                        }}>{row.customer_name}</div>
                        :
                        "--"
                }
            </>
        },
        {
            name: 'Order ID',
            selector: (row) => row.order_no ? row.order_no : '--',
            sortable: true,
            left: true,
            minWidth: "130px",
            defaultSortAsc: false,
        },
        {
            name: 'FP ID',
            selector: (row) => row.order_id ? row.order_id : '--',
            sortable: true,
            left: true,
            minWidth: "75px",
            defaultSortAsc: false,
        },

        {
            name: 'Created on',
            selector: row => row.order_time,
            // sortable: true,
            left: true,
            minWidth: '185px',
            sortFunction: (a, b) => moment(a.order_time) - moment(b.order_time),
            cell: row => <div className="col-lg-12">
                {row.order_time}
            </div>
        },

        {
            name: 'Amount',
            selector: row => row.amount ? Number(row.amount.replaceAll(/[₹,]/g, "")) : 0,
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                {
                    row.amount ?
                        row.amount
                        :
                        0
                }
            </div>

        },

        // {
        //     name: 'Basket name',
        //     left: true,
        //     minWidth: "150px",
        //     cell: row => <div className="py-2">
        //         {row.basket_name ? row.basket_name : "-"}
        //     </div>,

        // },

    ];

    // Row styles
    const custom_styles = {
        rows: {
            style: {
                paddingTop: "10px",
                paddingBottom: '10px',
                border: "none !important"
            },
        },
    };




    return (
        <div className="row position-relative mt-5 pt-3">
            <div className="col-lg-12">
                <div className='e-tab-position'>
                    <h3>Manage redemption</h3>
                </div>
                <div className="card e-dashboard-card border-0">
                    <div className="row pt-2">
                        <div className="col-lg-4 col-3">
                            <h6 className="e-section-title p-2 ps-1">Redemption</h6>
                        </div>

                        {
                            loader ?
                                <Loader />
                                :
                                userData.length > 0 ?
                                    <div className="col-lg-12 px-0">
                                        <DataTable
                                            pagination={true}
                                            columns={REDEMPTION_COLUMNS}
                                            data={userData}
                                            perPage={30}
                                            selectableRows={false}
                                            customStyles={custom_styles}
                                        // rowClick={(row) => navigate(`/user-detail?id=${row.user_id}`)}
                                        />
                                    </div>
                                    :
                                    <EmptyNote note="No data found" />
                        }
                    </div>
                </div>
            </div>
            {/* confirmation modal for blocking */}
            {
                orderId !== null ?
                    <ConfirmationforRedemption
                        title='Approve'
                        content={type === 1 ? `Approve withdrawal request` : `Reject withdrawal request`}
                        id={orderId}
                        type={type}
                        updateStatus={() => {
                            _getRedemptionList()
                        }}
                        onCloseModal={() => {
                            setType(null);
                            setOrderId(null);
                        }} />
                    :
                    null
            }
            {/* error toast */}
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        id="1"
                    />
                    :
                    null
            }
            {/* Success toast */}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2" />
                    :
                    null
            }

        </div>
    );
}

export default Redemption;

