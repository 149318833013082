/*
 *   File : outerHeader.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Login Header
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import packages*/
import React from 'react';
import { Link } from "react-router-dom";

/*import styles*/
import styles from '../../Styles/Components/outer-header.module.scss';

/*import config*/
import { TextBtn } from '../Cta';


/*import components*/
// import { TextBtn } from '../../components/cta/index';

const OuterHeader = (props) => {
	return (
		<div className={`row ${styles.e_outer_header}`}>
			<div className="col-lg-6 col-md-6 col-sm-6 col-4">
				<Link to='/'>
					<img src={require('../../Assets/Images/Navigation/outer-logo.svg').default} alt="Cashyu Logo" className={`${styles.e_logo}`} />
				</Link>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 col-8 text-end">
				{/* Will be enabled in future  */}
				{
					props.type === 1 ?
						<Link to="/forgot-password">
							<TextBtn name="Forgot password" className={`${styles.e_btn}`} />
						</Link>
						:
						null
				}
			</div>
		</div>
	)
}

export default OuterHeader;

