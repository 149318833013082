/*
 *   File : edit-address.js
 *   Author : https://evoqins.com
 *   Description : Modal to edit user address 
 *   Integrations : react-toastify
 *   Version : 1.0.0
 *   Date : 23-08-2024
*/

import React, { memo, useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

//custom component
import { PrimaryBtn, SecondaryBtn } from '../Cta';
import service from '../../Services/service';
import { CustomTextInput } from '../Form-Elements';
import SelectionSuggestModal from './select-suggestion';


const EditAddress = (props) => {

    const user_address = props.data.kyc.address;
    const is_permanent_address = props.editPermanentAddress ? true : false;

    const [address, setAddress] = useState(user_address.permanent_address.address_line1);
    const [addressError, setAddressError] = useState("");
    const [secondAddress, setSecondAddress] = useState(user_address.permanent_address.address_line2);
    const [secondAddressError, setSecondAddressError] = useState("");

    const [selectedState, setSelectedState] = useState({
        "id": user_address.permanent_address.state_id,
        "name": user_address.permanent_address.state,
    });

    const [stateError, setStateError] = useState("");
    const [selectedCity, setSelectedCity] = useState({
        "id": user_address.permanent_address.city_id,
        "name": user_address.permanent_address.city
    });
    const [cityError, setCityError] = useState("");

    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [countryData, setCountryData] = useState([]);

    const [pinCode, setPinCode] = useState(user_address.permanent_address.pincode);
    const [pinCodeError, setPinCodeError] = useState("");

    const [showSuggestModal, setShowSuggestModal] = useState(false);
    const [modalType, setModalType] = useState("");

    //correspond
    const [correspondNation, setCorrespondNation] = useState({
        "id": user_address.correspondence_address.country_id || "",
        "name": user_address.correspondence_address.country || "",
    });
    const [correspondNationError, setCorrespondNationError] = useState("");

    const [correspondAddressOne, setCorrespondAddressOne] = useState(user_address.correspondence_address.address_line1 || "");
    const [correspondAddressError, setCorrespondAddressError] = useState("");

    const [correspondSecondAddress, setCorrespondSecondAddress] = useState(user_address.correspondence_address.address_line1 || "");
    const [correspondSecondAddressError, setCorrespondSecondAddressError] = useState("");

    const [correspondState, setCorrespondState] = useState(user_address.correspondence_address.state || "");
    const [correspondStateError, setCorrespondStateError] = useState("");

    const [correspondCity, setCorrespondCity] = useState(user_address.correspondence_address.city || "");
    const [correspondCityError, setCorrespondCityError] = useState("");

    const [correspondPinCode, setCorrespondPinCode] = useState(user_address.correspondence_address.pincode || "");
    const [correspondPinCodeError, setCorrespondPinCodeError] = useState("");

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        _getCountry();
        _getState();
    }, []);

    useEffect(() => {
        if (selectedState.name) {
            _getCity();
        }
    }, [selectedState]);

    //Modal
    useEffect(() => {

        if (showSuggestModal === true) {
            if (modalType === 1) {
                let state_modal = new Modal(document.getElementById('select-state-modal'));
                state_modal.show();
            } else if (modalType === 2) {
                let city_modal = new Modal(document.getElementById('select-city-modal'));
                city_modal.show();
            } else if (modalType === 3) {
                let city_modal = new Modal(document.getElementById('select-correspond-country-modal'));
                city_modal.show();
            } else if (modalType === 4) {
                let proof_modal = new Modal(document.getElementById('select-address-proof-modal'));
                proof_modal.show();
            }
        };

    }, [showSuggestModal]);

    useEffect(() => {
        setAddressError("");
    }, [address]);

    useEffect(() => {
        setSecondAddressError("");
    }, [secondAddress]);

    useEffect(() => {
        setStateError("");
    }, [selectedState]);

    useEffect(() => {
        setCityError("");
    }, [selectedCity]);

    useEffect(() => {
        setPinCodeError("");
    }, [pinCode]);

    //correspond error
    useEffect(() => {
        setCorrespondNationError("");
    }, [correspondNation]);

    useEffect(() => {
        setCorrespondAddressError("");
    }, [correspondAddressOne]);

    useEffect(() => {
        setCorrespondSecondAddressError("");
    }, [correspondSecondAddress]);

    useEffect(() => {
        setCorrespondStateError("");
    }, [correspondState]);

    useEffect(() => {
        setCorrespondCityError("");
    }, [correspondCity]);

    useEffect(() => {
        setCorrespondPinCodeError("");
    }, [correspondPinCode]);


    //handle address change 
    const _handleAddressChange = (value) => {
        setAddress(() => value);
    };

    //handle address change 
    const _handleSecondAddressChange = (value) => {
        setSecondAddress(() => value);
    };

    //handle modal
    const _handleSuggestionModal = (type) => {
        setModalType(type);
        setShowSuggestModal(true);
    };

    //handle select state
    const _handleSelectState = (value) => {
        setSelectedState(value);
        // clearing city when state changes
        setSelectedCity({ id: null, name: "" });
    }

    //handle select city
    const _handleSelectCity = (value) => {
        setSelectedCity(value);
    }

    // handle pin change
    const _handlePinChange = (value) => {
        setPinCode(value);
    };

    // handle corr - pin change
    const _handleCorrPinCodeChange = (value) => {
        setCorrespondPinCode(value);
    };

    //handle select correspond nation
    const _handleCorrespondNation = (value) => {
        setCorrespondNation(value);
        setCorrespondState({ id: null, name: "" });
        setCorrespondCity({ id: null, name: "" });
        setCorrespondPinCode("");
        setCorrespondStateError("");
        setCorrespondCityError("");
        setCorrespondPinCodeError("");
    };


    // handle validate 

    //handle submit data
    const _handleUpdate = () => {

        let errors = [];
        let validation = true;

        if (is_permanent_address) {
            if (address.length === 0) {
                setAddressError("Please provide address details");
                errors.push("address-1");
                validation = false;
            }
            if (secondAddress.length === 0) {
                setSecondAddressError("Please provide additional address details");
                errors.push("address-2");
                validation = false;
            }

            if (selectedState.length === 0) {
                setStateError("Please provide state name");
                errors.push("state");
                validation = false;
            }

            if (selectedCity.length === 0) {
                setCityError("Please provide your city name");
                errors.push("city");
                validation = false;
            }

            if (pinCode.length === 0 || pinCode.length < 6) {
                setPinCodeError(pinCode.length === 0 ? "Provide your PIN code" : "Enter full PIN code");
                errors.push("pincode");
                validation = false;
            }
        } else {
            if (props.data.overview.is_indian_tax_payer === false) {
                if (correspondNation.length === 0) {
                    setCorrespondNationError("Please provide your nationality information");
                    errors.push("corrs-nationality");
                    validation = false;
                }
                if (correspondAddressOne.length === 0) {
                    setCorrespondAddressError("Please provide your address details")
                    errors.push("corrs-address-1");
                    validation = false;
                }

                if (correspondSecondAddress.length === 0) {
                    setCorrespondSecondAddressError("Please provide your additional address details");
                    errors.push("corrs-address-2");
                    validation = false;
                }

                if (correspondState.length === 0) {
                    setCorrespondStateError("Please provide your correspond state");
                    errors.push("corrs-state");
                    validation = false;
                }

                if (correspondCity.length === 0) {
                    setCorrespondCityError("Please provide your correspond city");
                    errors.push("corrs-city");
                    validation = false;
                }

                if (correspondPinCode.length === 0 || correspondPinCode.length < 6) {
                    setCorrespondPinCodeError(correspondPinCode.length === 0 ? "Please provide your ZIP code" : "Please provide your full ZIP code");
                    errors.push("corrs-pincode");
                    validation = false;
                }
            };
        }

        // Scrolling to first error
        if (errors.length !== 0) {
            let first_err_id = errors[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

        // Saving details
        if (validation === true) {
            _updateAddress();
            setLoading(true);
        };

    };





    //======= API CALLS =======//

    //API- Get countries
    const _getCountry = () => {
        let is_post = false;
        let url = '/general/countries';
        let data = null;

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCountryData(response.data.countries);
            } else {
                setCountryData([]);
            }
        });
    };

    //API- Get state
    const _getState = () => {

        let is_post = false;
        let url = '/general/states';
        let data = null;

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setStateData(response.data);
            } else {
                setStateData([]);
            }

        });
    };

    //API- Post City
    const _getCity = () => {

        let is_post = true;
        let url = '/general/cities';
        let data = {
            "state_id": selectedState ? selectedState.id : null,
        };
        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCityData(response.data);
            } else {
                setCityData([]);
            }
        });
    };

    //API- Post save address
    const _updateAddress = () => {

        let is_india_tax_payer = props.data.overview.is_indian_tax_payer;
        let user_address = props.data.kyc.address;

        let is_post = true;
        let url = '/user/update-address';
        let data = {
            "customer_id": props.data.overview.id,
            "is_same_corresponding_address": is_india_tax_payer === true ? true : false,
        };

        if (is_permanent_address) {

            data = {
                ...data,
                "address": {
                    "permanent_address": {
                        "address_line1": address,
                        "address_line2": secondAddress,
                        "pincode": pinCode,
                        "country_id": 251,
                        "city": selectedCity.name,
                        "state_name": selectedState.name,
                        "state_id": selectedState.id,
                        "city_id": selectedCity.id
                    },
                    "corresponding_address": is_india_tax_payer == true ? ({}) : ({
                        "address_line1": user_address.correspondence_address.address_line1,
                        "address_line2": user_address.correspondence_address.address_line2,
                        "pincode": user_address.correspondence_address.pincode,
                        "country_id": user_address.correspondence_address.country_id,
                        "city": user_address.correspondence_address.city,
                        "state_name": user_address.correspondence_address.state,
                        "state_id": null,
                        "city_id": null
                    })
                }
            }

        } else {
            data = {
                ...data,
                "address": {
                    "permanent_address": {
                        "address_line1": user_address.permanent_address.address_line1,
                        "address_line2": user_address.permanent_address.address_line2,
                        "pincode": user_address.permanent_address.pincode,
                        "country_id": user_address.permanent_address.country_id,
                        "city": user_address.permanent_address.city,
                        "city_id": user_address.permanent_address.city_id,
                        "state_name": user_address.permanent_address.state,
                        "state_id": user_address.permanent_address.state_id,
                    },
                    "corresponding_address": is_india_tax_payer == true ? ({}) : ({
                        "address_line1": correspondAddressOne,
                        "address_line2": correspondSecondAddress,
                        "pincode": correspondPinCode,
                        "country_id": correspondNation.id,
                        "city": correspondCity || null,
                        "state_name": correspondState || null,
                        "state_id": correspondState.id || null,
                        "city_id": correspondCity.id || null
                    })
                }
            }
        }

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Address details saved successfully");
                let close_btn = document.getElementById("edit-user-address-modal-close");
                close_btn.click();
                props.handleEditSuccess();
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        });
    };


    return (
        <div className={`modal fade e-manage-modal`}
            id="edit-user-address-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="Confirm unlink member modal"
            aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered modal-dialog-scrollable`}>
                <div className="modal-content e-border-radius-8 p-0">
                    <div className="modal-header justify-content-between border-0 p-4 pb-3">
                        <h6 className='modal-title mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                            Edit address details
                        </h6>
                        <button type="button"
                            id="edit-user-address-modal-close"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onCloseModal}></button>
                    </div>
                    <div className="modal-body px-4 pb-0 pt-0  ">

                        {/* Permanent Address */}
                        {
                            is_permanent_address &&
                            <div className="">
                                <h3 className='e-text-chinese-black e-font-16 e-font-manrope e-font-weight-600 line-height-32px'>
                                    Permanent Address
                                </h3>
                                <div id='nationality'
                                    className='h-90 position-relative mb-2'>
                                    <CustomTextInput label="Nationality"
                                        require
                                        placeholder="India"
                                        type="text"
                                        value={"India"}
                                        disabled
                                        readOnly />
                                </div>

                                {/* address line 1 */}
                                <div id='address-1'
                                    className='h-90 mb-2'>
                                    <CustomTextInput label="Address line 1"
                                        require
                                        placeholder="202,Madhav Apts, jawar road......."
                                        type="text"
                                        value={address}
                                        errorMsg={addressError}
                                        handleChange={_handleAddressChange} />
                                </div>

                                {/* address line 2 */}
                                <div id='address-2'
                                    className='h-90 mb-2'>
                                    <CustomTextInput label="Address line 2"
                                        require
                                        placeholder="202,Madhav Apts, jawar road......."
                                        type="text"
                                        value={secondAddress}
                                        errorMsg={secondAddressError}
                                        handleChange={_handleSecondAddressChange} />
                                </div>

                                {/* state and city */}
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div id='state'
                                            className='h-90 position-relative mb-2'>
                                            <CustomTextInput label="State"
                                                require
                                                className="cursor-pointer"
                                                placeholder="Kerala"
                                                type="text"
                                                value={selectedState.name}
                                                errorMsg={stateError}
                                                readOnly
                                                onClick={() => _handleSuggestionModal(1)} />
                                            <img src={require("../../Assets/Images/Kyc/drop-down.svg").default}
                                                draggable={false}
                                                className='position-absolute end-0 top-46px me-2 bg-white'
                                                alt='icon' />
                                        </div>
                                    </div>

                                    {/*select city*/}
                                    <div className='col-lg-6'>
                                        <div id='city'
                                            className={`${selectedState.name ? "" : "e-disable"} h-90 position-relative mb-2`}>
                                            <CustomTextInput label="City"
                                                require
                                                className="cursor-pointer"
                                                placeholder="Kannur"
                                                type="text"
                                                value={selectedCity.name}
                                                errorMsg={cityError}
                                                readOnly
                                                onClick={() => _handleSuggestionModal(2)} />
                                            <img src={require("../../Assets/Images/Kyc/drop-down.svg").default}
                                                draggable={false}
                                                className='position-absolute end-0 top-46px me-2 bg-white'
                                                alt='icon' />
                                        </div>
                                    </div>
                                </div>

                                {/* pincode */}
                                <div id='pincode'
                                    className='h-90 mb-2'>
                                    <CustomTextInput label="Pincode"
                                        require
                                        placeholder="670676"
                                        type="number"
                                        maxLength={6}
                                        value={pinCode}
                                        errorMsg={pinCodeError}
                                        handleChange={_handlePinChange} />
                                </div>

                            </div>
                        }

                        {/* Overseas Address */}
                        {
                            props.data.overview.is_indian_tax_payer === false && is_permanent_address === false &&
                            <div className="">
                                <h3 className='e-text-chinese-black e-font-16 e-font-manrope e-font-weight-600 line-height-32px'>
                                    Overseas address*
                                </h3>
                                {/* Nationality select */}
                                <div id='corrs-nationality' className='h-90 position-relative mb-2'>
                                    <CustomTextInput label="Nationality"
                                        require
                                        placeholder="India"
                                        type="text"
                                        value={correspondNation.name}
                                        errorMsg={correspondNationError}
                                        readOnly
                                        className="cursor-pointer"
                                        onClick={() => _handleSuggestionModal(3)} />
                                    <img src={require("../../Assets/Images/Kyc/drop-down.svg").default}
                                        draggable={false}
                                        className='position-absolute end-0 top-46px me-3'
                                        alt='icon' />
                                </div>
                                {/* address line 1 */}
                                <div id='corrs-address-1' className='h-90 mb-2'>
                                    <CustomTextInput label="Address line 1"
                                        require
                                        placeholder="202,Madhav Apts, jawar road......."
                                        type="text"
                                        value={correspondAddressOne}
                                        errorMsg={correspondAddressError}
                                        handleChange={(e) => {
                                            setCorrespondAddressOne(e);
                                        }} />
                                </div>
                                {/* address line 2 */}
                                <div id='corrs-address-2' className='h-90 mb-2'>
                                    <CustomTextInput label="Address line 2"
                                        require
                                        placeholder="202,Madhav Apts, jawar road......."
                                        type="text"
                                        value={correspondSecondAddress}
                                        errorMsg={correspondSecondAddressError}
                                        handleChange={(e) => {
                                            setCorrespondSecondAddress(e);
                                        }} />
                                </div>
                                {/* state and city */}
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div id='corrs-state'
                                            className='h-90 position-relative mb-2'>
                                            <CustomTextInput label="State"
                                                placeholder="New york"
                                                require
                                                type="text"
                                                value={correspondState}
                                                errorMsg={correspondStateError}
                                                handleChange={(val) => setCorrespondState(val)} />
                                        </div>
                                    </div>

                                    {/*select city*/}
                                    <div className='col-lg-6'>
                                        <div id='corrs-city'
                                            className={`${correspondState ? "" : "e-disable"} h-90 position-relative mb-2`}>
                                            <CustomTextInput label="City"
                                                require
                                                placeholder="San Francisco"
                                                type="text"
                                                value={correspondCity}
                                                errorMsg={correspondCityError}
                                                handleChange={(val) => setCorrespondCity(val)} />
                                        </div>
                                    </div>
                                </div>
                                {/* pincode */}
                                <div id='corrs-pincode'
                                    className='h-90 mb-2'>
                                    <CustomTextInput label="Zip code"
                                        require
                                        placeholder="670676"
                                        type="number"
                                        value={correspondPinCode}
                                        errorMsg={correspondPinCodeError}
                                        handleChange={_handleCorrPinCodeChange} />
                                </div>
                            </div>
                        }

                    </div>

                    <div className="modal-footer px-4 py-3 border-0">
                        <div className='d-flex justify-content-end w-100 gap-4'>
                            <div className='col-4'>
                                <SecondaryBtn name="Cancel"
                                    className="w-100 h-100 py-2 px-0"
                                    cancel="modal"
                                    handleClick={props.onCloseModal} />
                            </div>
                            <div className='col-4 text-center'>
                                <PrimaryBtn name="Update"
                                    loading={loading}
                                    disabled={loading}
                                    className="d-flex justify-content-center w-100 h-40 px-0"
                                    handleClick={_handleUpdate} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* Select Modals */}
            {/* State modal*/}
            {
                showSuggestModal && modalType === 1 &&
                <SelectionSuggestModal title="Choose a state"
                    backdrop="false"
                    search={true}
                    searchPlaceholder="Search state"
                    data={stateData}
                    type={2}
                    id='select-state-modal'
                    selectedOption={selectedState}
                    onSelectOption={_handleSelectState}
                    onModalClose={() => setShowSuggestModal(false)} />
            }

            {/* city modal */}
            {
                showSuggestModal && modalType === 2 &&
                <SelectionSuggestModal title="Choose a city"
                    backdrop="false"
                    search={true}
                    searchPlaceholder="Search city"
                    data={cityData}
                    type={3}
                    id='select-city-modal'
                    selectedOption={selectedCity}
                    onSelectOption={_handleSelectCity}
                    onModalClose={() => setShowSuggestModal(false)} />
            }

            {/* correspond Address */}

            {
                showSuggestModal && modalType === 3 &&
                <SelectionSuggestModal title="Choose a nationality"
                    backdrop="false"
                    search={true}
                    searchPlaceholder="Search nationality"
                    data={countryData}
                    type={4}
                    id='select-correspond-country-modal'
                    selectedOption={correspondNation}
                    onSelectOption={_handleCorrespondNation}
                    onModalClose={() => setShowSuggestModal(false)} />
            }
        </div>

    )
};

export default memo(EditAddress);