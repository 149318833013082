/*
 *   File : orderDetail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order detail Modal
 *   Integrations :28-11-2023
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';

/*import componets*/
import { Badge } from '../Badge/index';
import EmptyNote from '../Note/empty';
import Tooltip from '../Badge/tooltip';

/*import styles*/
import styles from '../../Styles/Components/transaction-detail.module.scss';
import { Loader } from '../Loader';


/* import services */
import service from '../../Services/service';



export default function OrderDetail(props) {
    const navigate = useNavigate()
    const [orderData, setOrderData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [show, setShow] = useState(null);

    useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            _orderDetail()
        }
    }, [props.data.order_id]);

    const _orderDetail = () => {
        setLoader(true)
        let url = ""
        let data = {}
        if (props.id) {
            url = "/order/detail";
        } else {
            url = "/order/detail";
        }

        if (props.id) {
            data = {
                user_id: Number(props.id),
                order_id: props.data.order_id
            }
        } else {
            data = {
                order_id: props.data.order_id
            }
        }

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data);
            } else {
                setOrderData([]);
            }
            setLoader(false);
        })
    }
    return (
        <>

            <div className={`${styles.e_transaction_detail} modal fade e-order-detail-modal mb-5`}
                id="orderDetail"
                tabIndex="-1"
                aria-labelledby="success"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className={`${styles.e_modal_content} modal-content p-0`}>
                        <div className="modal-header p-4 pb-3 ">

                            <div className='position-relative'>
                                <h6 className={`${styles.e_modal_title}`}>
                                    Order details
                                </h6>
                            </div>
                            <button type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={props.onCloseModal}></button>
                        </div>
                        {
                            loader ?
                                <div className='e-max-height-260px'>
                                    <Loader />
                                </div>
                                :

                                orderData.length > 0 ?
                                    <div className='modal-body p-0 e-order-detail no-scrollbar'>
                                        <div className={`${styles.e_transaction_content}`}>
                                            <div className="row p-4 ps-0">
                                                <div className="col-5 ">
                                                    <div className='row'>
                                                        <div className='col-3 px-0 d-flex align-items-center justify-content-end'>
                                                            <div className={`${styles.e_icon} d-flex align-items-center justify-content-center`}>
                                                                {props?.data?.customer_name?.charAt(0).toUpperCase()}
                                                            </div>
                                                        </div>
                                                        <div className='col-9 ps-0'>
                                                            <div className={`${styles.e_span} ms-3 mt-2 d-flex flex-column gap-2`}>
                                                                {
                                                                    props.fName ?
                                                                        <h6 className='mb-0'>{props.fName}</h6>
                                                                        :
                                                                        <h6 className='mb-0' onClick={() => {
                                                                            navigate(`/user-detail?id=${props.data.customer_id}`)
                                                                            $(".btn-close").trigger("click")
                                                                        }}>
                                                                            <span className='e-link link-offset-1'>{props.data.customer_name}</span>

                                                                        </h6>
                                                                }
                                                                <p className={`${styles.e_time_date}  mb-0`}>{props.data.order_time}</p>
                                                                <p className={`${styles.e_time_date}  mb-0`}>{props.data.order_no}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3 text-start px-0 position-relative mt-2">

                                                    <h6 className='mb-0 pt-0 d-flex flex-column gap-2'>

                                                        Payment status
                                                        <div className='pt-1'>
                                                            {
                                                                props.payment_status !== null ?
                                                                    <Badge type={props.payment_status}
                                                                        text={props.payment_status.toLowerCase()}
                                                                        className={`${styles.e_badge}`}
                                                                        style={{ fontSize: "11px" }} />
                                                                    :
                                                                    null
                                                            }

                                                            <span onMouseEnter={() => setShow(true)}
                                                                onMouseLeave={() => setShow(false)}
                                                                className={
                                                                    props.payment_status === "Failed" || props.payment_status === "Processing" ? `e-icon`
                                                                        :
                                                                        null
                                                                }></span>
                                                            {
                                                                show ?
                                                                    props.id ?
                                                                        <Tooltip
                                                                            className={styles.e_tooltip}
                                                                            content={
                                                                                props.data.payment_failed_reason ?
                                                                                    props.data.payment_failed_reason :
                                                                                    props.payment_status === "Processing" ?
                                                                                        "Transaction pending"
                                                                                        :
                                                                                        "Transaction failed"
                                                                            } />
                                                                        :
                                                                        <Tooltip
                                                                            className={styles.e_tooltip}
                                                                            content={
                                                                                props.data.failure_reason ?
                                                                                    props.data.failure_reason :
                                                                                    props.payment_status === "Processing" ?
                                                                                        "Transaction pending"
                                                                                        :
                                                                                        "Transaction failed"
                                                                            } />

                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                    </h6>

                                                </div>
                                                <div className="col-2 text-start px-0 mt-2">
                                                    <h6 className='mb-0 pt-0 d-flex flex-column n gap-2'>
                                                        Order status
                                                        {
                                                            props.order_status !== null ?
                                                                <Badge type={props.order_status}
                                                                    text={props.order_status.toLowerCase()}
                                                                    className={`${styles.e_badge} mt-1`}
                                                                    style={{ fontSize: "11px" }} />
                                                                :
                                                                null
                                                        }
                                                    </h6>
                                                </div>
                                                <div className="col-2 text-start mt-2">
                                                    <h6 className='mb-1 pb-1 pt-0 '>Type</h6>
                                                    <p className='e-font-14 e-font-manrope'>{props.data.type}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container-fluid ps-3'>
                                            <div className='row mt-4 pe-3 ps-1'>
                                                <div className={`col-3 ${styles.e_details} mb-2`}>
                                                    <h6>Transaction ID:</h6>
                                                </div>
                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                    <h6>Amount & units</h6>
                                                </div>
                                                <div className={`col-3 ${styles.e_details} mb-2 px-0 `}>
                                                    <h6>Invested in</h6>
                                                </div>
                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                    <h6>Date</h6>
                                                </div>
                                                <div className={`col-2 ${styles.e_details} mb-2 `}>
                                                    <h6>Status</h6>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            orderData.map((item, key) => {
                                                return (
                                                    <>
                                                        <div className={`container-fluid px-3`}>
                                                            <div className={orderData.length === key + 1 ?
                                                                key % 2 !== 0 ? `border-bottom row pt-3 mt-1  pe-4 ps-1 align-items-center` : `border-bottom e-order-row row pt-3 mt-1  pe-4 ps-1 align-items-center`
                                                                :
                                                                key % 2 !== 0 ? `row pt-3 mt-1  pe-4 ps-1` : `e-order-row row pt-3 mt-1  pe-4 ps-1`}>
                                                                <div className={`col-3 ${styles.e_details} mb-2`}>
                                                                    <p className={`${styles.e_transaction_id}`}>{item.transaction_id} </p>
                                                                </div>
                                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                                    <p className={`${styles.e_transaction_id} mb-0 pb-0`}>{item.amount} & </p>
                                                                    <p className={`${styles.e_transaction_id} mt-0 pt-0`}>{item.units.toFixed(2)} </p>
                                                                </div>
                                                                <div className={`col-3 ${styles.e_details} mb-2 px-0`}>
                                                                    <p className={`${styles.e_transaction_id}`}>{item.mutual_fund} </p>
                                                                </div>
                                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                                    <p className={`${styles.e_transaction_id}`}>{item.transaction_date} </p>
                                                                </div>
                                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                                    <Badge type={item.status}
                                                                        text={item.status?.toLowerCase()}
                                                                        className={`${styles.e_badge}`}
                                                                        style={{ fontSize: "11px" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                        {
                                            props.data.amount > 0 ?
                                                <div className='row py-3'>
                                                    <div className='col-3 text-end'>
                                                        <h6>Total</h6>
                                                    </div>
                                                    <div className='col-2 '>
                                                        <p className={`${styles.e_transaction_id}`}>
                                                            ₹{props.data.amount.toFixed(2)}
                                                        </p>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                    </div>
                                    :
                                    <EmptyNote title={
                                        props.data.failure_reason ?
                                            props.data.failure_reason
                                            :
                                            "Transaction pending"
                                    }
                                        note="Please try again later" />

                        }
                    </div>
                </div>
            </div>

        </>
    );
};


OrderDetail.defaultProps = {
    onCloseModal: () => { }
};
