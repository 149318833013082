/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations : 17-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input, TextArea } from '../Input';
import { SecondaryBtn, PrimaryBtn } from '../Cta';
import { Toast } from '../Note';

/*import styles*/
import styles from '../../Styles/Components/risk.module.scss';

/*import services */
import service from '../../Services/service';

export default function EditTopic(props) {

	const [topic, setTopic] = useState("");
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [id, setId] = useState(null);
	const [desc, setDesc] = useState("");

	useEffect(() => {
		if (props.data) {
			setId(props.data.category_id)
			setTopic(props.data.category)
			setDesc(props.data?.description)
		}
	}, [props.data]);

	/*-------edit topic api for quiz ---*/
	const editTopic = () => {
		let url = '/quiz/edit-category'

		let data = {
			category_id: id,
			category: topic,
			description: desc,
		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setSuccess("Category edited successfully")
				setTimeout(() => {
					$('#close').trigger('click');
				}, 1000)
				props.edit(true)
			} else {
				setError(response.data.message)
			}
		})
		setTimeout(() => {
			setError(null)
		}, 3000)
		setTimeout(() => {
			setSuccess(null)
		}, 3000)
	}

	return (
		<div className={`${styles.e_risk} modal fade mx-auto`}
			id="editTopic"
			tabIndex="-1"
			data-bs-backdrop="false"
			aria-labelledby="success"
			aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className="modal-content">
					<div className="modal-header pt-1 px-2">
						<h6>Edit topic</h6>
						<span id="close" data-bs-dismiss="modal" aria-label="Close" className='d-none'></span>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body pt-0 px-2">
						<div className="row">
							<div className="col-12">
								<label>Topic name*</label>
								<Input placeholder="Eg : Indian equities" value={props.data ? topic : topic} valueChange={(value) => { props?.data ? setTopic(value) : setTopic(value) }} type="text" />
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<label>Description*</label>
								<TextArea placeholder="Topic description" value={props.data ? desc : desc} valueChange={(value) => { props?.data ? setDesc(value) : setDesc(value) }} type="text" />
							</div>
						</div>
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn name="Cancel" className={`${styles.e_cancel} e-cancel`} cancel="modal" />
									{
										topic ?
											<PrimaryBtn name="Change" className="e-modal-submit" handleClick={() => editTopic()} />
											:
											<PrimaryBtn name="Change" className='e-disabled' />
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast data={error} format={1} type="fail" id="1" />
					:
					null
			}
			{
				success !== null ?
					<Toast data={success} format={1} type="success" id="1" />
					:
					null
			}
		</div>
	);
}
