/*
 *   File : selectBox.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import package*/
import React from 'react';
import Select from 'react-select';

// Style Object
const style = {

	control: (base_style) => ({
		...base_style,
		borderRadius: "8px",
		border: "1px solid rgba(190, 190, 190, 1)",
		padding: "2px 3px",
		boxShadow: "none",
		cursor: "pointer",
		":hover": {
			border: "1px solid rgba(190, 190, 190, 1)",
		},
	}),

	input: (base_style) => ({
		...base_style,
		padding: "0px",
	}),

	singleValue: (base_style) => ({
		...base_style,
		fontSize: "0.875rem",
		fontFamily: "Manrope"
	}),

	indicatorsContainer: (base_style) => ({
		...base_style,
		"svg": {
			width: "1rem",
		}
	}),

	placeholder: (base_style) => ({
		...base_style,
		fontSize: "0.875rem",
		fontFamily: "Manrope"
	}),

	option: (base_style, state) => ({
		...base_style,
		cursor: "pointer",
		fontSize: "0.75rem",
		fontFamily: "Manrope",
		color: state.isSelected ? "#fff" : "#181614",
		backgroundColor: state.isFocused ? state.isSelected ? "#1c359f" : "#F0F3FF" : state.isSelected ? "#1c359f" : "",
		":active": {
			backgroundColor: state.isFocused ? "#F0F3FF" : state.isSelected ? "#1c359f" : "",
		}
	}),
}

const SelectBox = (props) => {

	/* --- passing value to selectbox ----*/
	const valueChange = (e) => {
		props.selectChange(e)
	}

	return (
		<Select
			classNamePrefix="react-select"
			isSearchable={props.isSearchable}
			isClearable={props.isClearable}
			//eslint-disable-next-line
			// className={`${styles.e_select_box} e-select-box` + " " + props.className}
			styles={style}
			onChange={(e) => { valueChange(e) }}
			isDisabled={props.isDisabled}
			options={props.options}
			value={props.value ? props.value : ""}
			isMulti={props.selectType}
			placeholder={props.placeholder}
			defaultValue={props.defaultValue}
			// isLoading={true}
			// menuIsOpen={true}
			{...props} />
	);
}

export default SelectBox;
