/*
 *   File : notification.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Notification file for Notification container;
 *   Integrations :28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023	
*/

/*import packages*/
import { useEffect, useState } from 'react';

/*import components*/
import { Input, TextArea } from '../../Components/Input/index';
import { PrimaryBtn } from '../../Components/Cta/index';
import { Toast } from '../../Components/Note';

/* import services */
import service from '../../Services/service';

const Notification = () => {

	const [title, setTitle] = useState("");
	const [titleErr, setTitleErr] = useState("");
	const [content, setContent] = useState("");
	const [contentErr, setContentErr] = useState("");
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		// getNotifications();
	}, []);

	// hiding error toast after 3sec
	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	// hiding success toast after 3sec
	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success]);

	const _handleTitleChange = (title) => {
		setTitle(title);
		setTitleErr("");
	};

	const _handleContentChange = (content) => {
		setContent(content);
		setContentErr("");
	};

	const _handleSendNotification = () => {

		let is_valid = true;

		if (title.length === 0) {
			setTitleErr("Please provide a title");
			is_valid = false;
		}

		if (content.length === 0) {
			setContentErr("Please provide a content");
			is_valid = false;
		};

		if (is_valid) {
			_sendGlobalNotification();
		};
	};

	/*----- send global notification api ---*/
	const _sendGlobalNotification = () => {
		setLoader(true);
		const url = '/notification/send-global'
		const data = {
			title: title,
			message: content
		}

		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setSuccess("Notification sent successfully")
				setContent("");
				setTitle("");
				setLoader(false);
			} else {
				setError(response.data.message);
				setLoader(false);
			}
		});
	}

	/*----- get notifications list api ---*/
	// const getNotifications = () => {
	// 	setLoader(true);
	// 	const url =  '/notifications/get-notifications'

	// 	APIGet(url).then((response) => {
	// 		if (response.status_code === 200) {
	// 			setLoaderData(response.data.notifications);
	// 			setLoader(false);
	// 		} else {
	// 			setError(response.data.message);
	// 			setLoader(false);
	// 			setLoaderData([]);
	// 		}
	// 	});
	// }


	return (
		<div className="row e-notification position-relative">
			<div className="col-lg-6">
				<h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
					Send app notifications
				</h3>

				{/*-----------notification card to send global notifications---------*/}
				<div className="card border-0 e-dashboard-card p-4 h-auto">
					<div className="col-lg-12  mb-3">
						<h5 className='e-notification-form-title'>Push notifications</h5>
					</div>
					<div className='row'>
						<div className="col-lg-12 mb-1">
							<label>Title</label>
							<Input
								placeholder="Notification title"
								value={title}
								error={titleErr}
								className="e-notification-form-element"
								valueChange={_handleTitleChange}
							/>
						</div>
						<div className="col-lg-12 mb-2">
							<label>Content</label>
							<TextArea
								placeholder="Content of the push notification"
								value={content}
								error={contentErr}
								className="e-notification-form-element"
								rows="9"
								valueChange={_handleContentChange}
							/>
						</div>

						<div className="col-lg-12 text-left mt-1">
							<PrimaryBtn
								name="Send now"
								className={
									loader ?
										"e-notification-form-btn e-btn-loader"
										:
										"e-notification-form-btn"}
								handleClick={_handleSendNotification}
							/>
						</div>
					</div>
				</div>
			</div>
			{/*-----------notification card ends---------*/}

			{/*---------------- list of push notifications ------------*/}
			{/* <div className="col-lg-12 mt-4 position-relative">
				{
					loader === true ?
						<Loader />
						:
						<div className="e-dashboard-card card border-0 p-4">
							{
								notificationData.length > 0 ?
									<>
										<h6 className="e-card-title mb-3">Push notification history</h6>
										{
											notificationData?.map((item, key) => (
												<div className="row" key={key}>
													<div className="col-lg-12">
														<div className="card border-0 e-notification-history p-3 mb-3">
															<span className="e-notification-history-time d-block">
																{item.date}
															</span>
															<h6 className="e-title mt-1 mb-3">{item.title}</h6>
															<span className="e-notification-history-content d-block">
																{item.description}
															</span>
														</div>
													</div>
												</div>
											))
										}
									</>
									:
									<EmptyNote note="No notification found" />
							}
						</div>
				}
			</div> */}


			{/*--------- error toast --------*/}
			{
				error !== null ?
					<Toast
						data={error}
						type="error"
						id="1"
					/>
					:
					null
			}


			{/*--------- success toast --------*/}
			{
				success !== null ?
					<Toast
						data={success}
						type="success"
						id="2"
					/>
					:
					null
			}
		</div>
	);
}

export default Notification;