/*
 *   File : orders.js
 *   Author URI : https://evoqins.com
 *   Description : Selected family members orders list
 *   Integrations : react-toastify
 *   Version : v1.0
 *   Created : 10-05-2024
 */

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";


// Component
import { DownloadBtn, FilterBtn } from "../../../Components/Cta";
import { SearchInput } from "../../../Components/Input";
import SelectBox from "../../../Components/Dropdown/select-box";
import { Loader } from "../../../Components/Loader";
import { EmptyNote } from "../../../Components/Note";
import { DataTable } from "../../../Components/DataFormating";
import Tooltip from "../../../Components/Badge/tooltip";
import { OrderDetail } from "../../../Components/Modal";
import service from "../../../Services/service";


/*import styles*/
import styles from '../../../Styles/Components/search-input.module.scss';

function FamilyOrders(props) {

    const navigate = useNavigate();
    const initialRender = useRef(true);

    const [orderData, setOrderData] = useState([]);
    const [orderFilterData, setOrderFilterData] = useState([]);
    const [search, setSearch] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [orderIDs, setOrderIDs] = useState([]);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [showPaymentStatus, setShowPaymentStatus] = useState(false);
    const [showOrderStatus, setShowOrderStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [familyID, setFamilyID] = useState(0);
    const [selectedOrderDetail, setSelectedOrderDetail] = useState("");
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);

    // Filters
    const [selectedInvestType, setSelectedInvestType] = useState("");
    const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
    const [selectedFamilyMember, setSelectedFamilyMember] = useState("");

    // Order list data table column data
    const ORDER_LIST = [
        {
            name: 'Order ID',
            selector: (row) => row.order_no ? row.order_no : '--',
            sortable: true,
            left: true,
            minWidth: "110px",
            defaultSortAsc: false,
        },
        {
            name: 'Date',
            selector: row => new Date(row["order_time"]).valueOf(),
            sortable: true,
            left: true,
            minWidth: "150px",
            defaultSortAsc: false,
            cell: row => (
                <span data-tag="allowRowEvents">{row.order_time}</span>
            ),
        },

        {
            name: 'Customer name',
            selector: row => row["customer_name"].toLowerCase(),
            sortable: true,
            minWidth: "170px",
            cell: row => (
                <span className="e-link link-offset-1 w-100"
                    data-tag="allowRowEvents"
                    onClick={() => {
                        navigate(`/user-detail?id=${row.customer_id}&tab=${2}`)
                    }}>
                    {row["customer_name"]}
                </span>
            ),
        },

        {
            name: 'Investment name',
            selector: "Type",
            left: true,
            minWidth: "250px",
            defaultSortAsc: false,
            cell: row => (
                <div className="d-flex gap-2 py-2" data-tag="allowRowEvents">
                    <img src={row.icon}
                        data-tag="allowRowEvents"
                        alt="fund_image"
                        draggable="false"
                        className='me-2 rounded-4 bg-white'
                        width="24"
                        height="24" />
                    {row.name}
                </div>
            )
        },

        {
            name: 'Investment type',
            selector: "Type",
            left: true,
            minWidth: "170px",
            defaultSortAsc: false,
            cell: row => (
                <div className="py-1">
                    <span data-tag="allowRowEvents">{row.type}</span>
                </div>
            )
        },


        {
            name: 'Amount',
            selector: row => row.amount ? Number(row.amount.replaceAll(/[₹,]/g, "")) : 0,
            sortable: true,
            left: true,
            minWidth: '120px',
            cell: row => <div data-tag="allowRowEvents">
                {
                    row.amount ? row.amount : 0
                }
            </div>

        },
        {
            name: 'Payment method',
            selector: row => row.payment_method ? row.payment_method : "--",
            sortable: true,
            left: true,
            minWidth: '170px',
        },
        {
            name: 'Order status',
            selector: 'Order status',
            left: true,
            minWidth: '140px',
            cell: (row, key) => <>
                {
                    row.order_status ?
                        <>
                            <div className="d-inline-flex">
                                <div className="col-lg-10">
                                    <div data-tag="allowRowEvents"
                                        className={`${row.order_status === "Success" ?
                                            "e-status-badge-success" : row.order_status === "Failed" ?
                                                "e-status-badge-fail" : "e-status-badge-pending"} e-status-badge `}>
                                        {
                                            row.order_status
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                row.failure_reason && row.payment_status !== "Failed" &&
                                <div className="col-lg-2">
                                    <span data-tag="allowRowEvents"
                                        className={
                                            row.payment_status === "Failed" ? `e-icon` :
                                                row.payment_status === "Pending" ? `e-icon` :
                                                    null
                                        }
                                        onMouseEnter={() => setShowOrderStatus(key + 1)}
                                        onMouseLeave={() => setShowOrderStatus(null)}>
                                    </span>
                                </div>
                            }
                            {
                                showOrderStatus === (key + 1) ?

                                    <Tooltip className={"min-width-170px left-25-per"}
                                        content={row.failure_reason} />
                                    :
                                    null
                            }
                        </>
                        :
                        "--"
                }
            </>
        },
        {
            name: 'Payment status',
            selector: 'Payment status',
            left: true,
            minWidth: '240px',
            cell: (row, key) => <>
                {
                    row.payment_status ?
                        <>
                            <div className="d-inline-flex">
                                <div className="col-lg-10">
                                    <div data-tag="allowRowEvents"
                                        className={`${row.payment_status === "Success" ?
                                            "e-status-badge-success" : row.payment_status === "Failed" ?
                                                "e-status-badge-fail" : "e-status-badge-pending"} e-status-badge `}>
                                        {
                                            row.payment_status
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                row.failure_reason && row.payment_status === "Failed" &&
                                <div className="col-lg-2">
                                    <span data-tag="allowRowEvents"
                                        className={`e-icon`}
                                        onMouseEnter={() => setShowPaymentStatus(key + 1)}
                                        onMouseLeave={() => setShowPaymentStatus(null)}>
                                    </span>
                                </div>
                            }
                            {
                                showPaymentStatus === (key + 1) ?

                                    <Tooltip className={"min-width-170px left-25-per"}
                                        content={row.failure_reason} />
                                    :
                                    null
                            }
                        </>
                        :
                        "--"
                }
            </>
        },
        // {
        //     name: 'Ordered by',
        //     selector: (row) => row.ordered_by ? row.ordered_by : '--',
        //     left: true,
        //     minWidth: "160px",
        //     defaultSortAsc: false,
        // },
    ];

    useEffect(() => {
        setFamilyID(props.familyID);
    }, [props.familyID]);

    // handle get oderlist
    useEffect(() => {
        if (familyID) {
            setLoading(true);
            _getOrderList();
            _getOrderFilterData();
        }
    }, [familyID]);

    // handle filter order data
    useEffect(() => {

        if (initialRender.current === false) {
            // API - update order list based on applied filters
            setLoading(true);
            _getOrderList();
        }

    }, [selectedInvestType, selectedOrderStatus, selectedPaymentStatus, selectedFamilyMember]);

    useEffect(() => {
        if (initialRender.current === false && search.length === 0) {
            setLoading(true);
            _getOrderList();
        }
    }, [search]);

    useEffect(() => {
        if (showOrderDetailModal) {
            var order_detail_modal = new Modal(document.getElementById("orderDetail"), {});
            order_detail_modal.show();
        }
    }, [showOrderDetailModal]);

    //  handle open order detail modal
    const _handleOpenOrderDetailModal = (row) => {
        setShowOrderDetailModal(true);
        setSelectedOrderDetail(row);

    };


    // const _handleDownloadList = () => {
    //     _downloadOrderList();
    // };

    const _handleSelectRow = (row) => {

        let order_ids = [];
        for (let i = 0; i < row.selectedRows.length; i++) {
            order_ids.push(row.selectedRows[i]['order_id']);
        };
        setOrderIDs(order_ids);
    };

    const _handleSearch = () => {
        // API call
        setLoading(true);
        _getOrderList();
    };


    // ========================//
    //======= API CALLS =======//

    // API - Download order data
    const _downloadOrderList = () => {
        setDownloadLoader(true);

        setTimeout(() => {
            setDownloadLoader(false);
            setOrderIDs([]);
            toast.dismiss();
            toast.success("Order list downloaded successfully");
        }, 1000);
    };

    // API - get order filter data
    const _getOrderFilterData = () => {

        let is_post = true;
        let url = '/family/filter-data';
        let data = {
            "family_id": familyID,
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                const order_filter_data = response.data

                for (let item in order_filter_data) {
                    order_filter_data[item].forEach(item => {
                        item.label = item.name;
                        item.value = item.name;
                    });
                };

                setOrderFilterData(order_filter_data);
                setSelectedInvestType(order_filter_data.investment_types[0]);
                setSelectedOrderStatus(order_filter_data.order_status[0]);
                setSelectedPaymentStatus(order_filter_data.payment_status[0]);
                setSelectedFamilyMember(order_filter_data.family[0]);

            } else {
                setOrderFilterData([]);
            }
        });

    };

    // API - get order data
    const _getOrderList = () => {

        let is_post = true;
        let url = '/family/list-order';
        let data = {
            "family_id": familyID,
            "investment_type": selectedInvestType.id ? selectedInvestType.id : null,
            "query": search ? search : null,
            "customer_id": selectedFamilyMember.id ? selectedFamilyMember.id : null,
            "order_status": selectedOrderStatus.status ? selectedOrderStatus.status : null,
            "payment_status": selectedPaymentStatus.status ? selectedPaymentStatus.status : null,
            // "type": null,
            // "payment_method": 2,
            // "from_date": "10-01-2023",
            // "to_date": "10-12-2023",
            "page_num": null,
            "page_size": null,
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data.orders);
            } else {
                setOrderData([]);
            }

            setLoading(false);
            initialRender.current = false;
        });

    };

    return (
        <div className="col">
            <div className="card e-dashboard-card border-0 pb-5">
                <div className="row pt-2">
                    <div className="col-lg-4 col-3">
                        <h6 className="e-section-title p-2">All Orders</h6>
                    </div>
                    {/* <div className="col-lg-3 col-4 align-items-center text-end" >
                        <DownloadBtn
                            loading={downloadLoader}
                            check={orderIDs}
                            onClick={_handleDownloadList} />
                    </div> */}
                    <div className="ms-auto col-4 position-relative">
                        <div className="d-flex align-items-center e-bg-search-bg e-border-radius-4">
                            <SearchInput
                                placeholder="Search with Order ID"
                                value={search}
                                valueChange={(value) => setSearch(value)}
                                onSearch={_handleSearch}
                            />
                            {
                                search && search.length &&
                                <>
                                    <button className={`${styles.e_search_close} pe-3`}
                                        onClick={() => setSearch("")}>
                                    </button>
                                    <span className={`${styles.e_search_submit}`}
                                        onClick={_handleSearch}>
                                    </span>

                                </>
                            }
                        </div>

                    </div>
                    <div className="col-lg-1 col-1">
                        <FilterBtn className={showFilter ? "e-filter-btn e-active" : "e-filter-btn"}
                            handleClick={() => setShowFilter((showFilter) => !showFilter)} />
                    </div>

                    {/*-------------- Filters  ---------------*/}
                    {
                        showFilter &&
                        <div className="e-filter-wraper e-sort mt-3">
                            <div className="row">
                                <div className="col-lg-2 col-3 px-2">
                                    <label className="mb-2">Type</label>
                                    <SelectBox
                                        value={selectedInvestType}
                                        options={orderFilterData.investment_types}
                                        selectChange={(type) => setSelectedInvestType(type)}
                                        isSearchable={false}
                                    />
                                </div>
                                <div className="col-lg-2 col-3 px-2">
                                    <label className="mb-2">Order status</label>
                                    <SelectBox
                                        value={selectedOrderStatus}
                                        options={orderFilterData.order_status}
                                        selectChange={(selected) => setSelectedOrderStatus(selected)}
                                        isSearchable={false}
                                    />
                                </div>
                                <div className="col-lg-2 col-3  px-2">
                                    <label className="mb-2">Payment status</label>
                                    <SelectBox
                                        value={selectedPaymentStatus}
                                        options={orderFilterData.payment_status}
                                        selectChange={(selected) => setSelectedPaymentStatus(selected)}
                                        isSearchable={false}
                                    />
                                </div>
                                <div className="col-lg-2 col-3  p-0">
                                    <label className="mb-2">Family member</label>
                                    <SelectBox
                                        value={selectedFamilyMember}
                                        options={orderFilterData.family}
                                        selectChange={(selected) => setSelectedFamilyMember(selected)}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {/*--------------- Order table -----------*/}
                    {
                        loading ?
                            <Loader />
                            : (orderData.length === 0) ?
                                <EmptyNote note="There are no orders to show" />
                                :
                                <div className="col-lg-12 px-0 mt-2">
                                    <DataTable
                                        pagination={true}
                                        columns={ORDER_LIST}
                                        data={orderData}
                                        perPage={30}
                                        selectableRows={false}
                                        clearSelectedRows={downloadLoader ? false : true}
                                        selectRows={(row) => { _handleSelectRow(row) }}
                                        rowClick={(row) => _handleOpenOrderDetailModal(row)}
                                    />
                                </div>
                    }

                </div>
            </div>

            {/*--------- Order detail modal ----------------*/}
            {
                showOrderDetailModal &&
                <OrderDetail
                    data={selectedOrderDetail}
                    order_status={selectedOrderDetail.order_status}
                    payment_status={selectedOrderDetail.payment_status}
                    onCloseModal={() => setShowOrderDetailModal(false)}
                />
            }
        </div >
    );
};

export default FamilyOrders;