/*
 *   File : funds.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Funds Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
*/

/*import packages*/
import React, { useState, useEffect } from 'react';

/*import styles*/
import styles from '../../Styles/Components/aggressive-fund.module.scss';

/*import assets*/
import Add from '../../Assets/Images/Card/add.svg';
import Remove from '../../Assets/Images/Card/remove.svg';
import Select from '../../Assets/Images/Card/select.svg';
import Selected from '../../Assets/Images/Card/selected.svg';


const AggressiveFund = (props) => {
	const [selectedFunds, setselectedFunds] = useState(props.selectedFunds)

	/*--- send id to add fund modal and add fund class modal---*/
	// const sendData = () => {
	// 	props.sendVal(props.data.scheme_code)
	// }

	useEffect(() => {
		setselectedFunds(props.selectedFunds);
	}, [props.selectedFunds]);

	return (
		<>
			<div className={`${styles.e_fund_card} card border-0`}>
				{
					props.maturity ?
						<div className="row">
							<div className="col-lg-12 mt-3">
								<div className={`${styles.e_inner_card} p-3`}
									style={
										props.selectedFund ?
											props.data.scheme_code === props.selectedFund ?
												{ opacity: 1 }
												:
												{ opacity: 0.4, pointerEvents: "none" }
											:
											{}
									}>
									<div className="row ps-2">
										<div className="col-lg-10 ps-0">
											<h6 className={`${styles.e_inner_card_title}`}>{props.data.scheme_name}</h6>
										</div>
										<div className="col-lg-2 px-0 pt-0">
											<>
												<div onClick={() => {
													props.selectedFundData(props.data.scheme_code);
													props.selectFundObj(props.data);
												}} >
													{
														props?.data?.scheme_code !== props.selectedFund ?
															<div
																className={`${styles.e_add} top-0`}
																onClick={() => {
																	props.selectedFundData(null);
																	
																	// props.selectFundObj({})

																}}
															>
																<img src={Add}
																	alt=""
																/>
															</div>
															:
															null
													}
												</div>
												<div onClick={() => {
													props.selectedFundData(null);
													props.selectFundObj({});
												}} >
													{
														props.data.scheme_code === props.selectedFund ?
															<div
																className={`${styles.e_remove} top-0`}
																onClick={() => {
																	props.selectedFundData(props.data.scheme_code);
																	
																	// props.selectFundObj({})

																}}>
																<img src={Remove}
																	alt=""
																/>
															</div>
															:

															null
													}
												</div>
											</>
										</div>
									</div>
								</div>
							</div>
						</div>
						:
						<div className="row">
							<div className="col-lg-12 mt-3">
								<div className={
									selectedFunds.findIndex((item) => item.scheme_code === props.data.scheme_code) !== -1 ?
										`${styles.e_selected_scheme}`
										:
										`${styles.e_scheme_name}`}>
									<div className="row">

										<div className="col-lg-10 ">
											<h6 className='mb-0'>{props.data.scheme_name}{` (${props.data.isin})`}
											</h6>

										</div>
										<div className="col-lg-2 pt-0 d-flex align-items-center justify-content-end">
											<>
												{/* <div onClick={() => { props.selectedFundData(props.data.scheme_code) }} >
													{
														props?.data?.scheme_code !== props.selectedFund ?
															<div
																onClick={() => props.selectedFundData(null)}
															>
																<img src={Select} alt="" />
															</div>

															:

															null
													}
												</div> */}
												<div >
													{
														// props?.data?.scheme_code === props.selectedFund ?
														<div
															onClick={() => {
																const tempSelectedFunds = [...selectedFunds];
																const fundIndex = tempSelectedFunds.findIndex((item) => item.scheme_code === props.data.scheme_code);

																if (fundIndex === -1) {
																	tempSelectedFunds.push({ ...props.data, allocation: "", fund_alias: null })
																} else {
																	tempSelectedFunds.splice(fundIndex, 1);
																	// tempSelectedFunds.push(props.data.scheme_code)
																}
																props.getSelectedFunds(tempSelectedFunds);

																setselectedFunds(tempSelectedFunds);
															}}>
															<img
																style={{ cursor: "pointer" }}
																src={
																	selectedFunds.findIndex((item) => item.scheme_code === props.data.scheme_code) === -1 ?
																		Select
																		:
																		Selected
																} alt="" />
														</div>
														// :

														// null
													}
												</div>
											</>
										</div>
									</div>


								</div>
							</div>
						</div>
				}
			</div>
		</>
	);
}

export default AggressiveFund;
