/*
 *   File : file-picker.js
 *   Author : https://evoqins.com
 *   Description : File picker component
 *   Integrations : NA
 *    Version : 1.0.0
*/

import { useEffect, useState } from "react";

import Select from 'react-select';


// Assets
import Search from "../../Assets/Images/Cta/search.svg";
import Close from "../../Assets/Images/Cta/close-black.png";


// Style Object
const style = {

    control: (base_style) => ({
        ...base_style,
        borderRadius: "8px",
        border: "1px solid rgba(190, 190, 190, 1)",
        // padding: "2px 3px",
        padding: "2px",
        boxShadow: "none",
        cursor: "pointer",
        minHeight: "auto",
        ":hover": {
            border: "1px solid rgba(190, 190, 190, 1)",
        },
    }),

    input: (base_style) => ({
        ...base_style,
        padding: "8px 10px",
        margin: "0px",
    }),

    valueContainer: (base_style) => ({
        ...base_style,
        padding: "0px",
    }),

    singleValue: (base_style) => ({
        ...base_style,
        fontSize: "0.875rem",
        padding: "8px 10px",
        fontFamily: "Manrope"
    }),

    indicatorContainer: (base_style) => ({
        ...base_style,
        padding: "0px",

    }),

    placeholder: (base_style) => ({
        ...base_style,
        fontSize: "0.875rem",
        fontFamily: "Manrope",
        padding: "8px 10px",
    }),

    option: (base_style, state) => ({
        ...base_style,
        cursor: "pointer",
        fontSize: "0.75rem",
        fontFamily: "Manrope",
        color: state.isSelected ? "#fff" : "#181614",
        backgroundColor: state.isFocused ? state.isSelected ? "#1c359f" : "#F0F3FF" : state.isSelected ? "#1c359f" : "",
        ":active": {
            backgroundColor: state.isFocused ? "#F0F3FF" : state.isSelected ? "#1c359f" : "",
        }
    }),

    menuList: (base_style) => ({
        ...base_style,
        "&::-webkit-scrollbar": {
            display: "none"
        },
    }),

    indicatorsContainer: (base_style) => ({
        ...base_style,
        "svg": {
            width: "1rem",
            height: "1rem",
        },
    }),
};


function CustomSearchSelect(props) {

    const [selectedUser, setSelectedUser] = useState(props.selected ? props.selected : []);
    const [showMenu, setShowMenu] = useState(false);
    const [search, setSearch] = useState("");
    const [options, setOptions] = useState(props.options);

    useEffect(() => {
        setOptions(props.options);
        if (props.type !== 2) {
            setSelectedUser(null);
        }
    }, [props.options]);

    const _handleUserSelect = (selected) => {
        setSearch("");
        setSelectedUser(selected);
        props.handleSelect(selected);
    };

    const _handleShowMenu = (search) => {
        if (search.length > 2) {
            setShowMenu(true);
        } else {
            setShowMenu(false);
            setOptions([]);
        };

        setSearch(search);
        props.handleSearch(search);

    };

    const _handleClearSearch = () => {
        setSelectedUser(null);
        setOptions([]);
        props.handleClear();
    };


    return (
        <Select
            styles={style}
            components={{
                DropdownIndicator: ({ children, ...props }) => (
                    search.length == 0 && (selectedUser == null || selectedUser.length == 0) ? (
                        <div className="e-padding-lr-12px d-flex align-items-center h-100">
                            <img src={Search}
                                width={16}
                                height={16}

                                alt="search icon"
                                draggable="false" />
                        </div>
                    ) : (
                        <div className="e-padding-lr-12px d-flex align-items-center h-100"
                            onClick={_handleClearSearch} >
                            <img src={Close}
                                width={16}
                                height={10}
                                onClick={_handleClearSearch}
                                className="object-fit-contain"
                                alt="search icon"
                                draggable="false"
                            />
                        </div>
                    )
                )
            }}
            value={selectedUser !== null ? selectedUser : null}
            options={options}
            menuIsOpen={showMenu}
            placeholder={props.placeholder || "Search user"}
            isSearchable={true}
            noOptionsMessage={() => props.noDataMessage || "No users found"}
            onInputChange={_handleShowMenu}
            onChange={_handleUserSelect}
            isLoading={props.loading}
        />
    );
};

export default CustomSearchSelect;