/*
 *   File : secondary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Secondary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*Style Import*/
import styles from '../../Styles/Components/secondary.module.scss';

const SecondaryBtn = (props) => {
	return (
		<button id={props.id}
			className={`${styles.e_s_btn} ${props.className}`}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
			type={props.type}
		><span>
				{props.img}
				{props.name}
			</span>
		</button>

	);
}


export default SecondaryBtn;
