/*
 *   File : loader.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-11-2023
 */

/*Import Packages*/
import React from 'react';

/*import style*/
import styles from '../../Styles/Components/loader.module.scss';
import LoaderImg from '../../Assets/Images/Loader/btn-loader-blue.gif';


export default function Loader(props) {
	return (
		<>
			<div className={`${styles.e_loader}`}>
				<img src={LoaderImg} alt="loader" draggable="false"/>
			</div>
		</>
	)
}
