/*
 *   File : footer.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Footer
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-11-2023
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom'

// Components
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";

/*import style*/
import styles from '../../Styles/Components/sidebar.module.scss';

// Colors
import Colors from "../../Styles/config.scss";

/*import config*/
import devconfig from '../../Config/config';

/*import assets*/
import Logo from '../../Assets/Images/Navigation/inner-logo.svg';
import InnerLogo from '../../Assets/Images/Sidebar/collapsed-logo.png';

export default function Sidebar(props) {
	//eslint-disable-next-line
	const [location, setLocation] = useState();
	const current = useLocation();

	useEffect(() => {

		if (current.pathname === '/user-detail') {
			setLocation({ pathname: '/users' });
		} else {
			let pathname = "/" + current.pathname.split("/")[1];
			setLocation({ pathname });
		};

	}, [current]);



	return (
		<>
			<div className={props.is_menu_collapse || props.width <= 1024 ? `${styles.e_sidebar_collapsed} ${styles.e_sidebar}` : `${styles.e_sidebar}`}>
				{
					!props.is_menu_collapse && props.width > 1024 ?
						<div className={`${styles.e_sidebar_list_wrap} position-relative mb-5`}>
							<div className={`${styles.e_logo_wrapper} mb-3`}>
								<Link to="/">
									<img src={Logo} alt="logo" />
								</Link>
							</div>
							<div className={`${styles.e_sidebar_list} d-flex flex-column gap-3 mx-4`}>
								{
									devconfig.sidebar.map((item, key) => {
										return (
											<Link to={item.link} key={item.name}>
												<div
													className={`${location?.pathname === item.link && "e-bg-primary"} d-flex align-items-center gap-2 e-border-radius-4 px-3 e-padding-tb-10px`}>
													<IcoMoon iconSet={iconSet}
														icon={item.icon_name}
														size={"1.125rem"}
														color={location?.pathname === item.link ? Colors.white : Colors.roman_silver} />
													<span className={`${location?.pathname === item.link ? "e-text-white" : "e-text-grey"} e-font-manrope e-font-weight-400  e-font-14 line-height-20px letter-spacing-neg-2`}>
														{item.name}
													</span>
												</div>
											</Link>
										)
									})
								}
							</div>
						</div>
						:
						<div >
							<div className={` text-center mb-4 mt-4`}>
								<Link to="/"><img src={InnerLogo} alt="" /></Link>
							</div>
							<div>
								{
									devconfig.sidebar.map((item, key) => {
										return (
											<Link to={item.link} key={item.name}>
												<div className="text-center">
													<IcoMoon className='text-center my-3'
														iconSet={iconSet}
														icon={item.icon_name}
														size={"1.25rem"}
														color={location?.pathname === item.link ? Colors.primary_color : Colors.roman_silver} />
												</div>
											</Link>
										)
									})
								}
							</div>
						</div>
				}
			</div>
		</>
	)
}

