/*
 *   File : changeAllocation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Change Allocation Success Modal
 *   Integrations :28-11-2023
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input } from '../Input/index';
import { SecondaryBtn, PrimaryBtn } from '../Cta/index';
import { Toast } from '../Note';

/*import styles*/
import styles from '../../Styles/Components/fund-class.module.scss';

/*import services */
import service from '../../Services/service';

export default function EditAllocation(props) {

    const [allocation, setAllocation] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState("");
    const [selectedFunds, setSelectedFunds] = useState([]);
    const [disableBtn, setDisableBtn] = useState(true);


    useEffect(() => {
        var myModalEl = document.getElementById(props.debt ? "editDebtAllocations" : "editAllocations")
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            props.close()
            $(".modal-backdrop").remove()
            $("body").removeAttr("style")
        })
        //eslint-disable-next-line
    }, []);

    useEffect(() => {

        if (props.editedData && props && Object.values(props.editedData).length > 0) {
            setSelectedFunds(props.editedData)
            setName(props.editedData[0].asset_class);
            let allocation = props.editedData.reduce((a, b) => a + b.allocation, 0);
            setAllocation(allocation);
        }
    }, [props.editedData]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 4000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 4000);
        }
    }, [success]);

    /*-----update allocation---- */
    const _updateAllocation = () => {

        setLoader(true);
        const requestFunds = []
        selectedFunds.map((item) => {
            return (
                requestFunds.push({
                    "category_type": props.name,
                    "allocation_id": item.id,
                    "allocation": parseFloat(item.allocation),
                    "fund_alias": item.fund_alias
                })
            )
        })
        const url = '/fund/edit-allocation'
        const data = {
            funds: requestFunds
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Allocation updated successfully");


                setTimeout(() => {
                    // $(props.debt ? "#editDebtAllocations" : "#editAllocations").trigger('click');
                    $(".btn-close").trigger("click");
                    props.updatedAllocation(true);
                }, 1000);


            } else {
                setError(response.data.message);
            }

            setLoader(false);
        })
    }

    /*-----update allocation---- */
    const _updateBasket = () => {
        const requestFunds = []
        selectedFunds.map((item) => {
            return (
                requestFunds.push({
                    "scheme_code": item.scheme_code,
                    "allocation": parseFloat(item.allocation),
                    "fund_alias": item.fund_alias
                })
            )
        })
        setLoader(true);
        const url = '/baskets/update'
        const data = {
            basket_id: props.index,
            funds: requestFunds
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Allocation updated successfully");
                setLoader(false);

                setTimeout(() => {
                    // $(props.debt ? "#editDebtAllocations" : "#editAllocations").trigger('click');
                    $(".btn-close").trigger("click")
                    props.updatedAllocation(true);
                }, 1000)
            } else {
                setError(response.data.message);
                setLoader(false);

            }
        })
    }

    return (
        <>
            <div className={`${styles.e_add_fund} modal fade`}
                id={props.debt ? "editDebtAllocations" : "editAllocations"}
                tabIndex="-1"
                aria-labelledby="success"
                aria-hidden="true">
                <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered modal-lg`}>
                    <div className="modal-content p-0">
                        <div className="modal-header p-4">
                            <h6 className='mb-0'>Edit allocations</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-btn" ></button>
                        </div>
                        <div className="modal-body p-4 pt-0">
                            <div className='row'>
                                <div className='col-3'>
                                    <label>Asset class</label>
                                    <h6>{name}</h6>
                                </div>
                                <div className='col-4'>
                                    <label>Total asset class allocation</label>
                                    <h6>{allocation}%</h6>
                                </div>
                            </div>
                            <div>
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <label>
                                            Selected funds
                                        </label>
                                    </div>
                                    <div className='col-3'>
                                        <label>
                                            Fund alias
                                        </label>
                                    </div>
                                    <div className='col-3'>
                                        <label>
                                            Allocation
                                        </label>
                                    </div>
                                </div>
                                {
                                    selectedFunds.map((item, key) => {
                                        return (
                                            <div className='row mb-5 mt-3 align-items-center'>
                                                <div className='col-6'>
                                                    <h6>{props.basket ? item.fund_name : item.name}</h6>
                                                </div>
                                                <div className='col-3'>
                                                    <Input type="text"
                                                        disabled={true}
                                                        value={item.fund_alias}
                                                        valueChange={(value) => {
                                                            let temp_schemes = [...selectedFunds]

                                                            if (value === "") {
                                                                temp_schemes[key].fund_alias = null;
                                                            } else {
                                                                temp_schemes[key].fund_alias = value;
                                                            }

                                                            setSelectedFunds(temp_schemes)
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-3 position-relative'>
                                                    <Input type="number"
                                                        value={item.allocation}
                                                        suffix="%"
                                                        valueChange={(value) => {
                                                            if (value.length <= 3) {
                                                                let temp_funds = [...selectedFunds]
                                                                temp_funds[key].allocation = parseInt(value);
                                                                const temp_allocation = temp_funds.reduce((a, b) => a + b.allocation, 0);
                                                                const allocation = temp_allocation ? temp_allocation : 0;
                                                                setAllocation(allocation);
                                                                setSelectedFunds(temp_funds);
                                                                setDisableBtn(false);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-end">
                                            <SecondaryBtn
                                                name="Cancel"
                                                className={`${styles.e_cancel} e-cancel`}
                                                cancel="modal"
                                                handleClick={() => setAllocation("")}
                                            />
                                            {
                                                selectedFunds.filter((item) => item.allocation !== '').length === selectedFunds.length && selectedFunds.filter((item) => item.fund_alias !== null).length === selectedFunds.length ?
                                                    <PrimaryBtn
                                                        disabled={disableBtn || (allocation < 1 || allocation > 100) ? true : false}
                                                        id="save-btn"
                                                        name="Change"
                                                        className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                        handleClick={() => {
                                                            if (props.basket) {
                                                                _updateBasket()
                                                            } else {

                                                                _updateAllocation()
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <PrimaryBtn
                                                        id="save-btn"
                                                        name="Change"
                                                        className='e-modal-submit e-disabled'
                                                    />
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }
        </>
    );
}
