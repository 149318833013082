
/*
 *   File : line.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : line.js
 *   Integrations : 
 *   Version : v1.0
 */

/*import package*/
import React, { Component } from 'react';
import Chart from "react-apexcharts";

class LineChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [{
				name: '',
				type: 'area',
				data: this.props.data
			}],
			options: {
				chart: {
					//   height: 350,
					//   type: 'area',
					events: {
						mouseLeave: function (event, chartContext, config) {
							chartContext.toggleDataPointSelection(0, 0)
						}
					}
				},
				stroke: {
					width: [2],
					curve: 'straight'
				},
				xaxis: {
					categories: this.props.label,
					allowDecimals: false,
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					lines: {
						show: false
					},
					labels: {
						style: {
							colors: ['#5A596B'],
							fontSize: '10px',
							fontFamily: 'Manrope',
							fontWeight: 400,
							cssClass: 'apexcharts-xaxis-label',
						},
					},
				},
				yaxis: {
					show: true,
					allowDecimals: false,
					lines: {
						show: false
					},
					labels: {
						rotate: -90,
						show: true,
						formatter: (idx) => { return Math.abs(idx) > 99999 ? "₹" + Math.sign(idx) * ((Math.abs(idx) / 100000).toFixed(1)) + 'L' : Math.abs(idx) > 999 ? "₹" + Math.sign(idx) * ((Math.abs(idx) / 1000).toFixed(1)) + 'k' : Math.sign(idx) * Math.abs(idx) },
						style: {
							colors: ['#5A596B'],
							fontSize: '10px',
							fontFamily: 'Manrope',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label',

						},
					},
				},
				grid: {
					show: true,
					borderColor: '#6D6D6D',
					strokeDashArray: 2,
					position: 'back',

					yaxis: {
						lines: {
							show: true
						}
					},

					xaxis: {
						lines: {
							show: true
						}
					},

				},
				fill: {
					type: 'gradient',
					gradient: {
						shadeIntensity: 1,
						opacityFrom: 0.1,
						opacityTo: 0.9,
						stops: [0, 90, 100]
					},
					colors: this.props.colors,
				},
				colors: this.props.colors,
				markers: {
					size: 0,
				},
				dataLabels: {
					enabled: false,
				},
				tooltip: {
					enabled: true,
					intersect: false,
					// intersect: true,
					followCursor: true,
					y: {
						formatter: function (y) {
							if (typeof y !== "undefined") {
								return props.users ? y.toFixed(0) : "₹" + y.toLocaleString("en-IN");
							}
							return y;
						}
					},
					shared: true
					// shared: false
				},
			},
		};
		this.chartRef = React.createRef();
	}

	triggerTooltip = () => {
		if (this.chartRef.current) {
			this.chartRef.current.chart.toggleDataPointSelection(0, 0);
		}
	}

	componentDidMount() {
		this.triggerTooltip();
	}

	render() {

		return (
			<Chart
				ref={this.chartRef}
				options={this.state.options}
				series={this.state.series}
				type="area"
				height={350}

			/>
		);
	}
}


// function LineChart(props) {

// 	const chartRef = useRef();

// 	useEffect(() => {


// 		chartRef.current.chart.toggleDataPointSelection(0, 0);

// 	}, []);

// 	const series = [{
// 		name: '',
// 		type: 'area',
// 		data: props.data
// 	}];

// 	const options = {
// 		chart: {
// 			//   height: 350,
// 			//   type: 'area',
// 			events: {
// 				click: function (event, chartContext, config) {
// 					alert("success");
// 				}
// 			}
// 		},
// 		stroke: {
// 			width: [2],
// 			curve: 'straight'
// 		},
// 		yaxis: {
// 			show: true,
// 			allowDecimals: false,
// 			lines: {
// 				show: false
// 			},
// 			labels: {
// 				rotate: -90,
// 				show: true,
// 				formatter: (idx) => { return Math.abs(idx) > 99999 ? "₹" + Math.sign(idx) * ((Math.abs(idx) / 100000).toFixed(1)) + 'L' : Math.abs(idx) > 999 ? "₹" + Math.sign(idx) * ((Math.abs(idx) / 1000).toFixed(1)) + 'k' : Math.sign(idx) * Math.abs(idx) },
// 				style: {
// 					colors: ['#5A596B'],
// 					fontSize: '10px',
// 					fontFamily: 'Manrope',
// 					fontWeight: 400,
// 					cssClass: 'apexcharts-yaxis-label',

// 				},
// 			},
// 		},
// 		grid: {
// 			show: true,
// 			borderColor: '#6D6D6D',
// 			strokeDashArray: 2,
// 			position: 'back',
// 		},
// 		fill: {
// 			type: 'gradient',
// 			gradient: {
// 				shadeIntensity: 1,
// 				opacityFrom: 0.1,
// 				opacityTo: 0.9,
// 				stops: [0, 90, 100]
// 			},
// 			colors: props.colors,
// 		},
// 		colors: props.colors,
// 		markers: {
// 			size: 0,

// 		},
// 		dataLabels: {
// 			enabled: false,
// 		},
// 		tooltip: {
// 			enabled: true,
// 			intersect: false,
// 			followCursor: true,
// 			y: {
// 				formatter: function (y) {
// 					if (typeof y !== "undefined") {
// 						return props.users ? y.toFixed(0) : "₹" + y.toFixed(0);
// 					}
// 					return y;
// 				}
// 			},
// 			shared: true
// 		},
// 	};

// 	return (
// 		<Chart
// 			ref={chartRef}
// 			options={options}
// 			series={series}
// 			type="area"
// 			height={350}

// 		/>
// 	);
// };


export default LineChart;


// const triggerTooltip = () => {
// 	if (chartRef && chartRef.current) {
// 		chartRef.current.chart.showTooltip({ seriesIndex: 0, dataPointIndex: 0 });
// 	}
// };


// "apexcharts": "^3.33.1",
// "react-apexcharts": "^1.3.9",