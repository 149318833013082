/*
 *   File : value-pairs.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : ValuePair file for Value pair container;
 *   Integrations : 28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
*/

/*import packages*/
import { useEffect, useState } from 'react';

/*import components*/
import { Input } from '../../Components/Input/index';
import { PrimaryBtn } from '../../Components/Cta/index';
import { Loader } from '../../Components/Loader';
import { Toast } from '../../Components/Note';
import { FundsTab } from '../../Components/DataFormating';

/* import assets */
import Help from '../../Assets/Images/Note/help.svg';

/*import services */
import service from '../../Services/service';
import { toast } from 'react-toastify';

const ValuePair = () => {

    const [allocation, setAllocation] = useState(null);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [success, setSuccess] = useState(null);
    const [index, setIndex] = useState(1)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        _getInflationData(true);

    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }
    }, [success])

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    /*----- get inflation api -*/
    const _getInflationData = (status) => {
        setLoader(status);
        const url = "/config/get";
        service(false, url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                let arr = response.data.filter((item) => item.type === "INFLATION")
                setAllocation(arr[0]["allocation"])
            } else {
                setAllocation(null);
                setLoader(false);
            }
        })
    }

    /* ---- update inflation ----*/
    //eslint-disable-next-line
    const _updateInflation = () => {
        setSubmitLoader(true);

        const url = "/config/update";

        const data = {
            type: "INFLATION",
            allocation: parseFloat(allocation)
        }

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Inflation updated");
                _getInflationData(false);
            } else {
                toast.dismiss();
                setError(response.data.message);
            }

            setSubmitLoader(false);
        })
    };

    return (
        <div className="row e-notification position-relative">
            <div className="col-lg-6">
                <h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
                    Manage inflation
                </h3>
                <div className="card border-0 e-dashboard-card pt-4 px-4 pb-2 h-auto">
                    {
                        loader ?
                            <Loader />
                            :
                            <>
                                <div className='row'>
                                    {/* Will be enabled in future */}
                                    {/* <div className="col-10  mb-3">
                                        <h5 className='e-notification-form-title'>
                                            <FundsTab data={["Fixed deposit"]}
                                                funds={false}
                                                index={index}
                                                setTab={(temp) => {
                                                    setIndex(temp);

                                                }} />
                                        </h5>
                                    </div> */}
                                    {/* Will be enabled in future */}
                                    {/* <div className='col-2'>
                                        <img src={Help}
                                            alt="" />
                                    </div> */}
                                </div>
                                <div className='row px-2'>
                                    <div className="col-lg-12 mb-5 pb-5">
                                        <label>Inflation %</label>
                                        <Input
                                            placeholder="Enter the inflation"
                                            value={allocation}
                                            type="number"
                                            className="e-notification-form-element mb-5"
                                            valueChange={(temp) => { parseFloat(setAllocation(temp)) }}
                                        />
                                    </div>

                                    <div className="col-lg-12 text-end pt-5 mt-5 mb-4">
                                        {
                                            allocation ?
                                                <PrimaryBtn
                                                    name="Save"
                                                    className={submitLoader ? "e-notification-form-btn e-btn-loader mt-5" : "e-notification-form-btn mt-5"}
                                                    handleClick={() => {
                                                        _updateInflation()
                                                    }}
                                                />
                                                :
                                                <PrimaryBtn
                                                    name="Save"
                                                    className="e-disabled mt-5"
                                                />
                                        }
                                    </div>
                                </div>
                            </>
                    }
                </div>
                {/* Error toast */}
                {
                    error !== null ?
                        <Toast data={error}
                            type="error"
                            id="1" />
                        :
                        null
                }

                {/* Success toast */}
                {
                    success !== null ?
                        <Toast data={success}
                            type="success"
                            id="2" />
                        :
                        null
                }
            </div>
        </div>
    );
}

export default ValuePair;