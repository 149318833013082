/*
 *   File : badge.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : badge
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

import React from 'react';

/*import styles*/
import styles from '../../Styles/Components/badge.module.scss';

export default function Tooltip(props) {
	return (
		<>
			<div className={`${styles.e_tool_tip} ${props.className}`}>
				{props.content}
			</div>
		</>
	);
}