/*
 *   File : Support.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Support file for tickets;
 *   Integrations :
 *   Version : v1.0
 *   Created :28-11-2023
*/
/*import packages*/
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

/*import components*/
import { Loader } from '../../Components/Loader';
import { EmptyNote, Toast } from '../../Components/Note';
import SelectBox from '../../Components/Dropdown/select-box';

/*import assets*/

/* import services */
import service from '../../Services/service';
import { SupportTicketDetail } from '../../Components/Modal';
import { PrimaryBtn } from '../../Components/Cta';

const STATUS_OPTIONS = [
    {
        value: "", label: "All"
    },
    {
        value: "Open", label: "Open"
    },
    {
        value: "Closed", label: "Closed"
    }
];


const Support = () => {
    const navigate = useNavigate();
    const [supportTicketsData, setSupportTicketsData] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(true);
    const [queryType, setQueryType] = useState(null);
    const [status, setStatus] = useState(STATUS_OPTIONS[0]);
    const [queryOptions, setQueryOptions] = useState([]);
    const [openTicketModal, setOpenTicketModal] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState("");
    const initialRender = useRef(true);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });


        _getQueryTypes();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (queryType !== null) {
            setLoader(true);
            _getTicketList();
        }
        //eslint-disable-next-line
    }, [queryType, status]);

    useEffect(() => {
        setTimeout(() => {
            setError(null)
        }, 3000);
    }, [error]);


    useEffect(() => {
        if (openTicketModal === true) {
            let email_modal = new Modal(document.getElementById('support-ticket-modal'));
            email_modal.show();
        };
    }, [openTicketModal]);

    const _getTicketList = () => {

        const url = '/support/list-tickets';

        const data = {
            query_type: queryType.value,
            status: status.value
        }

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSupportTicketsData(response.data);
            } else {
                setSupportTicketsData([])
            }
            setLoader(false);
        });
    };

    const _getQueryTypes = () => {
        const url = '/support/query-types';
        service(false, url).then((response) => {
            if (response.status_code === 200) {
                let queries = [];
                queries.push({
                    label: "All",
                    value: 0
                })
                response.data.map((item) => (
                    queries.push({
                        label: item.name,
                        value: item.id
                    })
                ))
                setQueryOptions(queries);
                setQueryType(queries[0]);

                initialRender.current = false;
            }
        });
    };

    return (
        <>
            {
                initialRender.current == true ? (
                    <Loader />
                ) : (
                    <div className="row mt-4 position-relative mt-2 pt-2">
                        <div className="col-12 mt-4">
                            <div className='e-tab-position'>
                                <h3>Manage support</h3>
                            </div>
                            <div className="card e-dashboard-card border-0 p-4 e-holding-table">
                                {/*--------------- notification list ------------*/}

                                <div className='row mb-3'>
                                    <div className="col-lg-2 col-3 ps-3">
                                        <label className="mb-2">Query type</label>
                                        <SelectBox
                                            value={queryType}
                                            options={queryOptions}
                                            selectChange={(temp) => {
                                                setQueryType(temp);
                                            }}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-3">
                                        <label className="mb-2">Status</label>
                                        <SelectBox
                                            value={status}
                                            options={STATUS_OPTIONS}
                                            selectChange={(temp) => {
                                                setStatus(temp);
                                            }}
                                            isSearchable={false}
                                        />
                                    </div>

                                </div>

                                {
                                    loader === true ?
                                        <Loader />
                                        :
                                        supportTicketsData.length > 0 ?
                                            <>
                                                {
                                                    supportTicketsData.map((item, key) => (
                                                        <div key={key} className='row'>

                                                            <div className="col-9 mb-3 e-notification-sec mx-3 ">
                                                                <div className='row align-items-center px-2 py-3'>
                                                                    <div className='col-12 flex-fill'>
                                                                        <h4 className="mb-0 d-flex align-items-center justify-content-between">
                                                                            <span>
                                                                                <span>
                                                                                    IFSH-ST-{item.id}
                                                                                </span>
                                                                                <span className='cursor-pointer ps-2'
                                                                                    onClick={() => navigate(`/user-detail?id=${item.customer_id}`)}>
                                                                                    {item.customer_name}
                                                                                </span>
                                                                            </span>
                                                                            <span className={`${item.status === "Open" ? "e-status-badge-success" : "e-status-badge-fail"} e-status-badge float-end py-2 px-2`}>{item.status}</span>
                                                                        </h4>
                                                                        <p className='mb-2'>
                                                                            <span className='e-text-black e-font-weight-700'>Created on : </span>
                                                                            {item.created}
                                                                        </p>
                                                                        <p className='mb-2'>
                                                                            <span className='e-text-black e-font-weight-700'>Subject : </span>
                                                                            {item.subject}
                                                                        </p>
                                                                        <p className='mb-2'>
                                                                            <span className='e-text-black e-font-weight-700'>Message</span>
                                                                            <p className='mb-0 mt-2'>
                                                                                <span>
                                                                                    {item.description}
                                                                                </span>
                                                                            </p>
                                                                        </p>

                                                                        <div className='mt-2 d-flex justify-content-end gap-3'>
                                                                            <PrimaryBtn className={"px-3 py-2 me-auto"}
                                                                                name={item.status == "Open" ? "Manage ticket" : "View remarks"}
                                                                                handleClick={() => {
                                                                                    setOpenTicketModal(true);
                                                                                    setSelectedTicket(item);
                                                                                }} />
                                                                            {
                                                                                item.image &&
                                                                                <a href={item.image}
                                                                                    target='_blank'
                                                                                    className='text-link e-font-14 e-text-primary'>
                                                                                    View attachment
                                                                                </a>
                                                                            }
                                                                            {/* <a href={item.image}
                                                                                target='_blank'
                                                                                className='text-link e-font-14 e-text-primary'>
                                                                                View detail
                                                                            </a> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </>
                                            :
                                            <div className="row mt-3 p-2 e-notification-sec mx-3 my-5">
                                                <EmptyNote title={"No tickets found"}
                                                    note="Please try different filter" />
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            }


            {
                openTicketModal === true &&
                <SupportTicketDetail
                    ticket={selectedTicket}
                    onClose={(status) => {
                        if (status === "SUCCESS") {
                            _getTicketList();
                        }
                        setOpenTicketModal(false);
                    }} />
            }

            {/*--------------- error message toast-------------*/}
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>
    );
}

export default Support;
