/*
 *   File : list.js
 *   Author URI : https://evoqins.com
 *   Description : Family list page
 *   Integrations :react-router-dom, react-toastify
 *   Version : v1.0
 *   Created : 10-05-2024 
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { SearchInput } from "../../Components/Input";
import { Loader } from "../../Components/Loader";
import { EmptyNote } from "../../Components/Note";
import Datatable from "../../Components/DataFormating/data-table";
import service from "../../Services/service";

// Styles
import styles from '../../Styles/Components/search-input.module.scss';

function FamilyList() {

    const navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [familyList, setFamilyList] = useState([]);

    // Family list data table column datas
    const FAMILY_LIST_TABLE_COLUMN = [
        {
            name: 'Family ID',
            selector: (row) => row.family_id ? row.family_id : '--',
            sortable: true,
            left: true,
            minWidth: "110px",
            defaultSortAsc: false,
        },
        {
            name: 'Family head name',
            selector: (row) => row.name ? row.name : '--',
            sortable: true,
            left: true,
            minWidth: "170px",
            defaultSortAsc: false,
            cell: row => (
                <span className="d-block py-3 break-word e-link link-offset-1" data-tag="allowRowEvents">
                    {
                        row.name ? row.name : "--"
                    }
                </span>
            )
        },
        {
            name: 'No of sub accounts',
            selector: (row) => row.no_of_sub_accounts ? row.no_of_sub_accounts : '--',
            sortable: true,
            left: true,
            minWidth: "180px",
            defaultSortAsc: false,
        },

        {
            name: 'Family invested',
            selector: row => row.invested_amount ? row.invested_amount : 0,
            sortable: true,
            left: true,
            minWidth: '160px',
            cell: row => (
                <div>
                    {
                        row.invested_amount_text ? row.invested_amount_text : 0
                    }
                </div>
            )

        },
        {
            name: 'Current value',
            selector: row => row.current_amount ? row.current_amount : 0,
            sortable: true,
            left: true,
            minWidth: '160px',
            cell: row => <div>
                {
                    row.current_amount_text ? row.current_amount_text : 0
                }
            </div>

        },
        {
            name: 'P&L',
            selector: row => row.current_amount - row.invested_amount,
            sortable: true,
            left: true,
            minWidth: '160px',
            cell: row => <div>
                {
                    "₹" + Number((row.current_amount - row.invested_amount).toFixed(2)).toLocaleString("en-IN")
                }
            </div>

        },
        {
            name: 'Individual AUM',
            selector: row => row.individual_aum ? row.individual_aum : 0,
            sortable: true,
            left: true,
            minWidth: '160px',
            cell: row => <div>
                {
                    row.individual_aum_text ? row.individual_aum_text : 0
                }
            </div>

        },
        {
            name: 'Family AUM',
            selector: row => row.family_aum ? row.family_aum : 0,
            sortable: true,
            left: true,
            minWidth: '160px',
            cell: row => <div>
                {
                    row.family_aum_text ? row.family_aum_text : 0
                }
            </div>
        },
    ];

    // Fetch all data when user clears search and initial page load
    useEffect(() => {
        if (search.length === 0) {
            setLoading(true);
            _getFamilyList();
        }
    }, [search]);

    // handle search family head
    const _handleSearch = () => {
        // API call
        setLoading(true);
        _getFamilyList();
    };

    // Navigating to detail page
    const _handleNavigate = (row) => {
        navigate("family-detail", {
            state: {
                family_id: row.family_id
            }
        });
    };



    // ========================//
    //======= API CALLS =======//

    // API - get family users (listing API)
    const _getFamilyList = () => {

        let is_post = true;
        let url = '/family/list';
        let data = {
            "page_num": 1,
            "page_size": 10,
            "query": search ? search : null
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setFamilyList(response.data.users);
            } else {
                setFamilyList([]);
            };

            setLoading(false);

        });
    };

    return (
        <div className="row position-relative mt-5 pt-3 min-vh-100">
            <div className="col-lg-12">
                <div className='e-tab-position'>
                    <h3>Family users</h3>
                </div>
                <div className="e-dashboard-card border-0 p-0">
                    <div className="row">
                        <div className="col p-4 ps-3 mx-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="e-section-title mb-0">Family users list</h6>
                                <div className="col-3 d-flex align-items-center e-bg-search-bg e-border-radius-4">
                                    <SearchInput
                                        placeholder="Search by IFSH ID or name"
                                        value={search}
                                        valueChange={(value) => setSearch(value)}
                                        onSearch={_handleSearch}
                                    />
                                    {
                                        search && search.length &&
                                        <>
                                            <button className={`${styles.e_search_close} pe-3`}
                                                onClick={() => setSearch("")}>
                                            </button>
                                            <span className={`${styles.e_search_submit} `}
                                                onClick={_handleSearch}>
                                            </span>

                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pb-5">
                            {
                                loading == true ?
                                    <Loader />
                                    : familyList.length === 0 ?
                                        <EmptyNote note="There are data to show" />
                                        :
                                        <div className="col-lg-12 px-0">
                                            <Datatable
                                                pagination={true}
                                                columns={FAMILY_LIST_TABLE_COLUMN}
                                                data={familyList}
                                                perPage={30}
                                                selectableRows={false}
                                                rowClick={(row) => _handleNavigate(row)}
                                            />
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FamilyList;