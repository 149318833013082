/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import package*/
import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';

// Components
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";

/*import components*/
import { TextBtn } from '../Cta/index';
import { EditFundClass, Confirmation } from '../Modal/index';
import EditAllocation from '../Modal/edit-allocations';
import DeleteFunds from '../Modal/delete-funds';

/*import style*/
import styles from '../../Styles/Components/accordion.module.scss';

/*import assets*/
import Rename from '../../Assets/Images/DataFormating/rename.svg';
import Add from '../../Assets/Images/Modal/add.svg';


export default function Accordion(props) {

	const [edit, setEdit] = useState({});
	const [id, setId] = useState(0);
	const [ids, setIds] = useState([]);
	const [equityClass, setEquityClass] = useState(false);
	const [editAllocation, setEditAllocation] = useState(false);

	/* ------ open edit fund Modal -----*/
	const openModal = (item, asset) => {
		setEdit(props.data[item]);
		setEquityClass(asset)
	};


	useEffect(() => {
		if (editAllocation === true) {
			var myModal = new Modal(document.getElementById("editAllocations"), {});
			myModal.show();
		}
	}, [editAllocation]);

	useEffect(() => {
		if (equityClass === true) {
			var myModal = new Modal(document.getElementById("editEquityFundClass"), {});
			myModal.show();
		}
	}, [equityClass])

	return (
		<>
			<div className={`${styles.e_faq_accordion}`}>
				<div className="accordion" id="accordionDescription">
					<div className="row mx-0">
						{
							Object.keys(props.data).map((item, key) => {
								return (
									<div className={`${styles.e_accordion_sec} col-lg-12`}>
										<div className={`${styles.e_accordion_item} accordion-item`}>
											<h2 className={`${styles.e_accordion_header} accordion-header`}
												id={"collapseDescription" + key} >
												<button className={`${styles.e_accordion_button}`}
													type="button"
													data-bs-toggle="collapse"
													data-bs-target={`#collapse${key}`}
													aria-expanded="false"
													aria-controls={`collapse${key}`} >
													<div className="row" onClick={() => { setId(key); }}>
														{
															key !== id ?
																<div className="row" >
																	<div className="col-lg-10" >
																		<div className='d-flex gap-2 align-items-center'>
																			<span className={`${styles.e_accordion_title} mx-0 ps-0`}>{item} </span>
																			<span className={`${styles.e_accordion_sub_title} mx-0 ps-0`}>
																				{props.data[item].reduce((a, b) => a + b.allocation, 0)}%
																			</span>
																		</div>
																		{
																			key === id ?
																				<>
																					{
																						props.basket === true ?
																							null
																							:
																							<span onClick={() => openModal(item, true)} edit="true" className="ps-2 e-text-primary e-font-manrope  e-font-weight-700 line-height-24px e-font-14">
																								<IcoMoon iconSet={iconSet}
																									icon={"edit"}
																									className='h-16 me-1'
																									color={"#1c359f"} />
																								Rename
																							</span>
																					}
																				</>
																				:
																				null
																		}
																	</div>
																	<div className="col-lg-2" >
																		<span className={`${styles.e_accordion_icon}`} ></span>
																	</div>
																</div>
																:
																null
														}
													</div>
													<div className="row" onClick={() => { setId(null); }}>
														{
															key === id ?
																<div className="row" >
																	<div className="col-lg-10 d-flex align-items-center" >
																		<div className="d-flex gap-2 align-items-center">
																			<span className={`${styles.e_accordion_title} mx-0 ps-0`}>{item}</span>
																			<span className={`${styles.e_accordion_sub_title} px-0 mx-0`}>
																				{props.data[item].reduce((a, b) => a + b.allocation, 0)}%
																			</span>
																		</div>
																		{
																			key === id ?
																				<>
																					{props.basket === true ?
																						null
																						:
																						<span onClick={() => openModal(item, true)}
																							edit="true"
																							className="ps-2 e-text-primary e-font-manrope  e-font-weight-700 line-height-24px e-font-14">
																							<IcoMoon iconSet={iconSet}
																								icon={"edit"}
																								className='h-16 me-1'
																								color={"#1c359f"} />
																							Rename
																						</span>}
																				</>
																				:
																				null
																		}
																	</div>
																	<div className="col-lg-2" >
																		<span className={`${styles.e_accordion_icon_minus}`} ></span>
																	</div>
																</div>
																:
																null
														}
													</div>
												</button>
											</h2>
											{
												key === id ?
													<>

														{
															props.data[item].map((fund) => {
																return (
																	<div>
																		<div className={`${styles.e_accordion_body} accordion-body mt-3`}>
																			<div className={`${styles.e_accordion_wrapper} row pt-3`}>
																				{
																					item.mf_image ?
																						<span className={`${styles.e_accordion_content_img} col-lg-1`}><img src={fund.mf_image} alt="" /></span>
																						:
																						null
																				}
																				<div className={`${styles.e_accordion_content} col-lg-11`}>
																					<div className='d-flex'>
																						<div>{props.basket ? fund.fund_name : fund.name}</div>
																						<h6 style={{ paddingTop: "4px" }} className='mb-0 ps-2'>{fund.fund_alias ? `(${fund.fund_alias})` : null}</h6>
																					</div>
																					<h6 >Allocation: {fund.allocation}%</h6>
																				</div>
																			</div>

																		</div>
																	</div>
																)
															})
														}
														<div className="row mt-3">
															<div className="col-lg-9 text-start">
																<TextBtn
																	left={true}
																	name="Change allocation"
																	className={`${styles.e_change} ms-1 me-3`}
																	handleClick={() => {
																		setEdit(props.data[item]);
																		setEditAllocation(true)
																	}}
																/>

																<TextBtn
																	left={true}
																	name="Delete fund"
																	className={`${styles.e_delete}`}
																	toggle="modal"
																	target="#deleteAllocations"
																	handleClick={() => setEdit(props.data[item])}
																/>
															</div>
															{
																props.basket === true ?
																	null
																	:
																	<div className='col-lg-3 text-end'>
																		<TextBtn
																			left={true}
																			name="Add more fund"
																			image={Add}
																			className={`${styles.e_change} ms-1 me-3`}
																			toggle="modal"
																			target="#addFundClass"
																			handleClick={() => {
																				props.setEditedData(props.data[item])
																			}}
																		/>
																	</div>
															}
														</div>
													</>
													:
													null



											}
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
			{/*-------------- Change allocation modal -----------*/}

			{
				editAllocation === true && Object.keys(edit).length > 0 ?
					<EditAllocation
						editedData={edit}
						// riskId={props.riskId}
						name={props.name}
						debt={false}
						basket={props.basket}
						index={edit[0].basket_id}
						updatedAllocation={(data) => { props.updatedFund(data) }}
						close={() => {
							props.close()
							setEdit({});
							setEditAllocation(false);
						}}
					/>
					:
					null
			}


			{/*-------------- edit fund class modal -----------*/}

			{
				equityClass === true && Object.keys(edit).length > 0 ?
					<EditFundClass
						editedData={edit}
						name={props.name}
						debt={false}
						updatedClass={(data) => { props.updatedFund(data) }}
						close={() => {
							setEdit({});
							setEquityClass(false);
						}}
					/>
					:
					null
			}


			{/* -------------- Add fund modal -----------
			<FundClass
				editedData={edit}
				updatedFund={(data) => { props.updatedFund(data) }}
				close={() => {
					setEdit({})
				}}
			/> */}

			<DeleteFunds
				close={() => {

				}}
				editedData={edit}
				selectedSchemes={(temp) => {
					setIds(temp);
					setTimeout(() => {
						var myModal = new Modal(document.getElementById("confirmationEquity"), {});
						myModal.show();
					}, 500)
				}} />

			{/*-------------- Delete fund modal -----------*/}

			<Confirmation
				title="fund"
				allocation_ids={ids}
				name={props.name}
				debt={false}
				content="Are you sure you want to delete this fund?"
				deleteFund={true}
				index={props.index}
				updatedFund={(data) => { props.updatedFund(data) }}

			/>

		</>
	);
}


