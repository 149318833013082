/*
 *   File : Notification.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Notification file for All notifications;
 *   Integrations :
 *   Version : v1.0
 *   Created :28-11-2023
*/
/*import packages*/
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'antd';

/*import components*/
import { Loader } from '../../Components/Loader';
import { Toast } from '../../Components/Note';

/*import assets*/

/* import services */
import service from '../../Services/service';


const Notification = (props) => {
	let navigator = useNavigate();

	const [notificationData, setNotificationData] = useState([]);
	const [error, setError] = useState(null);
	const [page, setPage] = useState(1);
	const [loader, setLoader] = useState(false);
	//eslint-disable-next-line
	const [count, setCount] = useState();

	useEffect(() => {
		window.scrollTo({
			top: 0, behavior: 'smooth' //scroll top
		});
	}, []);

	useEffect(() => {
		_getNotificationCount();
		_getNotification();
		//eslint-disable-next-line
	}, [page]);

	useEffect(() => {
		setTimeout(() => {
			setError(null)
		}, 3000);
	}, [error]);

	const _getNotification = () => {
		setLoader(true);

		const url = '/notification/get-received-notification';

		const data = {
			page_num: page
		}

		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					setNotificationData(response.data);
					setLoader(false);
				} else {
					setLoader(false);
					setNotificationData([])
				}
			}
			else {
				setError(response.data.message);
				setNotificationData([])
				setLoader(false);
			}
		});
	}

	const _getNotificationCount = () => {
		const url = '/notification/count';
		service(false, url).then((response) => {
			if (response.status_code === 200) {
				setCount(response.data.notification_count);
			}
		});
	}

	/*---- read notification api----*/
	const _readNotification = (id) => {
		const url = '/notifications/read';

		const data = {
			notification_id: id,
		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				_getNotification();
			} else {
				setError(response.data.message);
			}
		});
	}

	const _redirectUser = (temp) => {
		switch (temp.type) {
			case 1: navigator(`/user-detail?id=${temp.data_id}`);
				break;
			case 2: navigator(`/user-detail?id=${temp.data_id}&tab=${2}`);
				break;
			default: navigator("/");
		}
	}

	return (
		<>
			<div className="row mt-5 position-relative">
				<div className="col-md-12 pt-1">
					<div className='e-tab-position'>
						<h3 >All notifications</h3>
					</div>
					<div className="card e-dashboard-card border-0 p-2 pb-3 e-holding-table">
						{/*--------------- notification list ------------*/}

						{
							loader === true ?
								<Loader />
								:
								notificationData.length > 0 ?
									<>
										{
											notificationData?.filter((data) => data.is_read === false).map((notData, key) => (
												<div
													className="row mt-3 p-2 e-notification-sec mx-3 cursor-pointer"
													key={notData.notification_id}
													onClick={() => {
														_readNotification(notData.notification_id);
														_redirectUser(notData)
													}}>
													<h4 className="pt-3">{notData.title}</h4>
													<p className='pt-2'>{notData.description}</p>
												</div>
											))
										}
										{
											notificationData?.filter((data) => data.is_read === true).map((notData, key) => (
												<div className="row mt-3 p-2 e-notification-sub-sec mx-3 cursor-pointer"
													key={notData.notification_id}
													s onClick={() => _redirectUser(notData)}>
													<h4 className="pt-1">{notData.title}</h4>
													<p className='pt-2'>{notData.description}</p>
												</div>
											))
										}

									</>
									:
									<div className="row mt-3 p-2 e-notification-sec mx-3 my-5">
										<h6 className='text-center'>No notifications found</h6>
									</div>
						}
						<div align="center" className='pt-4 pb-2'>
							<Pagination
								current={page}
								total={count}
								onChange={(current) => {
									setPage(current);
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			{/*--------------- error message toast-------------*/}
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
		</>
	);
}

export default Notification;

