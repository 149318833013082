import { applyMiddleware, createStore } from 'redux';
import rootReducer from '../Reducer/index';
// import thunk from 'redux-thunk';
import createSagaMiddleware from "redux-saga";
import { rootSaga } from '../Sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];


// redux store 
const Store = createStore(
    rootReducer,
    applyMiddleware(...middlewares)
);

// middleware: redux saga 
sagaMiddleware.run(rootSaga);

export { Store }



// const _configureStore = () => {
//     return createStore(
//         rootReducer,
//         composeEnhancers(applyMiddleware(sagaMiddleware))
//     )
// }
// sagaMiddleware.run(rootSaga)
// export default _configureStore;
