/*
 *   File : icon.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Icon Style Tooltip
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import package*/
import React from 'react';

/*import styles*/
import styles from '../../Styles/Components/icon-style-tooltip.module.scss';

const IconStyleToolTip = (props) => {

  return (
    <div className={`${styles.e_icon_tooltip} ${props.className}`} onMouseEnter={() => { if (props.more) props.hover(); }} onMouseLeave={() => { if (props.more) props.leave(); }}>
      <div className="row">

        {
          props.icon ?
            <div className={`${styles.e_icon} col-1 gx-0`}>
              <img src={props.icon} alt="" />
            </div>
            :
            null
        }

        <div className="col-11 ps-3">
          <span dangerouslySetInnerHTML={{ __html: `${props.content}` }}></span>
          {
            props.more ?
              <a href={props.more} target="_blank" rel="noreferrer">{props.more}</a>
              :
              null
          }
        </div>
      </div>
      {/* <span className={styles.className}>{props.content}<img src={props.icon} className="ms-2" /></span> */}
    </div>
  );
}

export default IconStyleToolTip;