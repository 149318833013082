/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import package*/
import React, { useEffect, useState, useRef } from 'react';

/*import components*/


/*import style*/
import styles from '../../Styles/Components/investment-without-goals.module.scss';

/*import assets*/
import Icon from '../../Assets/Images/DataFormating/Icon.svg';
import { IconStyleToolTip } from '../Badge';
import { EmptyNote } from '../Note';

export default function InvestmentWithoutGoals(props) {

    const wrapperRef = useRef(null);
    //eslint-disable-next-line
    const [tooltip, setTooltip] = useState(false);


    /* ----- closing tooltip on mouse click in any other position of screen ----*/
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);

    const closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setTooltip(false)
        }
    }

    return (
        <div className={`${styles.e_investment_without_goals}`}>

            <div className="row mx-0">
                {
                    props.summaryData && props.summaryData.funds && props.summaryData.funds.length > 0 ?
                        <div className={`${styles.e_accordion_sec} col-lg-12`}>
                            <div className={`${styles.e_accordion_item}`}>

                                <div className="row" >
                                    <div className="col-lg-12" >
                                        <span className={`${styles.e_accordion_title}`}>Regular investment</span>

                                    </div>

                                </div>
                            </div>
                            <div className={`${styles.e_accordion_body} accordion-body mt-3`}>
                                <div className={`${styles.e_accordion_wrapper} `}>
                                    <div className="row">
                                        <div className='col-lg-3 e-investment-tooltip position-relative '>
                                            <div className={`${styles.e_accordion_body_wrapper} ${styles.e_accordion_body_wrapper_invested}`}>
                                                <h6>Amount invested
                                                    {/* <img
                                                        className="ps-2"
                                                        src={Icon} ref={wrapperRef} alt=""
                                                        onMouseEnter={() => setTooltip(true)}
                                                        onMouseLeave={() => setTooltip(false)}
                                                        style={{ cursor: "pointer" }}
                                                    /> */}
                                                </h6>
                                                {/* {
                                                    tooltip ?
                                                        <IconStyleToolTip
                                                            className="e-icon-tooltip"
                                                            content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />

                                                        :
                                                        null
                                                } */}
                                                <p className='mb-0 pt-2'>{props.summaryData && props.summaryData.investment_summary && props.summaryData.investment_summary.invested_amount_text}</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className={`${styles.e_accordion_body_wrapper} ${styles.e_accordion_body_wrapper_value}`}>
                                                <h6>Current value</h6>
                                                <p className='mb-0 pt-2'>{props.summaryData && props.summaryData.investment_summary && props.summaryData.investment_summary.current_amount_text}</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className={`${styles.e_accordion_body_wrapper}`}>
                                                <h6>Total return%</h6>
                                                <p className='mb-0 pt-2'>{props.summaryData && props.summaryData.investment_summary && props.summaryData.investment_summary.returns_percentage_text}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pt-4'>
                                        <div className="col-lg-12" >
                                            <span className={`${styles.e_accordion_sub_title}`}>Funds and investments</span>
                                        </div>
                                    </div>

                                    <div className='row pt-2'>
                                        <div className='col-lg-12'>
                                            <div className={`${styles.e_investment_wrapper} `}>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <h6>Invested Funds</h6>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <h6>Amount</h6>
                                                    </div>
                                                </div>
                                                {
                                                    props.summaryData.funds.map((item, key) => {
                                                        return (
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <p className='pt-3'>{item.name}</p>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <p className='pt-3'>{item.invested_amount_text}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        :
                        <EmptyNote note="No data found" />
                }
            </div>

        </div>


    );
}


