/*
 *   File : primary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/* import packages */
import React from 'react';
import IcoMoon from 'react-icomoon';

/*Style Import*/
import styles from '../../Styles/Components/primary.module.scss';
import LoaderImg from '../../Assets/Images/Loader/btn-loader-white.gif';

import iconSet from "../../Assets/Images/Icons/selection.json";

const PrimaryBtn = (props) => {

	/* --- on press enter key ---*/
	const keyPress = (e) => {
		if (e.keyCode === 13) {
			props.onEnter();
		}
	}

	return (
		<>

			<button id={props.id}
				className={`${styles.e_p_btn} ${props.className} ${props.disabled && "opacity-75"} e-primary-button-hover transition`}
				data-bs-dismiss={props.cancel}
				data-bs-toggle={props.toggle}
				data-bs-target={props.target}
				onClick={props.handleClick}
				onKeyDown={(e) => keyPress(e)}
				disabled={props.disabled}
				type={props.type ? props.type : "submit"}>
				{
					props.prefix ?
						<img src={props.prefix} alt="" className='pe-2' />
						:
						null
				}
				{
					props.icon &&
					<IcoMoon iconSet={iconSet}
						icon={props.icon}
						size={props.size}
						className={props.iconClassName}
						color={props.color} />
				}
				{
					props.loading ? (
						<img src={LoaderImg}
							width={22}
							height={22}
							className='object-fit-contain mx-5 h-100'
							draggable="false" />
					) : (
						<span>{props.name}</span>
					)
				}

			</button>
		</>
	);
}

export default PrimaryBtn;
