/*
 *   File : radio-group.js
 *   Author : https://evoqins.com
 *   Description : Radio button component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useState } from 'react';

// Assets
import Active from "../../Assets/Images/Kyc/circle-active.svg";
import InActive from "../../Assets/Images/Kyc/circle-inactive.svg";

const RadioGroup = (props) => {

    const [radioData, setRadioData] = useState([]);

    useEffect(() => {
        if (props.data) {
            setRadioData(props.data);
        }
    }, [props.data]);

    return (
        <>

            {radioData.map((data, key) => (
                <div key={key} className={`${props.selected === data.id ? "cursor-default" : "cursor-pointer"} d-flex align-items-center gap-2 `}
                    onClick={() => props.handleSelect(data.id)} >
                    <img src={props.selected === data.id ? Active : InActive}
                        draggable={false}
                        width={24}
                        alt='radio' />
                    <p className={`mb-0 ${props.selected === data.id ? 'e-text-egyptian-blue' : 'e-text-chinese-black'} e-font-14  e-font-manrope e-font-weight-500 line-height-20px`}>
                        {data.display_name}
                    </p>
                </div>
            ))}

        </>
    )
}

export default RadioGroup;