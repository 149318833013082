/*
 *   File : editQuestion.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : edit Question Modal
 *   Integrations : 17-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input } from '../../Components/Input';
import { SecondaryBtn, PrimaryBtn } from '../../Components/Cta';
import { Toast } from '../Note';

/*import styles*/
import styles from '../../Styles/Components/fund-class.module.scss';
import SelectBox from '../Dropdown/select-box';

/* import assets */
import AddNew from '../../Assets/Images/DataFormating/add-new.svg';
import DeleteIcon from "../../Assets/Images/Cta/delete-topic.svg";

/*import services */
import service from '../../Services/service';

export default function EditQuestion(props) {

    const [question, setQuestion] = useState("");
    const [weightage, setWeightage] = useState(null);

    const [type, setType] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [questionOptions, setQuestionOptions] = useState(props.question_options);
    const [config, setConfig] = useState({
        "min_max": { "min": "", "max": "", "default": '' },
        "postfix_enabled": false, "singular_postfix": "", "plural_postfix": ""
    })
    let [answersArrMCQ, setAnswersArrMCQ] = useState([{ answer: "", score: "", }])


    useEffect(() => {
        if (props.question_options.length > 0) {
            setQuestionOptions(props.question_options);
            setType(props.question_options[0])
        }
    }, [props.question_options])

    useEffect(() => {
        if (props.data) {
            setQuestion(props?.data?.question)
            setWeightage(props?.data?.weight)
            if (questionOptions.length > 0) {
                const question_type = questionOptions.findIndex((item) => item.value === props.data.question_type)
                setType(questionOptions[question_type])
            }
            if (props?.data?.question_type === 1) {
                setAnswersArrMCQ(props?.data?.answers);

            }
            else if (props?.data?.question_type === 2) {
                setConfig(props.data.config)

            } else {
                setAnswersArrMCQ([{ answer: "", score: "", }]);
            }
        }
    }, [props?.data, questionOptions]);


    /*----add Fields for mcq type ------*/
    const addFieldMCQ = () => {
        setAnswersArrMCQ([...answersArrMCQ, { answer: "", score: "", }]);
    };

    const _handleDeleteOption = (id) => {
        let updated_options = answersArrMCQ.filter((item, key) => key !== id);
        setAnswersArrMCQ(updated_options);
    };

    /*----push values of field to mcq array------*/
    const handleChange = (val, e, i) => {
        const { name } = e.target
        const list = [...answersArrMCQ]
        list[i][name] = val;
        setAnswersArrMCQ(list)
    }

    /*--- edit question api ----*/
    const editQuestion = () => {
        let arr = [];
        let url = '/quiz/upsert-question';
        let data = {
            category_id: props.details.category_id,
            question_id: props?.data?.question_id,
            question: question,
            question_type: type?.value,
            weight: Number(weightage),
            order: 1,
            config: type.value === 2 ? config : null,
            answers: type?.value === 1 ? answersArrMCQ : arr
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Question updated")
                setTimeout(() => {
                    $('.btn-close').trigger('click');
                }, 1000)
                props.edit(true)
            } else {
                setError(response.data.message)
            }
        })
        setTimeout(() => {
            setError(null)
        }, 3000)
        setTimeout(() => {
            setSuccess(null)
        }, 3000)
    }

    /*----   form validation ---*/
    // const validateForm = () => {
    // 	let valid = true;
    // 	if (type.value === 1) {
    // 		for (let index = 0; index < answersArrMCQ?.length; index++) {
    // 			const element = answersArrMCQ[index];
    // 			if (isNaN(element.score) || element.answer === '') valid = false;
    // 		}
    // 		if (question && description && weightage ) {
    // 			return true;
    // 		}
    // 		else {
    // 			return false;
    // 		}
    // 	}
    // 	if (type.value === 2) {
    // 		for (let index = 0; index < answersArrYN?.length; index++) {
    // 			const element = answersArrYN[index];
    // 			if (isNaN(element.score) || element.answer === '') valid = false;
    // 		}
    // 		if (question && description && weightage ) {
    // 			return true;
    // 		}
    // 		else {
    // 			return false;
    // 		}
    // 	}
    // 	if (type.value === 3 || type.value === 4) {
    // 		if (question && description) {
    // 			return true;
    // 		}
    // 		else {
    // 			return false;
    // 		}
    // 	}

    // 	return false;
    // }

    return (
        <>
            <div className={`${styles.e_add_fund} modal fade p-0`}
                id="editQuestion"
                tabIndex="-1"
                aria-labelledby="success"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable px-0 modal-lg">
                    <div className="modal-content p-0">
                        <div className="modal-header px-4 pt-4">
                            <p className='e-font-manrope e-font-weight-700 e-font-16'>Edit question</p>
                            <button type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-4 pb-4 no-scrollbar overflow-x-hidden">
                            <div className="row">
                                <div className="col-9 col-sm-10 mb-3">
                                    <label>Question</label>
                                    <Input placeholder="What if"
                                        value={question}
                                        valueChange={(value) => setQuestion(value)} type="text" />
                                </div>
                                <div className='col-3 col-sm-2 mb-3 ps-0 position-relative'>
                                    <label>Weightage</label>
                                    <Input suffix="%"
                                        className={styles.e_input_box}
                                        placeholder="0" value={parseInt(weightage)}
                                        valueChange={(value) => setWeightage(parseInt(value))} type="number" />
                                </div>
                            </div>

                            <div className="row pt-2">
                                <div className="col mb-3">
                                    <label>Type</label>
                                    {/* {
										props?.data?.question_type ? */}
                                    <SelectBox placeholder="Fill"
                                        value={type}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={questionOptions}
                                        selectChange={(value) => setType(value)} type="text" />
                                    {/* :
											null
									} */}

                                </div>
                            </div>

                            {
                                type?.value === 1 ?
                                    <div className="row">
                                        <div className="col">
                                            <p className="mt-4 mb-3 e-font-weight-700 e-font-14">Choices</p>
                                            {
                                                answersArrMCQ?.map((item, key) => (
                                                    <>
                                                        <div className='d-flex justify-content-between align-items-center mt-1 mb-2'>
                                                            <h5 className='mb-0 e-font-weight-500 e-font-14 e-font-manrope'>Option {key + 1}</h5>
                                                            <img src={DeleteIcon}
                                                                className='object-fit-contain cursor-pointer'
                                                                alt="delete icon"
                                                                onClick={() => _handleDeleteOption(key)} />
                                                        </div>
                                                        <div className="row px-0">
                                                            <div className="col-9 col-sm-10 mb-3">
                                                                <label>Answer</label>
                                                                <Input placeholder="Answer" name="answer" value={item.answer} valueChange={(value, e) => handleChange(value, e, key)} type="text" />
                                                            </div>
                                                            <div className='col-3 col-sm-2 mb-3 ps-0 position-relative'>
                                                                <label>Score</label>
                                                                <Input placeholder="0" name="score" value={parseFloat(item.score)} valueChange={(value, e) => handleChange(parseFloat(value), e, key)} type="number" />
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                            <div className={`${styles.e_add_new_quiz_sec} ps-0 mt-1`} onClick={() => addFieldMCQ()}>
                                                <h6><span className="pe-2" ><img src={AddNew} alt="add" /></span>{answersArrMCQ.length === 0 ? "Add options" : "Add New"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    type?.value === 2 ?
                                        <>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="mt-4 mb-3 e-font-weight-700 e-font-14">Config</p>
                                                    <div className="row">
                                                        <div className="col-4 mb-3">
                                                            <label>Min</label>
                                                            <Input
                                                                placeholder="Minimum"
                                                                name="Min"
                                                                value={config.min_max.min}
                                                                valueChange={(value, e) => {
                                                                    let config_obj = { ...config }
                                                                    config_obj.min_max.min = value;
                                                                    setConfig(config_obj)
                                                                }}
                                                                type="numbers"
                                                            />
                                                        </div>
                                                        <div className='col-4 ps-0 mb-3 position-relative'>
                                                            <label>Max</label>
                                                            <Input
                                                                placeholder="Maximum"
                                                                name="Max"
                                                                value={config.min_max.max}
                                                                valueChange={(value, e) => {
                                                                    let config_obj = { ...config }
                                                                    config_obj.min_max.max = value;
                                                                    setConfig(config_obj)
                                                                }}
                                                                type="number"
                                                            />
                                                        </div>
                                                        <div className='col-4 mb-3 ps-0 position-relative'>
                                                            <label>Default</label>
                                                            <Input
                                                                placeholder="Maximum"
                                                                name="Max"
                                                                value={config.min_max.default}
                                                                valueChange={(value, e) => {
                                                                    let config_obj = { ...config }
                                                                    config_obj.min_max.default = value;
                                                                    setConfig(config_obj)
                                                                }}
                                                                type="number"
                                                            />
                                                        </div>
                                                        <div className='col-12 d-flex align-items-center'>
                                                            <label className='pe-2'>Postfix enabled</label>
                                                            <Input type="checkbox"
                                                                value={config.min_max.postfix_enabled}
                                                                valueChange={(value, e) => {
                                                                    let config_obj = { ...config }
                                                                    config_obj.min_max.postfix_enabled = !config_obj.min_max.postfix_enabled;
                                                                    setConfig(config_obj)
                                                                }} />
                                                        </div>
                                                        <div className='col-4 mb-3 position-relative'>

                                                            <Input
                                                                placeholder="Singular postfix"
                                                                name="Max"
                                                                value={config.min_max.singular_postfix}
                                                                valueChange={(value, e) => {
                                                                    let config_obj = { ...config }
                                                                    config_obj.min_max.singular_postfix = value;
                                                                    setConfig(config_obj)
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className='col-4 mb-3 ps-0  position-relative'>

                                                            <Input
                                                                placeholder="Plural postfix"
                                                                name="Max"
                                                                value={config.min_max.plural_postfix}
                                                                valueChange={(value, e) => {
                                                                    let config_obj = { ...config }
                                                                    config_obj.min_max.plural_postfix = value;
                                                                    setConfig(config_obj)
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                        :
                                        type?.value === 3 ?
                                            null
                                            :
                                            null
                            }
                            <div className="row pt-4 mt-2">
                                <div className="col">
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn name="Cancel" className={`${styles.e_cancel} e-cancel`} cancel="modal" />
                                        {
                                            question && weightage !== null && weightage <= 100 ?
                                                <PrimaryBtn name="Save question" className="e-modal-submit" handleClick={() => editQuestion()} />
                                                :
                                                <PrimaryBtn name="Save question" className={`${styles.e_disabled}`} />

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    error !== null ?
                        <Toast data={error} type="error" id="1" />
                        :
                        null
                }
                {
                    success !== null ?
                        <Toast data={success} type="success" id="2" />
                        :
                        null
                }
            </div>
        </>
    );
}
