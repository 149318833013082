/*
 *   File : _addQuestion.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add question modal
 *   Integrations : 17-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input } from '../Input';
import { SecondaryBtn, PrimaryBtn } from '../Cta';
import { Toast } from '../Note';

/*import styles*/
import styles from '../../Styles/Components/fund-class.module.scss';
import SelectBox from '../Dropdown/select-box';

/* import assets */
import AddNew from '../../Assets/Images/DataFormating/add-new.svg';
import DeleteIcon from "../../Assets/Images/Cta/delete-topic.svg";

/*import services */
import service from '../../Services/service';


export default function _AddQuestion(props) {

	const [question, setQuestion] = useState("");
	const [weightage, setWeightage] = useState(null);
	const [type, setType] = useState({});
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [description, setDescription] = useState("");
	const [loader, setLoader] = useState(false);
	const [questionOptions, setQuestionOptions] = useState(props.question_options);
	let [answersArrMCQ, setAnswersArrMCQ] = useState([{ answer: "", score: null, }]);
	const [config, setConfig] = useState({
		"min_max": { "min": "", "max": "", "default": '' },
		"postfix_enabled": false, "singular_postfix": "", "plural_postfix": ""
	})


	useEffect(() => {
		if (props.question_options.length > 0) {
			setQuestionOptions(props.question_options);
			setType(props.question_options[0])
		}
	}, [props.question_options])


	useEffect(() => {
		setTimeout(() => {
			setError(null)
		}, 3000);
	}, [error]);

	useEffect(() => {
		setTimeout(() => {
			setSuccess(null)
		}, 3000);
	}, [success]);



	/*----add Fields for mcq type ------*/
	const addFieldMCQ = () => {
		setAnswersArrMCQ([...answersArrMCQ, { answer: "", score: null, }])
	}


	/*----pushing respective values to mcq array having same name------*/
	const handleChange = (val, e, i) => {
		const { name } = e.target
		const list = [...answersArrMCQ]
		list[i][name] = val;
		setAnswersArrMCQ(list)
	}

	const _handleDeleteOption = (id) => {
		let updated_options = answersArrMCQ.filter((item, key) => key !== id);
		setAnswersArrMCQ(updated_options);
	};


	/* ----- add question api ----*/
	const _addQuestion = () => {
		setLoader(true);

		let url = '/quiz/upsert-question';

		let data = {
			category_id: props?.details?.category_id,
			question: question,
			question_type: type.value,
			description: description,
			weight: Number(weightage),
			order: 1,
			config: type.value === 2 ? config : null,
			answers: type.value === 1 ? answersArrMCQ : []
		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setSuccess("Question added")
				setTimeout(() => {
					$('.btn-close').trigger('click');
				}, 1000);
				props.addedQuestion(true);
				setQuestion("");
				setWeightage("");
				setDescription("");
				setAnswersArrMCQ([{ answer: "", score: null, }]);
				setLoader(false);
			} else {
				setError(response.data.message);
				setLoader(false);
			}
		})
	}

	/*----   form validation ---*/
	const validateForm = () => {
		let valid = true;
		if (type.value === 1) {
			for (let index = 0; index < answersArrMCQ.length; index++) {
				const element = answersArrMCQ[index];
				if (element.answer === "" || element.score === null) valid = false;
			}
			if (question && weightage !== null && weightage <= 100 && valid) {
				return true;
			}
			else {
				return false;
			}
		}
		if (type.value === 2) {
			// for (let index = 0; index < answersArrYN.length; index++) {
			// 	const element = answersArrYN[index];
			// 	if (element.answer === "" || element.score === null) valid = false;
			// }
			if (question && weightage !== null && weightage <= 100 && config.min_max.min !== "" && config.min_max.max !== "" && config.min_max.default !== "" && config.min_max.singular_postfix !== "" && config.min_max.plural_postfix !== "") {
				return true;
			}
			else {
				return false;
			}
		}
		if (type.value === 3 || type.value === 4) {
			if (question && weightage !== null && weightage <= 100) {
				return true;
			}
			else {
				return false;
			}
		}
		return false;
	}

	return (
		<>
			<div className={`${styles.e_add_fund} modal fade e-manage-modal`}
				id="addFundClass" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
					<div className="modal-content p-0">
						<div className="modal-header p-4">
							<h6> Add question</h6>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									setQuestion("");
									setWeightage("");
									setDescription("");
									setAnswersArrMCQ([{ answer: "", score: null, }]);
								}}
							>
							</button>
						</div>
						<div className="modal-body container px-4 pt-0">
							<div className="row">
								<div className="col-lg-10 mb-3">
									<label>Question*</label>
									<Input
										placeholder="What if"
										value={question}
										valueChange={(value) => setQuestion(value)}
										type="text"
									/>
								</div>
								<div className='col-lg-2 mb-3 ps-0 position-relative'>
									<label>Weightage*</label>
									<Input
										suffix="%"
										min="0"
										step="1"
										className={styles.e_input_box}
										placeholder="0"
										value={parseFloat(weightage)}
										type="number"
										valueChange={(value) => {
											if (value.length <= 3) {
												setWeightage(parseFloat(value))
											}

										}}
									/>
								</div>

							</div>
							<div className="row ">
								<div className="col-lg-12 mb-3">
									<label>Type*</label>
									<SelectBox
										value={type}
										placeholder="Fill"
										isSearchable={false}
										options={questionOptions}
										selectChange={(value) => setType(value)}
									/>
								</div>
							</div>
							{
								type?.value === 1 ?
									<div className="row ms-1 ">
										<p className="ps-0 mt-4 mb-1">Choices</p>

										{
											answersArrMCQ?.map((item, key) => (
												<>
													<div className='ps-0 d-flex justify-content-between mt-1'>

														<h6 className='ps-0 mb-2 mt-1'>Option {key + 1}</h6>
														<img src={DeleteIcon}
															width={16}
															height={16}
															className='object-fit-contain cursor-pointer'
															alt="delete icon"
															onClick={() => _handleDeleteOption(key)} />
													</div>

													<div className="row px-0">
														<div className="col-lg-10 mb-3">
															<label>Answer</label>
															<Input
																placeholder="Answer"
																name="answer"
																value={item.answer}
																valueChange={(value, e) => handleChange(value, e, key)}
																type="text"
															/>
														</div>
														<div className='col-lg-2 mb-3 ps-0 pe-0 position-relative'>
															<label>Score</label>
															<Input
																placeholder="0"
																name="score"
																min="0"
																step="1"
																value={parseFloat(item.score)}
																valueChange={(value, e) => handleChange(parseFloat(value), e, key)}
																type="number"
															/>
														</div>
													</div>
												</>
											))
										}
										<div className={`${styles.e_add_new_quiz_sec} col-lg-2 ps-0`} >
											<button className={styles.e_add_new_answer} onClick={() => addFieldMCQ()}>
												<span className="pe-2" >
													<img src={AddNew} alt="" />
												</span>
												{answersArrMCQ.length === 0 ? "Add options" : "Add New"}

											</button>
										</div>
									</div>
									:
									type?.value === 2 ?
										<>
											<div className="row ms-1">
												<p className="ps-0 mt-4 mb-1">Config</p>

												<div className="row px-0">
													<div className="col-lg-4 mb-3">
														<label>Min</label>
														<Input
															placeholder="Minimum"
															name="Min"
															value={config.min_max.min}
															valueChange={(value, e) => {
																let config_obj = { ...config }
																config_obj.min_max.min = value;
																setConfig(config_obj)
															}}
															type="numbers"
														/>
													</div>
													<div className='col-lg-4 mb-3 position-relative'>
														<label>Max</label>
														<Input
															placeholder="Maximum"
															name="Max"
															value={config.min_max.max}
															valueChange={(value, e) => {
																let config_obj = { ...config }
																config_obj.min_max.max = value;
																setConfig(config_obj)
															}}
															type="number"
														/>
													</div>
													<div className='col-lg-4 mb-3 ps-0 pe-0 position-relative'>
														<label>Default</label>
														<Input
															placeholder="Maximum"
															name="Max"
															value={config.min_max.default}
															valueChange={(value, e) => {
																let config_obj = { ...config }
																config_obj.min_max.default = value;
																setConfig(config_obj)
															}}
															type="number"
														/>
													</div>
													<div className='col-12 d-flex align-items-start'>
														<label className='pe-2 pt-1 pb-0'>Postfix enabled</label>
														<Input type="checkbox"
															value={config.min_max.postfix_enabled}
															valueChange={(value, e) => {
																let config_obj = { ...config }
																config_obj.min_max.postfix_enabled = !config_obj.min_max.postfix_enabled;
																setConfig(config_obj)
															}} />
													</div>
													<div className='col-lg-4 mb-3 position-relative'>

														<Input
															placeholder="Singular postfix"
															name="Max"
															value={config.min_max.singular_postfix}
															valueChange={(value, e) => {
																let config_obj = { ...config }
																config_obj.min_max.singular_postfix = value;
																setConfig(config_obj)
															}}
															type="text"
														/>
													</div>
													<div className='col-lg-4 mb-3 ps-0 pe-0 position-relative'>

														<Input
															placeholder="Plural postfix"
															name="Max"
															value={config.min_max.plural_postfix}
															valueChange={(value, e) => {
																let config_obj = { ...config }
																config_obj.min_max.plural_postfix = value;
																setConfig(config_obj)
															}}
															type="text"
														/>
													</div>
												</div>


											</div>
										</>
										:
										null
							}
							<div className="row pt-4 mt-4 pb-2">
								<div className="col-lg-12">
									<div className="d-flex justify-content-end">
										<SecondaryBtn
											name="Cancel"
											className={`${styles.e_cancel} e-cancel`}
											cancel="modal"
											handleClick={() => {
												setQuestion("");
												setWeightage("");
												setDescription("");
												setAnswersArrMCQ([{ answer: "", score: null, }]);
											}}
										/>
										{
											validateForm() ?
												<PrimaryBtn
													name="Save question"
													className={loader === true ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
													handleClick={() => _addQuestion()}
												/>
												:
												<PrimaryBtn
													name="Save question"
													className={`${styles.e_disabled}`}
												/>
										}

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				{
					error ?
						<Toast
							data={error}
							format={1}
							type="fail"
							id="1"
						/>
						:
						null
				}
				{
					success ?
						<Toast
							data={success}
							format={1}
							id="2"
							type="success"
						/>
						:
						null
				}
			</div>
		</>
	);
}
