/*
 *   File : members.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Selected family members list 
 *   Integrations : bootstrap
 *   Version : v1.0
 *   Created : 10-05-2024
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";

// Component
import { PrimaryBtn } from "../../../Components/Cta";
import { EmptyNote } from "../../../Components/Note";
import Datatable from "../../../Components/DataFormating/data-table";
import { AddFamilyMember } from "../../../Components/Modal";
import ConfirmUnlinkFamilyMember from "../../../Components/Modal/confirm-unlink-family-member";

function FamilyMembers(props) {

    const navigate = useNavigate();

    const [familyData, setFamilyData] = useState(props.data);
    const [familyID, setFamilyID] = useState(0);
    const [familyDetailID, setFamilyDetailID] = useState(0);

    const [showAddFamilyMember, setShowAddFamilyMember] = useState(false);
    const [showConfirmMemberUnlinkModal, setShowConfirmMemberUnlinkModal] = useState(false);

    // Setting data table column data
    const MEMBER_LIST_COLUMN_DATA = [
        {
            name: 'Customer ID',
            selector: (row) => row.ifsh_customer_id ? row.ifsh_customer_id : '--',
            sortable: true,
            left: true,
            minWidth: "140px",
            defaultSortAsc: false,
        },
        {
            name: 'Name',
            selector: (row) => row.name ? row.name : '--',
            sortable: true,
            left: true,
            minWidth: "170px",
            defaultSortAsc: false,
            cell: row => (
                <span className="e-link link-offset-1 d-block py-3 break-word"
                    data-tag="allowRowEvents"
                    onClick={() => {
                        navigate(`/user-detail?id=${row.customer_id}`)
                    }}>
                    {
                        row.name ? row.name : "--"
                    }
                </span>
            )
        },
        {
            name: 'Individual AUM',
            selector: row => row.individual_aum ? row.individual_aum : 0,
            sortable: true,
            left: true,
            minWidth: '150px',
            cell: row => <div>
                {
                    row.individual_aum_text ? row.individual_aum_text : 0
                }
            </div>

        },
        {
            name: 'Invested value',
            selector: row => row.invested_amount ? row.invested_amount : 0,
            sortable: true,
            left: true,
            minWidth: '150px',
            cell: row => <div>
                {
                    row.invested_amount_text ? row.invested_amount_text : 0
                }
            </div>

        },
        {
            name: 'Current value',
            selector: row => row.current_amount ? row.current_amount : 0,
            sortable: true,
            left: true,
            minWidth: '150px',
            cell: row => <div>
                {
                    row.current_amount_text ? row.current_amount_text : 0
                }
            </div>
        },
        {
            name: 'P&L',
            selector: row => row.current_amount - row.invested_amount,
            sortable: true,
            left: true,
            minWidth: '150px',
            cell: row => <div>
                {
                    "₹" + Number((row.current_amount - row.invested_amount).toFixed(2)).toLocaleString("en-IN")
                }
            </div>

        },
        {
            name: 'Connection status',
            selector: 'connection_status',
            sortable: false,
            left: true,
            minWidth: '170px',
            cell: row => <>
                {
                    row.status ?
                        <div className="d-inline-flex">
                            <div className="col-lg-10">
                                <div data-tag="allowRowEvents"
                                    className={`${row.status === "Active" ? "e-status-badge-success" : "e-status-badge-fail"} e-status-badge `}>
                                    {
                                        row.status
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        "--"
                }
            </>
        },
        {
            name: '',
            selector: "link",
            width: "150px",
            cell: row => (
                <span className="ms-3 e-notify fw-bolder e-font-14"
                    onClick={() => _handleUnlink(row.family_detail_id)}>
                    Unlink
                </span>
            ),
        },

    ];

    useEffect(() => {
        setFamilyID(props.familyID);
    }, [props.familyID]);

    useEffect(() => {
        setFamilyData(props.data);
    }, [props.data]);

    // handle open add family member modal
    useEffect(() => {
        if (showAddFamilyMember) {
            new Modal(document.querySelector("#add-family-member-modal")).show();
        }
    }, [showAddFamilyMember]);

    // handle open add confirm unlink family member modal
    useEffect(() => {
        if (showConfirmMemberUnlinkModal) {
            new Modal(document.querySelector("#confirm-unlink-member-modal")).show();
        }
    }, [showConfirmMemberUnlinkModal]);

    // handle remove member
    const _handleUnlink = (family_detail_id) => {
        setShowConfirmMemberUnlinkModal(true);
        setFamilyDetailID(family_detail_id);
    };

    // handle close modal
    const _handleCloseModal = () => {
        setShowAddFamilyMember(false);
        props.handleMemberAddSuccess();
    };

    // handle add member success
    const _handleOnMemberAddSuccess = () => {
        setShowAddFamilyMember(false);
        props.handleMemberAddSuccess();
    };

    // handle remove family member
    const _handleUnlinkMemberSuccess = () => {
        setShowConfirmMemberUnlinkModal(false);
        props.handleMemberUnlinkSuccess();
    };

    

    return (
        <div className="col">
            <div className="e-dashboard-card border-0 p-0">
                <div className="row">
                    <div className="col p-4 ps-3 mx-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="e-section-title mb-0">Family members list</h6>
                            <PrimaryBtn name="Add a member"
                                handleClick={() => setShowAddFamilyMember(true)} />
                        </div>
                    </div>
                    <div className="col-12 pb-5">
                        {
                            familyData.length === 0 ?
                                <EmptyNote note="There are data to show" />
                                :
                                <div className="col-lg-12 px-0">
                                    <Datatable
                                        pagination={true}
                                        columns={MEMBER_LIST_COLUMN_DATA}
                                        data={familyData}
                                        perPage={30}
                                        selectableRows={false}
                                    />
                                </div>
                        }
                    </div>
                </div>

                {/* Modals */}
                {
                    showAddFamilyMember &&
                    <AddFamilyMember familyID={familyID}
                        onCloseModal={_handleCloseModal}
                        onSuccess={_handleOnMemberAddSuccess} />
                }

                {
                    showConfirmMemberUnlinkModal &&
                    <ConfirmUnlinkFamilyMember familyDetailID={familyDetailID}
                        handleSuccess={_handleUnlinkMemberSuccess}
                        onCloseModal={() => setShowConfirmMemberUnlinkModal(false)} />
                }

            </div>
        </div>
    );
};

export default FamilyMembers;