import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

// ChartJS.defaults.global.elements.line.fill = false;

const LineChartMarker = (props) => {
    const options = {
        // plugins: {
        //     legend: {
        //         labels: {
        //             usePointStyle: true
        //         }
        //     }
        // },
        plugins: {
            legend: {
                position: 'top'
            }
        },
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return Math.abs(value) > 999 ? "₹ " + Math.sign(value) * ((Math.abs(value) / 1000).toFixed(1)) + 'k' : value
                    }
                }
            }
        }


    };

    const labels = props.categories;

    const images = props.images

    const skipped = (ctx, value) =>
        ctx.p0.parsed.y === 0 || ctx.p1.parsed.y === 0 ? undefined : value;
    const down = (ctx, value) => (ctx.p0.parsed.y === "" ? value : undefined);


    const data = {
        labels,

        datasets: [
            {
                fill: false,
                label: '',
                data: props.target,
                borderColor: '#106e88',
                borderWidth: 1,
                pointStyle: false,
                pointRadius: 0,
                segment: {
                    borderColor: (ctx) =>
                        skipped(ctx, "#106e88") || down(ctx, "#106e88"),
                    borderDash: (ctx) => skipped(ctx, [6, 6])
                },
                spanGaps: true,
            },
            {
                fill: true,
                label: '',
                data: props.performace,
                borderColor: '#4F7097',
                backgroundColor: 'rgba(63, 86, 216, 0.22)',
                borderWidth: 1,
                pointStyle: function (context) {
                    const index = context.dataIndex;
                    if (index > 0) {



                        // let imageNode = document.createElement("IMG")
                        // imageNode.src = images[index].src;
                        // imageNode.width = 28;
                        // imageNode.height = 28;
                        // imageNode.style.marginBottom = '10px'
                        // imageNode.style.position = "absolute"
                        // imageNode.style.top = "-60px"
                        // return imageNode

                        // let mainNode = document.createElement("DIV")
                        // mainNode.appendChild(imageNode)
                        // mainNode.appendChild(divNode)
                        // console.log(mainNode)
                        // return mainNode
                        // getImageLabel(index)

                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = 24;
                        canvas.height = 24;
                        const image = document.createElement("IMG");
                        image.src = images[index].src;

                        image.onload = function () {
                            ctx.drawImage(image, 0, 0, 24, 24);
                            // ctx.strokeStyle = 'black';
                            // ctx.lineWidth = 2;
                            // ctx.strokeRect(0, 0, 28, 28);

                            canvas.style.transform = 'translate(-50%, -50%)';
                        };
                        canvas.style.position = 'absolute';
                        canvas.style.top = '-30%';
                        // canvas.style.left = '50%';
                        canvas.style.transform = 'translate(-50%, -50%)';
                        return canvas;

                    }
                }
            },
        ],

    };
    return (
        <div className='position-relative'>
            <Line options={options} data={data}
                width="100%"
                height="100%" />
        </div>
    );
}
export default LineChartMarker
