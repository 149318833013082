/*
 *   File : addTopic.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add topic Success Modal
 *   Integrations : 14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React from 'react';
import $ from 'jquery'

/*import componets*/
import { Toast } from '../Note';
import { Modal } from 'bootstrap';
import { EditTopic } from './index';

/*import styles*/
import styles from '../../Styles/Components/fund-class.module.scss';

/* import assets */
import Edit from '../../Assets/Images/Cta/edit-topic.svg';

/*import services */
import service from '../../Services/service';

export default class AddTopic extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			topic: "",
			error: "",
			topicData: [],
			status: "",
			description: "",
			success: ""
		};
	}

	componentDidMount() {
		this._getTopic()
		if (this.state.status) {
			this._getTopic()
		}
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setTimeout(() => {
			this.setState({
				error: null
			})
		}, 3000)
		setTimeout(() => {
			this.setState({
				success: null
			})
		}, 3000)
	}

	/* ---- add topic api ---*/
	_addTopic = () => {
		let url = '/quiz/add-category';
		let data = {
			category: this.state.topic,
			description: this.state.topic_desc,
		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				this.setState({ success: 'Category added successfully' })
				setTimeout(() => {
					$('.btn-close').trigger('click');
				}, 1000)
				this._getTopic();
				this.setState({ topic: "", topic_desc: "" })
			} else {
				this.setState({ error: response.data.message })

			}
		})
	}

	/*---- get topics api ---*/
	_getTopic = () => {
		let url = '/quiz/get-category';

		service(false, url).then((response) => {
			if (response.status_code === 200) {
				this?.props?.topics(response.data);
				this.setState({ topicData: response.data })
			} else {
				this.setState({ error: response.data.message })
			}
		})
	}

	/* ---- opening modal edit topic and passing the object to the modal ---*/
	_editData = (item) => {
		this.setState({ edit: item })
		var myModal = new Modal(document.getElementById("editTopic"), {})
		myModal.show()
	}
	/*--- delete topic api ---*/

	_deleteData = (item) => {
		let url = '/quiz/remove-category';
		let data = JSON.stringify({
			category_id: item.category_id,
		})
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				this._getTopic();
				this.setState({ success: 'Category removed' })
			} else {
				this.setState({ error: response.data.message })
			}
		})
	}
	/*---order api---*/
	_saveOrder = () => {
		let url = '/quiz/category-order';
		let request = {
			category_id: this.state.orderIdArr,
		}
		service(true, url, request).then((response) => {
			if (response.status_code === 200) {

				this.setState({ success: 'Categories sorted' })
				setTimeout(() => {
					$('.btn-close').trigger('click');
					this._getTopic();
				}, 1000)
			} else {
				this.setState({ error: response.data.message })
			}
		})
	}

	_dragStart(e) {
		this.dragged = e.currentTarget;
	}


	_dragEnd(e) {
		this.dragged.style.display = 'block';
		this.dragged.style.marginTop = '20px';

		e.target.classList.remove("drag-up");
		this?.over?.classList.remove("drag-up");

		e.target.classList.remove("drag-down");
		this?.over?.classList.remove("drag-down");

		var data = this?.state?.topicData;
		var from = Number(this.dragged.dataset.id);

		var to = Number(this.over.dataset.id);
		data.splice(to, 0, data.splice(from, 1)[0]);

		//set newIndex to judge direction of drag and drop
		data = data.map((doc, index) => {
			doc.newIndex = index + 1;
			return doc;
		})

		this.setState({ data: data });
		let arr = [];
		for (let i = 0; i < data.length; i++) {
			arr.push(data[i]['category_id'])
		}
		this.setState({ orderIdArr: arr })
	}

	_dragOver(e) {
		e.preventDefault();
		this.dragged.style.display = "none";
		if (e.target.tagName !== "LI") {
			return;
		}


		const dgIndex = JSON.parse(this.dragged.dataset.item).newIndex;
		const taIndex = JSON.parse(e.target.dataset.item).newIndex;
		const animateName = dgIndex > taIndex ? "drag-up" : "drag-down";


		if (this.over && e.target.dataset.item !== this.over.dataset.item) {
			this.over.classList.remove("drag-up", "drag-down");
		}

		if (!e.target.classList.contains(animateName)) {
			e.target.classList.add(animateName);
			this.over = e.target;
		}
	}

	render() {
		return (
			<div className={`modal fade`}
				id="addTopic"
				tabIndex="-1"
				aria-labelledby="success"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header pt-1 px-2">
							<h6 className='e-font-16 e-font-weight-700 e-font-manrope'>Manage topics</h6>
							<button type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => this.setState({ topic: "", topic_desc: "" })}></button>
						</div>
						<div className="modal-body py-0 pb-2 px-2">
							<p className="e-font-weight-600 e-font-14 e-font-manrope">Added topics</p>
							<div className='d-flex flex-column gap-2'>
								{
									this.state.topicData?.map((data, i) => (
										<div className='d-flex justify-content-between e-bg-body-bg px-4 py-3 e-border-radius-8 border-1px-accordion_body_bg'>
											<div className="col-10 col-sm-11 text-start">
												<p className={`ps-0 mb-0 e-font-weight-600 e-font-14 e-font-manrope mt-0`}>
													{data.category}
												</p>
												<span className={`e-font-weight-400 e-font-14 e-font-manrope`}>
													{data.description ? data.description : '--'}
												</span>
											</div>
											<div className="col-2 col-sm-1">
												<div className="text-end">
													<span >
														<img src={Edit}
															alt="edit"
															className={"cursor-pointer"}
															onClick={() => { this._editData(data) }} />
													</span>
												</div>
											</div>
										</div>
									))
								}
							</div>
						</div>
					</div>
				</div>
				{
					this.state.error ?
						<Toast id="1" data={this.state.error} format={1} type="fail" />
						:
						null
				}
				{
					this.state.success ?
						<Toast id="2" data={this.state.success} format={1} type="success" />
						:
						null
				}
				<EditTopic data={this.state.edit} edit={() => this._getTopic()} />
			</div >
		);
	}
}
