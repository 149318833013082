/*
 *   File : detail.js
 *   Author URI : https://evoqins.com
 *   Description : Selected family detail page
 *   Integrations : 
 *   Version : v1.0
 *   Created : 10-05-2024
 */

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Component
import { UserDetail } from "../../Components/Cards";
import { Tab } from "../../Components/DataFormating";
import { Loader } from "../../Components/Loader";
import { FamilyMembers, FamilyOrders } from "./Helper";
import service from "../../Services/service";

// Custom data
const FAMILY_TABS = ['Family members', 'Orders'];


function FamilyDetail() {

    const location = useLocation();
    const navigate = useNavigate();

    const [familyDetail, setFamilyDetail] = useState({});
    const [tabIndex, setTabindex] = useState(1);
    const [loading, setLoading] = useState(true);

    const [familyID, setFamilyID] = useState(0);

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // Setting family ID when user reloads the page
        window.addEventListener("load", () => {
            setFamilyID(() => location.state !== null ? location.state.family_id : 0);
        });

    }, []);

    // Setting family id from location state - when navigating from family list page
    useEffect(() => {
        if (location.state && location.state.family_id) {
            setFamilyID(location.state.family_id);
        }
    }, [location.state]);

    // hanlde call family detail api
    useEffect(() => {
        if (familyID) {
            setLoading(true);
            _getFamilyDetail();
        }
    }, [familyID]);

    // handle update member list when new member added and removed
    const _handleUpdateMemberList = (status) => {
        if (status === "UNLINK" && familyDetail.members.length === 1) {
            navigate("/family-users");
        } else {
            setLoading(true);
            _getFamilyDetail();
        }
    };

    // ========================//
    //======= API CALLS =======//

    // API - get family details
    const _getFamilyDetail = () => {

        let is_post = true;
        let url = '/family/detail';
        let data = {
            "family_id": familyID
        }

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setFamilyDetail(response.data);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                setLoading(false);
            } else {
                toast.dismiss();
                toast.error(response.data.message);
                navigate(-1);
            }

        });

    };

    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <>
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <span className="e-breadcrumb-link">
                                    <Link to="/family-users">Family users /</Link>
                                    {familyDetail.summary.name}
                                </span>
                            </div>
                        </div>
                        <div className="row">

                            {/*-----------card displaying basic details------------------*/}
                            <div className="col-lg-12">
                                <UserDetail
                                    data={familyDetail.summary}
                                    type={2}
                                />
                            </div>

                            {/*----------- tabs ------------------*/}
                            <div className="col-lg-12 mt-4 mb-4">
                                <Tab data={FAMILY_TABS}
                                    setTab={(tab_id) => setTabindex(tab_id)}
                                    currentIndex={tabIndex} />
                            </div>
                            {
                                tabIndex == 1 ?
                                    <FamilyMembers familyID={familyID}
                                        data={familyDetail.members}
                                        handleMemberAddSuccess={_handleUpdateMemberList}
                                        handleMemberUnlinkSuccess={() => _handleUpdateMemberList("UNLINK")} />
                                    :
                                    <FamilyOrders familyID={familyID} />
                            }

                        </div>
                    </>
            }
        </>
    );
}

export default FamilyDetail;