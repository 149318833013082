/*
 *   File : footer.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Footer
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-11-2023
 */

/*import packages*/
import React from 'react';

/*import style*/
import styles from '../../Styles/Components/footer.module.scss';

/*import config*/
import devconfig from '../../Config/config';

export default function Footer() {

	const footer = devconfig.footer;

	return (
		<>
			<div className={`e-padding-lr-56px position-relative mb-3 mt-auto`}>
				<div className="d-flex justify-content-between align-items-center ">
					<div className='d-flex align-items-center gap-1 ms-1'>
						<img src={footer.logo}
							width={24}
							height={24}
							alt="index fund logo" />
						<span className='e-text-light-grey e-font-manrope e-font-weight-400  e-font-12'>{footer.copy_right}</span>
					</div>

					{/* Will be enabled in future */}
					{/* map links */}
					{/* <div className='d-flex gap-5'>
						{
							footer.links.map((item, index) => (
								<a href={item.link}
									id='footer-link'
									className='text-decoration-none e-text-dark-grey e-font-manrope e-font-weight-400 e-font-14 line-height-20px'
									key={index}>{item.name}</a>
							))
						}
					</div> */}
				</div>
			</div>
		</>
	)
}

