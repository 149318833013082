/*
 *   File : funds.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Funds file for Manage funds container;
 *   Integrations :28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
*/

/*import packages*/
import { useState, useEffect } from 'react';



/*import components*/
import { Accordion } from '../../Components/DataFormating/index';
import { Loader } from '../../Components/Loader';
import { FundClass } from '../../Components/Modal';
import { Modal } from 'bootstrap';
import { PrimaryBtn, SecondaryBtn } from '../../Components/Cta';
// import { PrimaryBtn } from '../../components/cta';
import { Toast } from '../../Components/Note';

/*import assets*/
import AddNew from '../../Assets/Images/DataFormating/add-new.svg';
import Rebalance from '../../Assets/Images/Cta/rebalance.svg';

/*import services */
import service from '../../Services/service';



const Funds = (props) => {
	const [fundData, setFundData] = useState({});
	const [fundLoader, setFundLoader] = useState(true);
	const [syncLoader, setSyncLoader] = useState(false);
	//eslint-disable-next-line
	const [updated, setUpdated] = useState("");
	// const [debtOptions, setDebtOptions] = useState([]);
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	// const [syncLoader, setSyncLoader] = useState(false);
	const [addFund, setAddFund] = useState(false);
	const [editedData, setEditedData] = useState({});
	const [allocation, setAllocation] = useState(null);
	const [riskData, setRiskData] = useState([]);
	const [riskId, setRiskId] = useState(null);

	// const [investmentId, setInvestmentId] = useState(2)

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, []);

	useEffect(() => {

		_getInvestmentStyles()
		//eslint-disable-next-line
	}, []);

	useEffect(() => {

		const temp_allocation_length_arr = []

		//eslint-disable-next-line
		if (Object.keys(fundData).filter((item) => item === `${riskId}`).length > 0) {
			//eslint-disable-next-line
			Object.values(fundData[`${riskId}`]).map((item) => {
				item.map((fund) => {
					return (
						temp_allocation_length_arr.push(fund.allocation)
					);
				})
			});

			setAllocation(temp_allocation_length_arr.reduce((a, b) => a + b, 0));

		} else {
			setAllocation(0);
		}

	}, [riskId, fundData])

	useEffect(() => {
		if (addFund) {
			_openAddFundModal()
		}
	}, [addFund])

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error])

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success]);

	/* ---- get investment styles ---*/
	const _getInvestmentStyles = (loader = true) => {
		setFundLoader(loader);
		const url = '/fund/investment_styles'
		service(false, url).then((response) => {
			if (response.status_code === 200) {
				setRiskData(response.data);
				setRiskId(response.data[0].id);
				setFundLoader(false);
				_getFunds();
			}
			else {
				setFundLoader(false);
				setRiskData([]);
			}
		});

	}

	/* ---- get mutual funds ---*/
	const _getFunds = (loader = true) => {
		setFundLoader(loader);
		const url = '/fund/list'
		service(false, url).then((response) => {
			if (response.status_code === 200) {
				setFundData(response.data);
			}
			else {
				setFundData([]);
			}

			setFundLoader(false);
		});

	}

	// Sync data to calculate min investment
	const _syncData = () => {

		setSyncLoader(true);

		const url = '/fund/sync-data';
		const data = {
			"investment_style_id": riskId
		};

		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setSuccess(response.message);
			}
			else {
				setError(response.message);
			}

			setSyncLoader(false);
		});

	};


	/* ------ open add fund Modal -----*/
	const _openAddFundModal = () => {
		var myModal = new Modal(document.getElementById("addFundClass"), {});
		myModal.show();
	}

	return (
		<>

			<div className="row pb-5 position-relative">
				<div className="col-lg-12">
					<h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
						Manage funds
					</h3>
					{
						fundLoader ?
							<Loader />
							:
							<div className="card e-dashboard-card e-faq-card  border-0 p-4 ">
								{/* -------------- baskets ---------- */}
								<div className='row mb-3'>

									<div className="col-lg-12 d-flex mt-2">
										{
											riskData.map((item) => (
												<div onClick={() => setRiskId(item.id)} className={item.id === riskId ? "e-btn-active-wrapper" : "e-btn-wrapper"}>
													<span className="e-btn-active">{item.name}</span>
												</div>
											))
										}
									</div>


									<div className='col-lg-4 col-4 d-flex justify-content-start align-items-center e-equity mt-3'>
										<p className='px-0 mb-0'>Total allocation:
											<span className='ps-2'>{allocation !== null ? allocation : 0}%</span>
										</p>
										{/* <img src={Help} alt="" /> */}
									</div>
								</div>

								{/*----------- list of funds added ------------------*/}

								{
									fundData && fundData[`${riskId}`] && fundData[`${riskId}`] ?
										<div div className="col-lg-12 ">
											<Accordion
												data={fundData[`${riskId}`]}
												updatedFund={(data) => {
													_getFunds();
													// setUpdated(data);
												}}
												setEditedData={(item) => {
													setEditedData(item);
												}}
												close={() => {
													_getFunds(false);
												}}
											/>

										</div>
										:
										null
								}
								<div className="row mx-0">
									<div className="e-add-sec col-lg-12 " onClick={() => { setAddFund(true); setEditedData({}) }}>
										<h6><span className="pe-3"><img src={AddNew} alt="add" /></span>Add New</h6>
									</div>
								</div>

								{/* sync data button */}

								<div className='row mt-auto'>
									<div className='col-lg-5 offset-lg-7 d-flex justify-content-end'>
										{/* Will enabled in future */}
										{/* <SecondaryBtn
									name="Trigger balance"
									className="e-submit py-2 px-3"
									img={<img src={Rebalance} alt="" />}
									handleClick={() => {
										// _syncData()
									}}
								/> */}
										{/* <PrimaryBtn
									name="Save"
									className={syncLoader ? "e-btn-loader " : "ms-2"}
									handleClick={() => {
										_syncData();
									}} /> */}
									</div>

								</div>
							</div>
					}
				</div>
			</div >
			{/* success toast */}
			{
				success !== null ?
					<Toast data={success} type="success" id="2" />
					:
					null
			}

			{
				error !== null ?
					<Toast data={error} type="error" id="1" />
					:
					null
			}

			{/*----------- Modal to add fund ------------------*/}

			<FundClass
				totalAllocation={allocation}
				investmentStyle={riskId}
				editedData={editedData}
				close={() => {
					setAddFund(false);
					setEditedData({});
				}}
				addedFund={(data) => { _getFunds(); setUpdated(data) }}
			/>


		</>
	)
}

export default Funds;