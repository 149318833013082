/*
 *   File : basket.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Basket file for Manage baskets container;
 *   Integrations :28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
*/

/*import packages*/
import { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';

/*import components*/
import { Accordion, FundsTab, } from '../../Components/DataFormating/index';
import { Loader } from '../../Components/Loader';
import { PrimaryBtn, SecondaryBtn } from '../../Components/Cta';
// import { PrimaryBtn } from '../../components/cta';
import { Toast } from '../../Components/Note';

/*import assets*/
import Rebalance from '../../Assets/Images/Cta/rebalance.svg';

/*import services */
import service from '../../Services/service';
import { FundClass } from '../../Components/Modal';



const Basket = (props) => {
    const [fundData, setFundData] = useState({});
    const [fundLoader, setFundLoader] = useState(true);
    const [syncLoader, setSyncLoader] = useState(false);
    //eslint-disable-next-line
    const [updated, setUpdated] = useState("");
    // const [debtOptions, setDebtOptions] = useState([]);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    // const [syncLoader, setSyncLoader] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [basketData, setBasketData] = useState([]);
    const [basketId, setBasketId] = useState(null);
    const [addFund, setAddFund] = useState(false);
    const [totalAllocation, setTotalAllocation] = useState(0);

    // const [investmentId, setInvestmentId] = useState(2)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {

        _getBasketList()
        //eslint-disable-next-line
    }, []);

    useEffect(() => {


        if (basketId) {
            let temp_allocation = [];
            Object.values(fundData).map((item) => {
                Object.values(item).map((fund) => {
                    fund.map(item => {
                        return temp_allocation.push(item);
                    })
                })
            });

            temp_allocation = temp_allocation.filter(item => item.basket_id == basketId).reduce((a, b) => a + b.allocation, 0)
            setTotalAllocation(temp_allocation);

        }

    }, [fundData, basketId]);

    useEffect(() => {
        if (addFund) {
            _openAddFundModal()
        }
    }, [addFund])

    // useEffect(() => {

    //     const temp_allocation_length_arr = []

    //     //eslint-disable-next-line
    //     if (Object.keys(fundData).filter((item) => item === `${basketId}`).length > 0) {

    //         Object.values(fundData[`${basketId}`]).map((item) => {
    //             item.map((fund) => {
    //                 return (
    //                     temp_allocation_length_arr.push(fund.allocation)
    //                 );
    //             })
    //         });

    //         setAllocation(temp_allocation_length_arr.reduce((a, b) => a + b, 0))

    //     } else {
    //         setAllocation(0);
    //     }

    // }, [basketId, fundData])

    useEffect(() => {
        if (Object.values(editedData).length > 0) {
            // var myModal = new Modal(document.getElementById("editAllocations"), {});
            // myModal.show();
        }
    }, [editedData])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error])

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        }
    }, [success]);


    /* ---- get mutual funds ---*/
    const _getBasketList = (loader = true) => {
        setFundLoader(loader);
        const url = '/baskets/list'
        service(false, url).then((response) => {
            if (response.status_code === 200) {
                let basket_tabs = [...basketData]
                Object.values(response.data).map((item) => (
                    // eslint-disable-next-line
                    item.map((fund) => {
                        let basket_index = basket_tabs.findIndex((item) => item.id === fund.basket_id);
                        if (basket_index < 0) {
                            basket_tabs.push({
                                name: fund.name,
                                id: fund.basket_id
                            })
                        } else {
                            basket_tabs.splice(basket_index, 1);
                            basket_tabs.push({
                                name: fund.name,
                                id: fund.basket_id
                            })
                        }

                    })

                ));
                setBasketData(basket_tabs);
                if (loader === true) {
                    setBasketId(basket_tabs[0].id);
                }
                const resultObject = {};

                for (const basketId in response.data) {
                    const dataArray = response.data[basketId];
                    resultObject[basketId] = {};

                    dataArray.forEach(item => {
                        const assetClass = item.asset_class;
                        if (!resultObject[basketId][assetClass]) {
                            resultObject[basketId][assetClass] = [];
                        }
                        resultObject[basketId][assetClass].push(item);
                    });
                }

                setFundData(resultObject);
                setFundLoader(false);
            }
            else {
                setFundLoader(false);
                setFundData([]);
            }
        });

    };


    // Sync data to calculate min investment
    const _syncData = () => {

        setSyncLoader(true);

        const url = '/fund/sync-data';
        const data = {
            "investment_style_id": 2
        };

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess(response.message);
            }
            else {
                setError(response.message);
            }

            setSyncLoader(false);
        });

    };


    /* ------ open add fund Modal -----*/
    const _openAddFundModal = () => {
        var myModal = new Modal(document.getElementById("addFundClass"), {});
        myModal.show();
    }


    return (
        <>

            <div className="row pb-5 position-relative">
                <div className="col-lg-12">
                    <h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
                        Manage baskets
                    </h3>
                    <div className="card e-dashboard-card e-faq-card  border-0 p-4 ">
                        <div className='col-12 mb-3'>
                            <FundsTab data={basketData}
                                funds={true}
                                index={basketId}
                                setTab={(tab_index) => {
                                    setBasketId(tab_index);
                                }} />
                        </div>

                        {/*----------- list of funds added ------------------*/}

                        {
                            fundLoader ?
                                <Loader />
                                :
                                <>

                                    {/* {

                                        fundData[basketId].map((fund) => {
                                            return (
                                                <div className={`e-basket-section mt-2`}>
                                                    <div className='d-flex align-items-center mb-2 ps-1'>{fund.asset_class}
                                                        <h6 className='mb-0 ps-2 e-allocation'> {fund.allocation}%</h6>
                                                        <span onClick={() => _openAddFundModal(fundData[basketId])} edit="true" className="ps-2 ">
                                                            <img src={require('../../Assets/Images/DataFormating/update.svg').default} alt="" />
                                                            <span className='e-update'>Update</span>
                                                        </span>
                                                    </div>

                                                    <div className={`e-basket-body row pt-3`}>

                                                        <div className={`col-lg-11`}>
                                                            <div className='d-flex'>
                                                                <div>{fund.fund_name}</div>
                                                                <span className='mb-0 ps-2 e-body-allocation'>{fund.fund_alias ? `(${fund.fund_alias})` : null}</span>
                                                            </div>
                                                            <h6 className='e-body-allocation'>Allocation: {fund.allocation}%</h6>

                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })

                                    } */}
                                    <div className="col-lg-12 ">
                                        <Accordion
                                            data={fundData[basketId]}
                                            basket={true}
                                            updatedFund={(data) => {
                                                _getBasketList(false);
                                                setUpdated(data);
                                            }}
                                            setEditedData={(item) => {
                                                setEditedData(item);
                                            }}
                                            close={() => {
                                                _getBasketList(false)
                                            }}
                                        />

                                    </div>
                                    <div className="row mx-0">
                                        <div className="e-add-sec col-lg-12 "
                                            onClick={() => { setAddFund(true); setEditedData({}) }}>
                                            <h6>
                                                <span className="pe-3"><img src={require('../../Assets/Images/DataFormating/add-new.svg').default} alt="add" />
                                                </span>
                                                Add New
                                            </h6>
                                        </div>
                                    </div>

                                </>
                        }

                        {/* sync data buttton */}
                        <div className='row mt-auto'>
                            <div className='col-lg-5 offset-lg-7 d-flex justify-content-end'>
                                {/* Will be enabled in future */}
                                {/* <SecondaryBtn
                                    name="Trigger balance"
                                    className="e-submit py-2 px-3"
                                    img={<img src={Rebalance} alt="" />}
                                    handleClick={() => {
                                        // _syncData()
                                    }}
                                /> */}
                                {/* <PrimaryBtn
                                    name="Save"
                                    className={
                                        syncLoader ? "e-btn-loader "
                                            :
                                            "ms-2"
                                    }
                                    handleClick={() => {
                                        _syncData()
                                    }} /> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }

            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }


            {/*----------- Modal to add fund ------------------*/}

            <FundClass
                totalAllocation={totalAllocation}
                investmentStyle={basketId}
                editedData={editedData}
                basket={true}
                close={() => {
                    setAddFund(false);
                    setEditedData({});
                }}
                addedFund={(data) => { _getBasketList(false); setUpdated(data) }} />

        </>
    )
}

export default Basket;