/*
 *   File : changeAllocation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Change Allocation Success Modal
 *   Integrations :28-11-2023
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
// impo/rt $ from 'jquery'

/*import componets*/
import { Input } from '../Input/index';
import { SecondaryBtn, PrimaryBtn } from '../Cta/index';
import { Toast } from '../Note';

/*import styles*/
import styles from '../../Styles/Components/fund-class.module.scss';

/*import services */
// import devconfig from '../../config/config';
// import APIPost from '../../services/postApi';


export default function DeleteFunds(props) {

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedFunds, setSelectedFunds] = useState([]);
    const [selectedScheme, setSelectedScheme] = useState([])

    useEffect(() => {
        var myModalEl = document.getElementById(props.debt ? "deleteDebtAllocations" : "deleteAllocations")
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            props.close()
            setSelectedScheme([]);

            // $(".fade").removeClass("modal-backdrop")
            // $("body").removeAttr("style")
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.editedData && Object.keys(props.editedData).length > 0) {
            setSelectedFunds(props.editedData)
        }
    }, [props.editedData]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 4000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 4000);
        }
    }, [success])

    /*-----update allocation---- */
    // const updateAllocation = () => {
    //     setLoader(true);
    //     let url = devconfig.admin + '/fund/#-allocation'
    //     let data = JSON.stringify({
    //         "category_type": props.name,
    //         "allocation_id": id,
    //         "allocation": parseFloat(allocation)
    //     });
    //     APIPost(url, data).then((response) => {
    //         if (response.status_code === 200) {
    //             setSuccess("Allocation updated successfully");
    //             setLoader(false);
    //             setTimeout(() => {
    //                 $(props.debt ? "#deleteDebtAllocations" : "#deleteAllocations").trigger('click');
    //                 props.updatedAllocation(true);
    //             }, 1000)
    //         } else {
    //             setError(response.data.message);
    //             setLoader(false);
    //         }
    //     })
    // }

    return (
        <>
            <div className={`${styles.e_add_fund} modal fade`} id={props.debt ? "deleteDebtAllocations" : "deleteAllocations"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered`}>
                    <div className="modal-content p-0">
                        <div className="modal-header p-4 pb-3">
                            <h6>Delete fund</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>

                        <div className="modal-body p-4 pt-0">
                            <div className='row'>
                                <div className='col-7'>
                                    <p className='mb-0 e-font-14 e-font-weight-500'>Select funds</p>
                                </div>
                            </div>
                            {
                                selectedFunds.map((item, key) => {
                                    return (
                                        <div className={`${styles.e_fund} row mb-4 mt-4`}>
                                            <div className='col-auto col-sm-1'>
                                                <Input type="checkbox"
                                                    id="e-select"
                                                    checked={selectedScheme.indexOf(item.id) < 0 ? false : true}
                                                    valueChange={() => {
                                                        const temp = [...selectedScheme]
                                                        const scheme_index = temp.findIndex((allocation_id) => allocation_id === item.id);
                                                        if (scheme_index === -1) {
                                                            temp.push(item.id)
                                                        } else {
                                                            temp.splice(scheme_index, 1)
                                                        }
                                                        setSelectedScheme(temp)
                                                    }} />
                                            </div>
                                            <div className='col-10 col-sm-10 position-relative text-start px-0' onClick={() => {
                                                const temp = [...selectedScheme]
                                                const scheme_index = temp.findIndex((allocation_id) => allocation_id === item.id);
                                                if (scheme_index === -1) {
                                                    temp.push(item.id)
                                                } else {
                                                    temp.splice(scheme_index, 1)
                                                }
                                                setSelectedScheme(temp)
                                            }} style={{ cursor: "pointer" }}>
                                                <h6>{item.name} ({item.fund_alias})</h6>
                                                <p>Allocation: {item.allocation}%</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="row">
                                <div className="col-12 pt-0">
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className={`${styles.e_delete} e-cancel`}
                                            cancel="modal"

                                        />
                                        {
                                            selectedScheme.length > 0 ?
                                                <PrimaryBtn
                                                    name="Delete"
                                                    cancel="modal"
                                                    handleClick={() => {
                                                        props.selectedSchemes(selectedScheme)
                                                    }}
                                                />
                                                :
                                                <PrimaryBtn
                                                    name="Delete"
                                                    className='e-disabled'
                                                />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }
        </>
    );
}
