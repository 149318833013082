/*
 *   File : privacy.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Privacy container
 *   Integrations  :28-11-2023
 *   Version : v1.0
 *   Created  :28-11-2023
 */
/*import packages*/
import { useState, useEffect } from 'react';
import { PrimaryBtn, SecondaryBtn } from '../../Components/Cta';

/*import components*/
import { Editor } from '../../Components/Input/index';
import { Loader } from '../../Components/Loader';
import { Toast } from '../../Components/Note';
import Nodata from '../../Components/Note/empty';

/* import services */

import service from '../../Services/service';

const Privacy = (props) => {
    const [editorData, setEditorData] = useState("");
    const [current, setCurrent] = useState(false);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        _getPrivacy();
    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error])

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        }
    }, [success])

    const _getPrivacy = () => {
        setLoader(true);
        const url = "/content/list-content";

        const data = {
            "type": "PRIVACY"
        }

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setEditorData(response.data.text)
            } else {
                setLoader(false)
            }
        })
    }

    const _updatePrivacy = () => {
        const url = "/content/update";

        const data = {
            "type": "PRIVACY",
            "text": editorData
        }

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                _getPrivacy();
                setSuccess("Privacy updated successfully")
            } else {
                setError(response.data.message)
            }
        })
    }

    return (
        <>
            <div className="row e-manage-content">
                <div className="col-lg-10 col-8">
                    {
                        !current ?
                            <h6 className="e-card-title">Privacy</h6>
                            :
                            <>
                                <span className='e-updated-time'>Updated on: 12 MAR 2020 10:00 AM</span>
                                <h4 className='pt-4 mb-5'>Privacy policy</h4>
                            </>
                    }
                </div>
                <div className="col-lg-2 col-4 text-end">
                    <SecondaryBtn
                        name={!current ? "View" : "Edit"}
                        handleClick={() => setCurrent(!current)} />
                </div>
            </div>
            <div className='row pt-2'>
                {
                    !current ?
                        loader ?
                            <Loader />
                            :
                            <div className='col-lg-12'>
                                <Editor
                                    editorChange={(temp) => {
                                        setEditorData(temp)
                                    }} value={editorData} />
                            </div>
                        :
                        editorData ?
                            <div className='col-lg-12'>
                                <span dangerouslySetInnerHTML={{ __html: editorData }} />
                            </div>
                            :
                            <Nodata />
                }
            </div>
            <div className='row pt-4 mt-3'>
                {
                    !current ?
                        editorData ?
                            <div className='col-lg-1'>
                                <PrimaryBtn
                                    name="Save"
                                    className="e-editor-save"
                                    handleClick={() => {
                                        _updatePrivacy()
                                    }} />
                            </div>
                            :
                            <div className='col-lg-1'>
                                <PrimaryBtn
                                    name="Save"
                                    className="e-editor-save e-disabled" />
                            </div>
                        :
                        null
                }
            </div>
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }

        </>

    )
}

export default Privacy;
