/*
 *   File : edit-basic-details.js
 *   Author : https://evoqins.com
 *   Description : Modal to edit basic details of user
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import { useState } from "react";
import { format, subYears } from "date-fns";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// Components
import { CustomTextInput, DatePicker, RadioGroup } from "../Form-Elements";
import { PrimaryBtn, SecondaryBtn } from "../Cta";
import service from "../../Services/service";
import { _formatDateFormat } from "../../Helper";

function EditBasicDetailsModal(props) {

  const GENERAL_DATA = useSelector((store) => store.INDEX_FUND_STORE.GENERAL_DATA);
  let selected_gender = GENERAL_DATA.genders.find((item) => item.display_name == props.data.overview.gender) || "";
  selected_gender = selected_gender ? selected_gender : GENERAL_DATA.genders[0];

  const [name, setName] = useState(props.data.overview.name || "");
  const [nameError, setNameError] = useState("");
  const [dob, setDob] = useState(props.data.overview.dob ? _formatDateFormat(props.data.overview.dob) : "");
  const [dobError, setDobError] = useState("");
  const [genderSelect, setGenderSelect] = useState(selected_gender.id);
  const [loading, setLoading] = useState(false);

  // handle name change
  const _handleNameChange = (value) => {
    setName(value);
    setNameError('');
  };


  //handle change date
  const _handleDateChange = (date) => {
    setDob(date);
    setDobError("");
  };

  //handle gender select
  const _handleGenderSelect = (id) => {
    setGenderSelect(id);
  };

  // handle validate data
  const _handleValidateData = () => {

    let validation = true;

    if (name.length < 2) {
      setNameError("Please enter a valid name");
      validation = false;
    }

    // if (dob.length === 0) {
    //   setDobError("DOB is required");
    //   validation = false;
    // };

    if (validation) {
      setLoading(true);
      // Api call
      _updateBasicDetails();
    }
  };


  // API - Update basic data 
  const _updateBasicDetails = () => {

    let is_post = true;
    let url = '/user/update-data';
    let data = {
      "customer_id": props.userId,
      "name": name,
      "dob": dob ? format(dob, "dd-MM-yyyy") : null,
      "gender_id": genderSelect,
    }
    service(is_post, url, data).then((response) => {
      if (response.status_code === 200) {

        toast.dismiss();
        toast.success("Personal details updated successfully");
        let close_btn = document.getElementById("edit-basic-detail-modal-close");
        close_btn.click();
        props.handleEditSuccess();

      } else {
        toast.dismiss();
        toast.error(response.data.message);
      }

      setLoading(false);
    });
  };

  return (
    <div className={`modal fade no-scrollbar`}
      id="edit-basic-detail-modal"
      data-bs-backdrop="static"
      tabIndex="-1"
      aria-labelledby="EditBasicModalLabel"
      aria-hidden="true">
      <div className={`modal-dialog  modal-dialog-centered modal-dialogue-scrollable`}>
        <div className="modal-content e-border-radius-8 p-0">
          <div className="modal-header d-flex justify-content-between pt-4 px-4 pb-0 border-0">
            <h6 className='modal-title mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
              Edit basic details
            </h6>
            <button type="button"
              id="edit-basic-detail-modal-close"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onCloseModal}></button>
          </div>
          <div className="modal-body pb-4 px-4">
            {/* name */}
            <div id='name'>
              <CustomTextInput label="Full name(As per PAN)"
                placeholder="John doe"
                type="text"
                value={name}
                errorMsg={nameError}
                handleChange={_handleNameChange} />
            </div>

            {/* Date picker */}
            <div className="my-1 mt-2">
              <h6 id='dob'
                className='e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-2'>
                Date of birth
              </h6>
              <DatePicker value={dob}
                minDate={subYears(new Date(), 100)}
                maxDate={subYears(new Date(), 18)}
                error={dobError}
                overLay={false}
                onChange={_handleDateChange} />
            </div>

            {/* gender  */}
            <div className='mb-2 pt-1'>
              <h6 className='e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-3'>
                Your gender
              </h6>
              <div className='d-flex flex-wrap gap-3'>
                <RadioGroup data={GENERAL_DATA.genders}
                  selected={genderSelect}
                  handleSelect={_handleGenderSelect} />
              </div>
            </div>

            {/* Action buttons */}
            <div className='row mt-4 justify-content-end'>
              <div className='col-4'>
                <SecondaryBtn name="Cancel"
                  className="w-100 h-40 d-flex justify-content-center align-items-center"
                  cancel="modal"
                  handleClick={props.onCloseModal} />
              </div>
              <div className='col-4'>
                <PrimaryBtn name="Update"
                  className="w-100 h-40 d-flex justify-content-center align-items-center"
                  loading={loading}
                  handleClick={_handleValidateData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditBasicDetailsModal;